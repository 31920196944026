import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ContentPaste } from '@mui/icons-material';

import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';
import PageHeader from 'components/PageHeader';
import DrawerContent from 'components/Drawer/components/DrawerContent';

const TermsOfUse = () => {
  const [hasToken, setToken] = useState(false);

  const getToken = async () => {
    setToken(!!(await localStorage.getItem('@AUTH_TOKEN')));
  };

  const navigate = useNavigate();

  useEffect(() => {
    getToken();
  }, [0]);

  return (
    <div style={{ backgroundColor: Theme.dark.background, flex: 1, display: 'flex', flexDirection: 'row' }}>
      <div style={{ minHeight: '83vh' }}>
        <DrawerContent />
      </div>
      <Styled.Content
        container
        direction="column"
        justifyContent="center"
      >
        <div
          style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}
        >
          {!hasToken && <ArrowBackIcon style={{ marginRight: 10, color: Theme.dark.primaryText, cursor: 'pointer' }} onClick={() => navigate(-1)} />}
          <PageHeader title='Termos de uso' icon={<ContentPaste fontSize="large" />} />
        </div>
        <Text bold color={Theme.dark.primaryText} size={18} style={{ marginTop: 20, width: '100%' }}>
        1. Apresentação
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 5, width: '100%' }}>
        Bem-vindo(a) à Plataforma EAD (Ensino a Distância) Aprendiz Bittar!
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 5, width: '100%' }}>
        Saiba que nós do Instituto João Bittar nos empenhamos muito para que a sua experiência em nosso ambiente virtual de ensino seja mais do que especial e inovadora. Para isso, desenvolvemos a Plataforma Aprendiz Bittar, um sistema de gestão de ensino e formação profissional no qual reunimos ferramentas e soluções tecnológicas de ponta para que o seu aprendizado seja incrível!
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 5, width: '100%' }}>
        A seguir estão descritas algumas instruções e disposições aplicáveis à utilização da Plataforma Aprendiz Bittar, para que você possa conhecer melhor o nosso ambiente virtual de aprendizagem e aproveitar ao máximo os nossos conteúdos e os meios de interação entre instrutores, tutores e aprendizes.        </Text>
        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        2. Definição de termos
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.1. Aprendiz: </spam>   jovem/adolescente entre 14 e 24 anos, ou pessoa com deficiência de qualquer idade, matriculado no curso de aprendizagem profissional desenvolvido pelo Instituto João Bittar para o cumprimento da cota a que se refere o art. 429 da CLT, junto à empresa convenente.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.2. Responsável legal: </spam>   mãe, pai ou outra pessoa que tenha o poder familiar, representando ou assistindo o aprendiz menor de idade usuário da Plataforma Aprendiz Bittar.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.3. Usuário: </spam>   pessoa natural ou jurídica que tenha sido autorizada pelo Instituto João Bittar a se cadastrar na Plataforma Aprendiz Bittar.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.4. Empresa convenente: </spam>   empresa conveniada ao Instituto João Bittar responsável pelo cumprimento da cota legal de aprendizagem e/ou responsável pela concessão das atividades práticas ao aprendiz.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.5. Instrutor: </spam>   profissional de nível superior, técnico ou médio com comprovada competência técnica referente ao saber operativo de atividades inerentes à respectiva formação profissional;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.6. Tutor: </spam>   profissional que atua na educação profissional e tecnológica, de modo análogo ao instrutor, promovendo o gerenciamento do curso na Plataforma Aprendiz Bittar, atuando no suporte dos processos de ensino e aprendizagem.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.7. Programa de aprendizagem profissional: </spam>   modelo inserido no CONAP (Catálogo Nacional de Programas de Aprendizagem Profissional), que tem como base uma ou mais ocupações da CBO, previamente aprovado pelos órgãos governamentais e disponível para oferta pelas entidades qualificadoras habilitadas pelo Ministério do Trabalho e Previdência.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.8. Curso de aprendizagem profissional: </spam>   unidade específica do programa de aprendizagem que possui características próprias, observados os parâmetros do programa disponibilizado na Plataforma Aprendiz Bittar.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>2.9. Competências da Economia 4.0: </spam>   competências em tecnologias digitais, tais como programação, internet das coisas, big data, inteligência artificial, automação, robótica, computação em nuvem, dentre outras, que promovam o aumento da produtividade e a redução dos custos pela transformação digital.
        </Text>
        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        3. Condições gerais
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.1. </spam>   A Plataforma Aprendiz Bittar é uma ferramenta que permite aos aprendizes e usuários autorizados pelo Instituto João Bittar o acesso a cursos de qualificação profissional certificados pelos órgãos governamentais competentes, de acordo com o cronograma do contrato de aprendizagem.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.2. </spam>   O aprendiz/usuário terá acesso à plataforma após o cadastro e a autorização do Instituto João Bittar, que disponibilizará os dados de acesso diretamente ao aprendiz/usuário ou por meio da empresa concedente das atividades práticas dos cursos ofertados.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.3. </spam>   O acesso à Plataforma Aprendiz Bittar poderá ser realizado por meio do endereço: https://aprendiz.ijb.edu.br. isso, é necessário um computador conectado à internet com as seguintes configurações mínimas: i) navegador Google Chrome, Mozilla Firefox, Microsoft Edge, Safari ou Internet Explorer; ii) plugin Adobe Flash 8 ou superior; iii) 2 GB de memória RAM; iv) kit multimídia; v) processador de 1 GHz ou superior. A Plataforma Aprendiz Bittar também está disponível como aplicativo para dispositivos móveis com sistemas Android.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.4. </spam>   O aprendiz/usuário deverá acessar o aplicativo por meio dos dados de caráter impessoal e intransferível fornecidos pelo Instituto João Bittar e/ou pela empresa concedente das atividades práticas, sendo-lhe vedado utilizar os dados de acesso de outro aprendiz/usuário e/ou compartilhar os seus dados de acesso com terceiros.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.5. </spam>   No caso de aprendizes/usuários menores de 18 (dezoito) anos, ou outras pessoas que necessitem de representação/assistência na forma da lei, o acesso deverá ser realizado com a assistência/autorização dos responsáveis legais.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.6. </spam>   O acesso e o uso da plataforma dependem da adesão aos presentes Termos e Condições de Uso da Plataforma Aprendiz Bittar e à Política de Privacidade e Proteção de Dados do Instituto Pater de Educação e Cultura, cujos termos podem ser acessados no endereço: https://institutojoaobittar.edu.br/.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.7. </spam>   O aceite aos termos do presente implica no compromisso do aprendiz/usuário em (i)  fornecer suas informações precisas, atuais e completas e as de seu responsável legal, conforme solicitado pelo Instituto João Bittar; (ii) manter a confidencialidade de sua senha e de outras informações relacionadas à segurança da conta; (iii) manter e atualizar prontamente os dados e qualquer informação de registro fornecidos ao Instituto João Bittar; (iv) ser totalmente responsável por todo o uso da conta e por quaisquer ações que ocorram por meio dela.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.8. </spam>   Ao acessar a Plataforma Aprendiz Bittar e aceitar os presentes termos, o aprendiz/usuário e responsável legal declaram que leram, compreenderam e concordam com os termos da Política de Privacidade e Proteção de Dados, aquiescendo integralmente ao presente, podendo o Instituto João Bittar corrigir, alterar e atualizar tais regulamentos mediante comunicação de alteração da data de atualização no documento.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>3.9. </spam>   Caso o aprendiz/usuário e/ou representante legal não concordem ou tenham dúvidas sobre os termos e as condições estipulados nesta licença de uso e/ou na Política de Privacidade e Proteção de Dados do Instituto João Bittar – ou sobre as alterações promovidas –, poderão interromper o uso da plataforma e solicitar esclarecimentos por meio de comunicação a ser enviada ao e-mail privacidade@institutojoaobittar.edu.br. 
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        4. Descrição das funcionalidades
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>4.1. </spam>   Dentre as funcionalidades disponibilizadas na Plataforma Aprendiz Bittar, poderá o aprendiz/usuário acessar videoaulas, áudios, PDFs, arquivos em geral, animações, links externos, páginas HTML, pacotes SCORM (padrão de conteúdo e-learning), etc. O aprendiz/usuário poderá proceder à leitura de textos relacionados às videoaulas, realizar exercícios, interagir com os instrutores/tutores e aprendizes e ter acesso à sua análise de desempenho e controle de frequência Contará também com chat para suporte técnico das 08h:00m às 18h:00m, de segunda a sexta-feira, podendo ainda entrar em contato no e-mail: suporte@ijb.edu.br.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>4.2. </spam>   O Instituto João Bittar, no decorrer do curso, poderá incluir, excluir ou alterar os conteúdos e funcionalidades da plataforma, promovendo melhorias didáticas e tecnológicas na ferramenta, de modo a aprimorar a experiência e o aprendizado dos aprendizes/usuários.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        5. Dos dados coletados
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>5.1. </spam>   Para garantia, controle e monitoramento do acesso aos cursos disponibilizados na Plataforma Aprendiz Bittar pode ser  necessário que você nos forneça os seguintes dados pessoais: i) nome completo, ii) gênero; c) data de nascimento; iii) CPF; e) registro geral; iv) endereço; v) escolaridade; vi) escola; vii) nome do representante legal; viii) CPF do representante legal; xi) endereço do representante legal.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>5.2. </spam>   A seu critério, você poderá nos fornecer dados adicionais como sua fotografia e complemento de dados de perfil, além de informações acerca de campanhas e ações às quais estiver relacionado também poderão ser coletados mediante sua inclusão espontânea em ferramentas de interação da plataforma.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>5.3. </spam>   Serão também coletados dados técnicos do dispositivo e do sistema utilizados para acesso à plataforma e monitoramento da frequência e do aprendizado, a saber: x) data e hora de acesso da Plataforma Aprendiz Bittar; xi) número de IP; xii) tipo e modelo de dispositivo; xiii) tipo e versão de browser; xiv) linguagem do browser; xv) registro das aulas realizadas e conteúdos acessados; xvi) registros de avaliação quanto ao aproveitamento do curso; xvii) data e hora do registro de ponto e tempo de permanência na plataforma; xviii) foto do registro de ponto; xix) relatório de atividades práticas; xx) mensagens enviadas no chat com o instrutor; xxi) mensagens enviadas no chat com outros aprendizes.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        6. Da finalidade da coleta de dados
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>6.1. </spam>   Os dados e informações coletados na Plataforma Aprendiz Bittar são os estritamente necessários para o cadastramento do aprendiz/usuário na plataforma e para a gestão do programa de aprendizagem desenvolvido na modalidade EAD.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>6.2. </spam>   Os dados coletados também serão utilizados para enviar informações pedagógicas e utilitárias sobre as campanhas e demais informes de caráter institucional ou não do Instituto João Bittar.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>6.3. </spam>   As informações fornecidas pelo aprendiz/usuário, assim como os demais dados relacionados ao seu perfil, não serão utilizados para nenhuma outra finalidade desvinculada da gestão do programa de aprendizagem, exceto mediante autorização específica. 
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>6.4. </spam>   Ao aderir ao presente termo, o aprendiz/usuário está ciente de que o Instituto João Bittar poderá compartilhar informações de identificação e de contato com as empresas conveniadas, órgãos públicos e privados e outros cujo acesso seja necessário para o desenvolvimento do programa de aprendizagem, em conformidade com os regulamentos vigentes e para garantir o funcionamento e o uso do aplicativo nos termos licenciados.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>6.5. </spam>   Os dados coletados automaticamente pelo acesso, navegação e interação na ferramenta serão utilizados com o propósito de registro do acesso aos cadastros de usuários mantidos em nosso aplicativo e protegidos contra fraudes e danos a nossos usuários e a terceiros durante todo o período em que perdurar a autorização de acesso ao curso.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        7. Da segurança dos dados
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>7.1. </spam>   Nos termos constantes da nossa Política de Privacidade e Proteção de Dados, os dados dos aprendizes/usuários cadastrados na Plataforma Aprendiz Bittar estarão seguros nos servidores de nuvem e operados por empresas contratadas pelo Instituto João Bittar, localizadas no Brasil e submetidas à Política de Privacidade e Proteção de Dados do Instituto João Bittar e à legislação brasileira.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>7.2. </spam>   O Instituto João Bittar é o controlador dos dados coletados via Plataforma Aprendiz Bittar e assegura que todas as medidas técnicas e de gestão adequadas para o tratamento das informações são adotadas para evitar a perda e o vazamento dos dados controlados.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        8. Da licença de uso da Plataforma Aprendiz Bittar
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>8.1. </spam>   Ao cadastrar o aprendiz/usuário, o Instituto João Bittar outorga-lhe a licença de uso da Plataforma Aprendiz em caráter temporário, não exclusivo e intransferível, para a utilização em dispositivo móvel de uma cópia de cada aplicativo na forma de código-objeto, apenas em um dispositivo sem fio interativo sob seu controle.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>8.2. </spam>   Não é permitido ao usuário derivar ou tentar derivar o código-fonte de todo ou qualquer parte de qualquer app, permitir que terceiros derivem ou tentem derivar tal código-fonte ou fazer engenharia reversa, descompilar, desmontar ou traduzir qualquer app ou qualquer parte dele.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>8.3. </spam>   O Instituto João Bittar declara que conta com todos os direitos sobre a Plataforma Aprendiz Bittar para realizar o licenciamento dos aprendizes/usuários, nos moldes do presente termo, podendo a qualquer tempo fazer alterações, modificações ou correções na ferramenta, assegurando-lhe melhorias.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>8.4. </spam>   O instituto poderá utilizar mecanismos de inibição de cópias, de instalação ou de utilização indevida da Plataforma Aprendiz Bittar, bem como de mecanismos de controle de uso dentro do limite de dispositivos móveis permitidos. Os mecanismos poderão ser do tipo: i) chave de controle no servidor no qual o programa/sistema estiver instalado; ii) registro da instalação por meio de senha de acesso, número de série, liberação de contrassenha; iii) uma combinação dos mecanismos de controle citados ou que venham a ser criados.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        9. Direitos de propriedade
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>9.1. </spam>   O aprendiz/usuário reconhece expressamente que a Plataforma Aprendiz Bittar, assim como os logotipos, marcas, insígnias, símbolos, sinais distintivos, manuais, documentação técnica associada e quaisquer outros materiais correlatos à ferramenta, constituem, conforme o caso, direitos autorais, segredos comerciais e/ou direitos de propriedade do Instituto João Bittar, sendo tais direitos protegidos pelas legislações nacional e internacional aplicáveis à propriedade intelectual e aos direitos autorais, especialmente tuteladas nas leis nº 9.609 e 9.610, de 19/12/1998. 
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>9.2. </spam>   Fica expressamente vedado ao aprendiz/usuário, em relação à Plataforma Aprendiz Bittar  e aos conteúdos nela disponibilizados, ceder, doar, alugar, vender, arrendar, emprestar, reproduzir, modificar, adaptar, traduzir, disponibilizar o acesso de terceiros, via on-line, acesso remoto ou de outra forma; incorporar a outros sistemas ou programas, próprios ou de terceiros; oferecer em garantia ou penhor; alienar ou transferir, total ou parcialmente, a qualquer título, de forma gratuita ou onerosa; descompilar, mudar a engenharia (reengenharia), enfim, dar qualquer outra destinação à Plataforma Aprendiz Bittar – ou parte dela – que não seja a simples utilização para a realização dos cursos de qualificação profissional, nos moldes do programa de aprendizagem.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        10. Direitos de propriedade do conteúdo
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>10.1. </spam>   Todo o conteúdo disponível na Plataforma Aprendiz Bittar, incluindo apostilas, materiais didáticos, videoaulas, designs, textos, gráficos, imagens, informações, softwares, áudios e outros arquivos, bem como sua seleção e disposição, é de propriedade exclusiva do Instituto João Bittar. Nenhum conteúdo disponibilizado pela plataforma pode ser modificado, copiado, distribuído, emoldurado, reproduzido, republicado, baixado, copiado, exibido, postado, transmitido ou vendido de qualquer forma ou por qualquer meio, no todo ou em parte, exceto conforme expressamente permitido neste termo. 
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>10.2. </spam>   É proibido o uso de data mining, robôs, scraping ou coleta de dados semelhante ou métodos de extração para obter os conteúdos disponibilizados na Plataforma Aprendiz Bittar.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>10.3. </spam>   Todos os dados, informações e materiais gerados a partir do acesso e realização das atividades disponibilizadas por meio da plataforma, incluindo o conteúdo traduzido gerado individual ou coletivamente, serão de propriedade exclusiva do Instituto João Bittar, não podendo ser extraído da plataforma exceto se expressamente autorizado pelo Instituto João Bittar. O licenciamento de uso da Plataforma Aprendiz Bittar não inclui downloads dos materiais disponibilizados na plataforma. 
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>10.4. </spam>   O Instituto João Bittar é detentor e/ou licenciado exclusivo de todos e quaisquer direitos, títulos e interesses do curso ofertado, incluindo os de propriedade intelectual e outros, devendo tais direitos ser respeitados por todos os aprendizes/usuários.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        11. Do uso apropriado
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>11.1. </spam>   A Plataforma Aprendiz Bittar é um local de aprendizado e criação de saberes seguro para todos os aprendizes/usuários. Assédio e conteúdos ofensivos não serão tolerados. A utilização de símbolos, nomes e textos que promovam o ódio, assim como assediar, perseguir, personificar e dirigir comentários de cunho sexual a alguém serão considerados como abuso. O mesmo vale para nudez, fotos de perfil e apelidos perturbadores, o que implicará na penalização do aprendiz/usuário, nos termos legais e do Regimento Interno do Instituto João Bittar, sem prejuízo das medidas de proteção e punição previstas em lei.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>11.2. </spam>   A postagem de mensagens irrelevantes, abusivas, ilegais ou sem sentido nos espaços de interatividade ou em qualquer local na Plataforma Aprendiz Bittar poderá incorrer em suspensão do acesso, bem como na aplicação das medidas previstas no Regimento Interno do Instituto João Bittar. Isso inclui também qualquer tipo de spam, distribuição de códigos de promoção, propaganda e promoção excessiva de produtos e serviços e demais ações que desvirtuem o objetivo do programa de aprendizagem desenvolvido no ambiente virtual.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>11.3. </spam>   O Instituto João Bittar trabalha para a formação de pessoas éticas e honestas. Comentários obscenos, que incitem ódio ou que fujam dos objetivos do aprendizado não serão admitidos. Em suma, nos espaços de interatividade e/ou em qualquer local na Plataforma Aprendiz Bittar, não será permitida a divulgação de conteúdo: i) Ilegal; ii) pornográfico; iii) excessivamente profano ou violento; iv) spam; e) ameaçador, assediador ou qualquer tipo de bullying; v) associado a racismo ou intolerância; vi) imitação ou representação de outra pessoa de maneira maliciosa ou enganosa; vii) que viole qualquer lei ou infrinja o direito de terceiros (incluindo direitos autorais, marcas registradas, privacidade, publicidade ou outros direitos pessoais ou de propriedade);  viii) que seja questionável ou que exponha os usuários, o Instituto João Bittar e as empresas conveniadas a qualquer dano ou responsabilidade de qualquer tipo. Qualquer conteúdo que infringir o espírito dessas orientações será removido e os responsáveis serão devidamente advertidos e/ou penalizados.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        12. Prazo de validade técnica
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>12.1. </spam>   O licenciamento outorgado aos aprendizes será válido durante a vigência do contrato de aprendizagem profissional e pelo prazo de até 24 (vinte e quatro) meses após o término do contrato de aprendizagem profissional. Após a referida data, caso o usuário deseje solicitar a certificação e/ou outro registro relativo ao curso ofertado, deverá entrar em contato com o Instituto João Bittar através do e-mail: suporte@ijb.edu.br e dos telefones: (34)3212-2999 e (34)3214-0900.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        13. Limitação de responsabilidade
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>13.1. </spam>   O aprendiz/usuário declara, reconhece e aceita que: i) a Plataforma Aprendiz Bittar não foi desenvolvida sob encomenda, mas para o desenvolvimento de cursos de qualificação profissional de formação de aprendizes na modalidade de Ensino a Distância, atendendo aos requisitos regulamentares exigidos pelos órgãos do poder público; ii) as configurações de seus dispositivos e servidores estão de acordo com os requisitos mínimos necessários para a instalação e a utilização do aplicativo; iii) O Instituto João Bittar isenta-se expressamente de quaisquer responsabilidades e indenizações, perdas e danos, lucros cessantes, prejuízos de quaisquer espécies, sob quaisquer títulos, perdas de negócios, perda ou extravio de dados, defeitos de computador, equipamentos ou periféricos, ou quaisquer outros danos diretos, indiretos, acidentais, especiais, consequenciais ou punitivos causados ao usuário ou a terceiros, decorrentes direta ou indiretamente da aquisição e/ou da indevida utilização da Plataforma Aprendiz Bittar e de atos praticados em desacordo com a legislação em vigor por parte dos aprendizes/usuários ou de seus prepostos/funcionários causados ao usuário ou a terceiros.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>13.2. </spam>   O Instituto João Bittar NÃO TERÁ QUALQUER RESPONSABILIDADE PELOS DANOS E PREJUÍZOS DE QUALQUER NATUREZA QUE POSSAM DECORRER DO ACESSO, INTERCEPTAÇÃO, ELIMINAÇÃO, ALTERAÇÃO, MODIFICAÇÃO OU MANIPULAÇÃO, POR TERCEIROS NÃO AUTORIZADOS, DOS DADOS DO USUÁRIO DURANTE A UTILIZAÇÃO DO APLICATIVO.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>13.3. </spam>   Toda e qualquer ação executada ou conteúdo publicado pelo aprendiz/usuário durante o uso do aplicativo será de sua exclusiva e integral responsabilidade, devendo isentar e indenizar o Instituto João Bittar de quaisquer reclamações, prejuízos, perdas em decorrência de tais ações ou manifestações.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        14. Foro
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Fica eleito o foro da cidade de Uberlândia-MG para dirimir quaisquer questões decorrentes destes Termos e Condições para o uso da Plataforma Aprendiz Bittar, que será regido pelas leis brasileiras.
        </Text>
        
      </Styled.Content>
    </div>
  )
}

export default TermsOfUse;
