import { thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import LearningApi from 'api/Learning';

export default {
  send: thunk(async (actions, payload) => {
    const response = await LearningApi.send({ description: payload });

    if (response.ok && response.status !== 499) return response?.data?.lesson;
    
    let description = response?.data?.message || 'Ocorreu um erro inesperado';
    toast(description, { type: 'error' });
    return false;
  }),
};
