import API from 'api';

const list = () => API.get('/jovem/');
const listPending = () => API.get('/jovem/pending');
const listJourneyReport = () => API.get('/jovem/reports/journey');
const listGeneralReport = (body) => API.post('/jovem/reports/general', body);
const listGeneralReportColumns = () => API.get('/jovem/reports/general/columns');
const listYoung = (body) => API.post('/jovem/data', body);
const listUnities = () => API.get('/jovem/unities');
const listCompanies = () => API.get('/jovem/companies');
const listCourses = (body) => API.get('/jovem/courses/' + body);
const listSectors = (body) => API.get('/jovem/sectors/' + body);
const listClasses = (param1, param2) => API.get('/jovem/classes/' + param1 + '/' + param2);
const listTheoreticalPlaces = (body) => API.get('/jovem/theoretical/' + body);
const listPracticalPlaces = (body) => API.get('/jovem/practical/' + body);
const listDocs = (body) => API.get('/jovem/docs/' + body);
const newYoung = (body) => API.post('/jovem/new', body);
const updateYoung = (body) => API.post('/jovem/update', body);
const newContract = (body) => API.post('/jovem/contract/new', body);
const getContract = (body) => API.post('/jovem/contract/pdf', body);
const getRegistration = (body) => API.post('/jovem/registration/pdf', body);
const getWarnings = (body) => API.get('/warnings/young/' + body);

export default {
  list,
  listUnities,
  listYoung,
  listPending,
  listCompanies,
  listSectors,
  newYoung,
  updateYoung,
  listCourses,
  listClasses,
  listTheoreticalPlaces,
  listPracticalPlaces,
  newContract,
  getContract,
  listDocs,
  listJourneyReport,
  listGeneralReport,
  listGeneralReportColumns,
  getRegistration,
  getWarnings,
};
