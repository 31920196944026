import React from 'react';
import Text from 'components/Text';
import * as Styled from './styles';
import { Grid } from '@mui/material';
import { Checkbox } from '@mui/material';

const CheckboxComponent = (props) => {

  return (
    <>
      <Styled.ItemContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid container direction="row" style={{ alignItems: 'center', marginLeft: (props.column[1]==1)?0:25 }}>
          <Checkbox style={{ color: 'white' }} checked={(props.columns.indexOf(props.column[2]) != -1)} onChange={() => props.handleCheck(props.column)} />
          <Text>{props.column[2]}</Text>
        </Grid>

      </Styled.ItemContainer>
    </>
  );
};

export default CheckboxComponent;
