import auth from './Auth';
import user from './User';
import youngs from './Youngs';
import learning from './Learning';
import reports from './Reports';

export default {
  auth,
  user,
  youngs,
  learning,
  reports
};