import { toast } from 'react-toastify';
import { action, thunk } from 'easy-peasy';

import ReportsApi from 'api/Reports';

export default {
  warnings: [],
  performances: [],

  setData: action((state, { field, payload }) => {
    state[field] = payload;
  }),

  listWarnings: thunk(async (actions, payload) => {
    try {
      const response = await ReportsApi.warningsList(payload);

      if (response.ok) {
        actions.setData({ field: 'warnings', payload: response.data.data });
        return true;
      }

      toast.error(response.data.message || 'Ocorre um erro inesperado');
      return false;
    } catch (e) {
      return false;
    }
  }),

  listPerformance: thunk(async (actions, payload) => {
    try {
      const response = await ReportsApi.performancesList(payload);

      if (response.ok) {
        actions.setData({ field: 'performances', payload: response.data.data });
        return true;
      }

      toast.error(response.data.message || 'Ocorre um erro inesperado');
      return false;
    } catch (e) {
      return false;
    }
  }),
};
