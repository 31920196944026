import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { Grid, CircularProgress } from '@mui/material';
import Text from 'components/Text';
import RegisterData from './components/RegisterData';
import ContractualData from './components/ContractualData';
import LearningContract from './components/LearningContract';
import Declaration from './components/Declaration';
import Documentation from './components/Documentation';
import Warnings from './components/Warnings';
import { IJB_URL, IMG_PLACEHOLDER } from 'api';
import Theme from 'style/Theme';

const tabs = [
  // {
  //   title: 'Desempenho Geral',
  //   type: 'GENERAL_PERFORMANCE',
  // },
  // {
  //   title: 'Controle de jornada teórica',
  //   type: 'THEORETICAL_JOURNEY_CONTROL',
  // },
  // {
  //   title: 'Advertências',
  //   type: 'WARNINGS',
  // },
  // {
  //   title: 'Orientaçãos e advertências',
  //   type: 'WARNINGS',
  // },
  {
    title: 'Dados cadastrais',
    type: 'REGISTER_DATA',
  },
  {
    title: 'Dados contratuais',
    type: 'DATA_CONTRACT',
  },
  {
    title: 'Contrato de aprendizagem',
    type: 'LEARNING_CONTRACT',
  },
  {
    title: 'Documentação completa',
    type: 'DOCUMENTATION',
  },
  {
    title: 'Declaração de matrícula',
    type: 'DECLARATION',
  },
]

const YoungScreen = () => {
  const { id } = useParams();

  const [seletecTab, setSeletecTab] = useState('REGISTER_DATA');
  const [loading, setLoading] = useState(true);

  const getYoungData = useStoreActions(({ user }) => user.getYoungData);
  const youngData = useStoreState(({ user }) => user.youngData);

  const init = async () => {
    setLoading(true);
    await getYoungData(id);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) return (
    <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
      <Grid container alignItems="center" justifyContent="center" direction="column">
        <CircularProgress style={{ color: Theme.primary, marginTop: 25 }} />
        <Text bold size={16} color={Theme.primary} style={{ marginTop: 5 }}>Carregando...</Text>
      </Grid>
    </div>
  );

  return (
    <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Grid
          xs={12}
          sm={12}
          md={9}
          item
          container
          direction="column"
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ borderBottom: '1px solid #364563', padding: 25 }}
          >
            <img
              src={(youngData?.foto) ? (IJB_URL + youngData?.foto) : IMG_PLACEHOLDER}
              width="100"
              height="100"
              alt='Foto do jovem'
              style={{ borderRadius: 100, marginRight: 10 }}
            />

            <Grid item container alignItems="center" justifyContent="space-between" style={{ paddingLeft: 15, flex: 1 }}>
              <Grid item xs={12} md={8}>
                <Text bold size={16}>{youngData?.nome}</Text>
                <Text size={12} color="#93979F">Idade: {youngData?.idade} anos</Text>
              </Grid>

              <Grid item xs={12} md={4}>
                <Text size={12} color="#93979F">Unidade</Text>
                <Text bold size={16}>{youngData?.unidade}</Text>
              </Grid>

              <Grid item xs={12} md={8} style={{ marginTop: 5 }}>
                <Text size={12} color="#93979F">Cadastrado em</Text>
                <Text bold size={16}>{youngData?.data_cadastro}</Text>
              </Grid>

              <Grid item xs={12} md={4} style={{ marginTop: 5 }}>
                <Text size={12} color="#93979F">Contratado</Text>
                <Text bold size={16}>{(youngData?.meses_contratado==1)?'1 mês':youngData?.meses_contratado + ' meses'} </Text>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            {seletecTab === 'WARNINGS' && (<Warnings id={id} />)}
            {seletecTab === 'REGISTER_DATA' && (<RegisterData id={id} />)}
            {seletecTab === 'DATA_CONTRACT' && (<ContractualData id={id} />)}
            {seletecTab === 'LEARNING_CONTRACT' && (<LearningContract id={id} />)}
            {seletecTab === 'DOCUMENTATION' && (<Documentation id={id} />)}
            {seletecTab === 'DECLARATION' && (<Declaration id={id} />)}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          container
          direction="column"
          style={{ paddingLeft: 25, height: '100%' }}
        >
          <Grid
            container
            direction="column"
            style={{ borderLeft: '1px solid #364563', paddingLeft: 25, height: '100%' }}
          >
            <Text size={18} bold color="#ffffff">Menu</Text>

            {tabs.map((tab, index) => (
              <Grid
                item
                container
                alignItems="center"
                style={{
                  border: '1px solid ' + seletecTab === tab.type ? Theme.primary : '#364563',
                  padding: 20,
                  borderRadius: 8,
                  marginTop: 10,
                  backgroundColor: seletecTab === tab.type ? '#24354B' : '#1E262D',
                  cursor: 'pointer'
                }}
                key={tab.type}
                onClick={() => setSeletecTab(tab.type)}
              >
                <Text size={14} bold color={seletecTab === tab.type ? Theme.primary : '#f2f2f3'}>
                  {tab.title}
                </Text>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default YoungScreen;
