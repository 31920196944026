import React, { useEffect } from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import LoginScreen from 'containers/Auth/Login';
import RecoverPasswordScreen from 'containers/Auth/RecoverPassword';
import UpdatePasswordScreen from 'containers/Auth/UpdatePassword';

import YoungsScreen from 'containers/Dashboard/Youngs';
import ReportsScreen from 'containers/Dashboard/Reports';
import JourneyReportScreen from 'containers/Dashboard/JourneyReport';
import WarningReportScreen from 'containers/Dashboard/WarningReport';
import GeneralReportScreen from 'containers/Dashboard/GeneralReport';
import PerformanceReportScreen from 'containers/Dashboard/PerformanceReport';
import NewYoungScreen from 'containers/Dashboard/NewYoung';
import NewYoungStep2Screen from 'containers/Dashboard/NewYoungStep2';
import NewYoungStep3Screen from 'containers/Dashboard/NewYoungStep3';
import TermsOfUse from 'containers/Dashboard/TermsOfUse';
import GeneralDataProtectionPolicy from 'containers/Dashboard/GeneralDataProtectionPolicy';
import YoungScreen from 'containers/Dashboard/Young';
import Drawer from 'components/Drawer';
import Theme from 'style/Theme';

const MainRoutes = () => {
  const getMe = useStoreActions(({ user }) => user.getMe);
  const user = useStoreState(({ user }) => user.user);

  const init = async () => {
    const token = localStorage.getItem('@AUTH_TOKEN');

    if (token && !user?.name) {
      getMe();
    }
  };

  useEffect(() => {
    init();
  }, [0]);

  return (
    <div style={{ flex: 1, minHeight: '100vh', width: '100%', backgroundColor: Theme.dark.background }}>
      <Drawer>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/" element={<LoginScreen />} />
          <Route path="/recuperar-senha" element={<RecoverPasswordScreen />} />
          <Route path="/redefinir-senha" element={<UpdatePasswordScreen />} />
          <Route path="/aprendizes" element={<YoungsScreen />} />
          <Route path="/relatorios" element={<ReportsScreen />} />
          <Route path="/relatorios/advertencia" element={<WarningReportScreen />} />
          <Route path="/relatorios/geral" element={<GeneralReportScreen />} />
          <Route path="/relatorios/desempenho" element={<PerformanceReportScreen />} />
          <Route path="/relatorios/jornada" element={<JourneyReportScreen />} />
          <Route path="/cadastrar-aprendiz" element={<NewYoungScreen />} />
          <Route path="/cadastrar-aprendiz-dois" element={<NewYoungStep2Screen />} />
          <Route path="/cadastrar-aprendiz-tres" element={<NewYoungStep3Screen />} />
          <Route path="/jovem/:id" element={<YoungScreen />} />
          <Route path="/politica-protecao-dados" element={<GeneralDataProtectionPolicy />} />
          <Route path="/termos" element={<TermsOfUse />} />
          <Route path="*" element={<LoginScreen />} />
        </Routes>
        {/* <Footer /> */}
      </Drawer>

    </div>
  );

};

export default MainRoutes;
