import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { CircularProgress, Grid } from '@mui/material';
import Text from 'components/Text';
import Theme from 'style/Theme';
import { IJB_URL } from 'api';

const Documentation = ({ id }) => {
    const [loading, setLoading] = useState(true);

    const listDocs = useStoreActions(({ youngs }) => youngs.listDocs);
    const docs = useStoreState(({ youngs }) => youngs.docs);


    const init = async () => {
        setLoading(true);
        await listDocs(id);
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, [0]);


    if (loading) return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24}>Documentação completa</Text>

            <Grid container alignItems="center" justifyContent="center" direction="column">
                <CircularProgress style={{ color: Theme.primary, marginTop: 25 }} />
                <Text bold size={16} color={Theme.primary} style={{ marginTop: 5 }}>Carregando...</Text>
            </Grid>
        </div>
    );

    return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24} style={{ marginBottom: 24 }}>Documentação completa</Text>

            {(docs?.contrato) ? <a target='_blank' rel="noopener noreferrer" download="contrato.pdf" href={IJB_URL + docs.contrato} style={{ textDecoration: 'none' }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10, cursor: 'pointer' }}
                >
                    <Text>Contrato assinado</Text>
                    <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                        BAIXAR
                    </Text>
                </Grid>
            </a> : null}

            {(docs?.cpf) ? <a target='_blank' rel="noopener noreferrer" download="cpf.pdf" href={IJB_URL + docs.cpf} style={{ textDecoration: 'none' }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10, cursor: 'pointer' }}
                >
                    <Text>CPF do aprendiz</Text>
                    <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                        BAIXAR
                    </Text>
                </Grid>
            </a> : null}

            {(docs?.rg) ? <a target='_blank' rel="noopener noreferrer" download="rg.pdf" href={IJB_URL + docs.rg} style={{ textDecoration: 'none' }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10, cursor: 'pointer' }}
                >
                    <Text>RG do aprendiz</Text>
                    <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                        BAIXAR
                    </Text>
                </Grid>
            </a> : null}

            {(docs?.responsavel_cpf) ? <a target='_blank' rel="noopener noreferrer" download="responsavel_cpf.pdf" href={IJB_URL + docs.responsavel_cpf} style={{ textDecoration: 'none' }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10, cursor: 'pointer' }}
                >
                    <Text>CPF do responsável</Text>
                    <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                        BAIXAR
                    </Text>
                </Grid>
            </a> : null}

            {(docs?.responsavel_rg) ? <a target='_blank' rel="noopener noreferrer" download="responsavel_rg.pdf" href={IJB_URL + docs.responsavel_rg} style={{ textDecoration: 'none' }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10, cursor: 'pointer' }}
                >
                    <Text>RG do responsável</Text>
                    <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                        BAIXAR
                    </Text>
                </Grid>
            </a> : null}

            {(docs?.escolaridade) ? <a target='_blank' rel="noopener noreferrer" download="escolaridade.pdf" href={IJB_URL + docs.escolaridade} style={{ textDecoration: 'none' }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10, cursor: 'pointer' }}
                >
                    <Text>Declaração de escolaridade</Text>
                    <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                        BAIXAR
                    </Text>
                </Grid>
            </a> : null}

            {(docs?.ctps_assinada) ? <a target='_blank' rel="noopener noreferrer" download="esocial.pdf" href={IJB_URL + docs.ctps_assinada} style={{ textDecoration: 'none' }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10, cursor: 'pointer' }}
                >
                    <Text>Comprovante de registro eSocial</Text>
                    <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                        BAIXAR
                    </Text>
                </Grid>
            </a> : null}


        </div>
    );
}

export default Documentation;
