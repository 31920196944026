import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Checkbox, CircularProgress, Grid } from '@mui/material';

import Text from 'components/Text';

const FilterAdvancedContent = ({ userType, onChange = () => null, valuesProjects = [], valuesTags = [] }) => {
    // const getAll = useStoreActions(({ tags }) => tags.getAll);
    // const [projects = [], tags = []] = useStoreState(({ project, tags }) => [project.projects, tags.tagsCourse]);

    // const [loading, setLoading] = useState(true);

    // useEffect(async () => {
    //     setLoading(true);
    //     if (!tags.length) await getAll({ type: 'CURSO' });
    //     setLoading(false);
    // }, [0]);

    // const handleChange = (sigla, type) => {
    //     if (type === 1) {
    //         let index = valuesProjects.findIndex((t) => t === sigla);
    //         if (index != -1) {
    //             let valuesSplice = valuesProjects;
    //             valuesSplice.splice(index, 1);
    //             onChange([...valuesSplice], 1);
    //         } else {
    //             onChange([...valuesProjects, sigla], 1);
    //         }
    //     } else {
    //         let index = valuesTags.findIndex((t) => t === sigla);
    //         if (index != -1) {
    //             let valuesSplice = valuesTags;
    //             valuesSplice.splice(index, 1);
    //             onChange([...valuesSplice], 2);
    //         } else {
    //             onChange([...valuesTags, sigla], 2);
    //         }
    //     }
    // }

    return (
        <>
            <Grid style={{ borderTop: '2px solid rgba(255, 255, 255, 0.1)', height: 1, marginTop: 20, marginBottom: 20 }} />

            <Text size={12} color="#fff" style={{ marginBottom: 10 }}>
                Tipo
            </Text>
            <Grid container alignItems="center">
                <Checkbox
                // checked={!!valuesTags.includes(item)}
                // onChange={() => handleChange(item, 2)}
                />
                <Text>Presencial</Text>
            </Grid>
            <Grid container alignItems="center">
                <Checkbox
                // checked={!!valuesTags.includes(item)}
                // onChange={() => handleChange(item, 2)}
                />
                <Text>EAD</Text>
            </Grid>
            {/* {loading ? (<CircularProgress size={22} />) : (
                tags && tags.map((item, index) => (
                    <Grid container key={index} alignItems="center">
                        <Checkbox
                            checked={!!valuesTags.includes(item)}
                            onChange={() => handleChange(item, 2)}
                        />
                        <Text>{item}</Text>
                    </Grid>
                ))
            )} */}
        </>
    )
};

FilterAdvancedContent.defaultProps = {
    userType: null,
    valuesProjects: [],
    valuesTags: [],
    show: false
};

FilterAdvancedContent.prototype = {
    userType: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    valuesTags: PropTypes.array,
    valuesProjects: PropTypes.array,
};

export default FilterAdvancedContent;
