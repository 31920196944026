import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid, Hidden } from '@mui/material';
import FilterAdvanced from 'components/FilterAdvanced';
import Text from 'components/Text';
import Card from './components/Card';
import Search from './components/Search';
import FilterAdvancedContent from './components/FilterAdvancedContent';

import Theme from 'style/Theme';

const WarningReportScreen = () => {

  const [isSearching, setIsSearching] = useState(false);
  const [filtered, setFiltered] = useState([]);

  const [showFilter, setShowFilter] = useState(false);

  const openFilter = () => setShowFilter(true);
  const closeFilter = () => setShowFilter(false);

  const listWarnings = useStoreActions(({ reports }) => reports.listWarnings);
  const warnings = useStoreState(({ reports }) => reports.warnings);

  const handleSearch = async (value) => {
    let aux = value.toLowerCase();

    const result = warnings.filter(item => ((item.nome.toLowerCase().indexOf(aux) != -1)
      || (item.medida_disciplinar.toLowerCase().indexOf(aux) != -1)
      || (item.cnpj.toLowerCase().indexOf(aux) != -1)));

    if (value && value.trim() != '' && result.length < warnings.length) {
      setFiltered(result);
      setIsSearching(true);
    } else {
      setFiltered([]);
      setIsSearching(false);
    }
  }

  useEffect(() => {
    listWarnings();
  }, [0])

  return (
    <>
      <FilterAdvanced
        show={showFilter}
        onClose={closeFilter}
      >
        <FilterAdvancedContent />
      </FilterAdvanced>

      <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>
        <Text color="#F2F2F3" bold size={24}>Relatório de advertências</Text>

        <Search
          handleSearch={handleSearch}
          handleOpenFilter={openFilter}
        />

        <div style={{ marginTop: 25, marginBottom: 10 }}>
          <Text>Últimas advertências</Text>
        </div>

        <Hidden mdDown>
          <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingLeft: 10 }}
          >
            <Grid item xs={12} sm={12} md={3}>
              <Text color="#93979F">Nome</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <Text color="#93979F" style={{ paddingLeft: 5}}>Data</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Text color="#93979F">Motivo disciplinar</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Text color="#93979F" style={{ marginLeft: -14}}>Estabelecimentos</Text>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Text color="#93979F" style={{ marginLeft: -20}}>Contrato</Text>
            </Grid>
          </Grid>
        </Hidden>

        <div style={{ overflowY: 'scroll', height: '60vh' }}>
          {(warnings && !isSearching) ? warnings.map((item) => <Card key={item.id} {...item} />) : null}
          {(filtered.length && isSearching) ? filtered.map((item) => <Card key={item.id} {...item} />) : null}
        </div>

      </div>
    </>
  );
}

export default WarningReportScreen;
