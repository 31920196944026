import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Grid } from '@mui/material';

import Text from 'components/Text';
import moment from 'moment';

import Theme from 'style/Theme';

const RegisterData = () => {
    const youngData = useStoreState(({ user }) => user.youngData);

    return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24}>Dados cadastrais</Text>

            <Text bold size={18} color={Theme.primary} style={{ marginTop: 25 }}>Dados pessoais</Text>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Data de nascimento</Text>
                    <Text bold>{moment(youngData?.data_nascimento).format('DD/MM/YYYY') || '--/--/----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Gênero</Text>
                    <Text bold>{youngData?.sexo || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Estado civil</Text>
                    <Text bold>{youngData?.estado_civil || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CPF</Text>
                    <Text bold>{youngData?.cpf || '---.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>RG</Text>
                    <Text bold>{youngData?.rg_numero || '--.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Possui deficiência?</Text>
                    <Text bold>{youngData?.deficiencia || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CEP</Text>
                    <Text bold>{youngData?.cep || '--.---.---'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Rua</Text>
                    <Text bold>{youngData?.logradouro || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Número</Text>
                    <Text bold>{youngData?.numero || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Complemento</Text>
                    <Text bold>{youngData?.complemento || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Bairro</Text>
                    <Text bold>{youngData?.bairro || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>UF</Text>
                    <Text bold>{youngData?.sigla || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Cidade</Text>
                    <Text bold>{youngData?.cidade || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Telefone 1</Text>
                    <Text bold>{youngData?.telefone_1 || '(--) -----.----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Telefone 2</Text>
                    <Text bold>{youngData?.telefone_2 || '(--) -----.----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Telefone 3</Text>
                    <Text bold>{youngData?.telefone_3 || '(--) -----.----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>E-mail</Text>
                    <Text bold>{youngData?.email || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Tamanho uniforme</Text>
                    <Text bold>{youngData?.uniforme || '-'}</Text>
                </Grid>
            </Grid>

            <Text bold size={18} color={Theme.primary} style={{ marginTop: 25 }}>Dados do responsável</Text>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Responsável:</Text>
                    <Text bold>{youngData?.responsavel || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={6} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nome do responsável</Text>
                    <Text bold>{youngData?.responsavel_nome || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>RG do responsável</Text>
                    <Text bold>{youngData?.responsavel_rg || '---.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CPF do responsável</Text>
                    <Text bold>{youngData?.responsavel_cpf || '--.---.---.--'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Escolaridade</Text>
                    <Text bold>{youngData?.escolaridade || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nome da escola</Text>
                    <Text bold>{youngData?.escola_nome || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Período</Text>
                    <Text bold>{youngData?.turno || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 15 }}>
                <Grid item xs={12} sm={12} md={4}>
                    <Text bold size={12} color='#93979F'>E-mail do responsável</Text>
                    <Text bold>{youngData?.responsavel_email || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Text bold size={12} color='#93979F'>Telefone do responsável</Text>
                    <Text bold>{youngData?.responsavel_telefone || '-'}</Text>
                </Grid>
            </Grid>

        </div>
    );
}

export default RegisterData;
