import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { CircularProgress, Grid } from '@mui/material';
import Text from 'components/Text';
import Theme from 'style/Theme';
import WarningCard from '../WarningCard';

const Warnings = ({ id }) => {
    const [loading, setLoading] = useState(true);

    const listWarnings = useStoreActions(({ youngs }) => youngs.listWarnings);
    const warnings = useStoreState(({ youngs }) => youngs.warnings);
    const [totalPoints, setTotalPoints] = useState(0);

    const init = async () => {
        setLoading(true);
        await listWarnings(id);
        for(let w of warnings) {
            console.log(w.pontos);
            setTotalPoints(totalPoints + w.pontos);
        }
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, [0]);


    if (loading) return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24}>Orientações e advertências</Text>

            <Grid container alignItems="center" justifyContent="center" direction="column">
                <CircularProgress style={{ color: Theme.primary, marginTop: 25 }} />
                <Text bold size={16} color={Theme.primary} style={{ marginTop: 5 }}>Carregando...</Text>
            </Grid>
        </div>
    );

    return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>

            <Grid container alignItems="center" justifyContent="left" direction="row">
                <Grid item xs={9} md={9} lg={9}>
                    <Text bold size={24} style={{ marginBottom: 24 }}>Orientações e advertências</Text>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <Text bold size={16} style={{ marginBottom: 18 }}>Total: {totalPoints} pontos</Text>
                </Grid>
            </Grid>

            {(warnings?.length) ?
                warnings.map((item) => { return <WarningCard key={item.id} props={item} /> })
                : null}

        </div>
    );
}

export default Warnings;
