import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid, Hidden } from '@mui/material';
import FilterAdvanced from 'components/FilterAdvanced';
import Text from 'components/Text';
import Card from './components/Card';
import Search from './components/Search';
import FilterAdvancedContent from './components/FilterAdvancedContent';
import Theme from 'style/Theme';

const PerformanceReportScreen = () => {

  const [isSearching, setIsSearching] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const openFilter = () => setShowFilter(true);
  const closeFilter = () => setShowFilter(false);

  const listPerformance = useStoreActions(({ reports }) => reports.listPerformance);
  const performances = useStoreState(({ reports }) => reports.performances);

  const handleSearch = async (value) => {
    let aux = value.toLowerCase();

    const result = performances.filter(item => ((item.nome.toLowerCase().indexOf(aux) != -1)
      || (item.estabelecimento.toLowerCase().indexOf(aux) != -1)));

    if (value && value.trim() != '' && result.length < performances.length) {
      setFiltered(result);
      setIsSearching(true);
    } else {
      setFiltered([]);
      setIsSearching(false);
    }
  }

  useEffect(() => {
    listPerformance();
  }, [0])

  return (
    <>
      <FilterAdvanced
        show={showFilter}
        onClose={closeFilter}
      >
        <FilterAdvancedContent />
      </FilterAdvanced>

      <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>
        <Text color="#F2F2F3" bold size={24}>Relatório de desempenho geral</Text>

        <Search
          handleSearch={handleSearch}
          handleOpenFilter={openFilter}
        />

        <Hidden mdDown>
          <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: '0 20px', marginTop: 15 }}
          >
            <Grid item xs={12} sm={12} md={3}>
              <Text color="#93979F">Nome</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Text color="#93979F">Idade</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Text color="#93979F">Estabelecimento</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Text color="#93979F">Desempenho</Text>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Text color="#93979F">Advertência</Text>
            </Grid>
            <Grid item xs={6} sm={6} md={1}>
              <Text color="#93979F">Absenteísmo</Text>
            </Grid>
          </Grid>
        </Hidden>

        <div style={{}}>
          {(performances && !isSearching) ? performances.map((item) => <Card key={item.id_jovem} {...item} />) : null}
          {(filtered && isSearching) ? filtered.map((item) => <Card key={item.id_jovem} {...item} />) : null}
        </div>

        {/* <Fab onClick={newYoung} color="primary" aria-label="add" style={{ right: 20, bottom: 20, position: 'fixed' }}>
          <Add />
        </Fab> */}

      </div>
    </>
  );
}

export default PerformanceReportScreen;
