import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import { setToken } from 'api';
import AuthAPI from 'api/Auth';

import { ValidateCPF } from 'services/Validation';

export default {
  loading: false,

  setLoading: action((state, payload) => {
    state.loading = !!payload;
  }),

  login: thunk((actions, { cpf, password }, { getStoreActions }) => {
    if (!ValidateCPF(cpf)) {
      return toast('CPF inválido', { type: 'danger' });
    } else if (!password || password.length < 6) {
      return toast('Senha inválida', { type: 'danger' });
    }

    actions.setLoading(true);

    return AuthAPI.login({ cpf, password }).then(async (response) => {
      if (response.ok) {
        localStorage.setItem('USER', JSON.stringify(response.data.user));
        await localStorage.setItem('@AUTH_TOKEN', response.data.token);
  
        setToken(response.data.token);
        const { user } = await getStoreActions();
        user.setUser(response.data.user);
        actions.setLoading(false);
        return true;
      }
  
      let description = response?.data?.message || 'Ocorreu um erro inesperado.';

      toast(description, { type: 'danger' });
      
      actions.setLoading(false);
      return false;
    });
  }),

  recoverPassword: thunk((actions, { cpf }, { getStoreActions }) => {
    if (!ValidateCPF(cpf)) {
      return toast('CPF inválido', { type: 'danger' });
    }

    actions.setLoading(true);

    return AuthAPI.recoverPassword({ cpf }).then(async (response) => {
      if (response.ok) {
        actions.setLoading(false);
        return true;
      }
  
      let description = response?.data?.message || 'Ocorreu um erro inesperado.';

      toast(description, { type: 'danger' });
      
      actions.setLoading(false);
      return false;
    });
  }),

  updatePassword: thunk((actions, { cpf, recoveryCode, password }, { getStoreActions }) => {
    if (!ValidateCPF(cpf)) {
      return toast('CPF inválido', { type: 'danger' });
    }

    actions.setLoading(true);

    return AuthAPI.updatePassword({ cpf, recovery_code: recoveryCode, password }).then(async (response) => {
      if (response.ok) {
        actions.setLoading(false);
        return true;
      }
  
      let description = response?.data?.message || 'Ocorreu um erro inesperado.';

      toast(description, { type: 'danger' });
      
      actions.setLoading(false);
      return false;
    });
  }),

};
