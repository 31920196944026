import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Drawer,
	Toolbar,
	Grid,
	Menu,
	MenuItem,
	Button,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';
import { useStoreState } from 'easy-peasy';

import { AccountCircle, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import LogoWhite from 'assets/images/logo.png';

import Text from 'components/Text';
import DrawerContent from './components/DrawerContent';

import Theme from 'style/Theme';
import * as Styled from './styles';

let PATHS_TO_SHOW_TOOLBAR = [
	'/aula',
	'/aula/',
	'/aulas',
	'/avisos',
	'/jovem/',
	'/desempenho',
	'/desempenho/aula/',
	'/desempenho/aula',
	'/aprendizado',
	'/ouvidoria',
	'/praticas',
	'/politicas-privacidade',
	'/termos',
	'/politica-protecao-dados',
	'/calendario',
	'/psicossocial',
	'/instituto',
	'/psicossocial/normas',
	'/psicossocial/proibicoes',
	'/psicossocial/advertencias',
	'/chat-alunos',
	'/aprendizes',
	'/relatorios',
	'/relatorios/jornada',
	'/relatorios/advertencia',
	'/relatorios/geral',
	'/relatorios/desempenho',
	'/cadastrar-aprendiz',
	'/cadastrar-aprendiz-dois',
	'/cadastrar-aprendiz-tres',
	'/registro-ponto'
];

const PATHS_TO_HIDE_DRAWER = [
];

const MainDrawer = ({ children }) => {
	const [open, setOpen] = React.useState(false);

	const user = useStoreState(({ user }) => user.user);
	const firstName = user && user?.name && user.name.split(' ')[0];
	const nameFormatted = firstName && firstName[0].toUpperCase() + firstName.slice(1).toLowerCase();
	const location = useLocation();
	const navigate = useNavigate();

	const [dialogLogout, setDialogLogout] = React.useState(false);

	const handleOpenDialogLogout = () => setDialogLogout(true);
	const handleCloseDialogLogout = () => setDialogLogout(false);

	const handleDrawerOpen = () => setOpen(true)

	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleMenu = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const handleLogout = () => {
		handleOpenDialogLogout();
	};

	const logout = () => {
		handleClose();
		handleCloseDialogLogout();

		localStorage.clear();
		navigate('/login');
		window.location.replace('/');
	};

	const token = localStorage.getItem('@AUTH_TOKEN');

	const isToShowDrawer = !PATHS_TO_HIDE_DRAWER.includes(location.pathname.replace(/[0-9]/g, ''));
	const isToShowToolbar = PATHS_TO_SHOW_TOOLBAR.includes(location.pathname.replace(/[0-9]/g, '')) && !!token;

	if (!isToShowToolbar) return children;

	return (
		<>
			<Dialog
				open={dialogLogout}
				keepMounted
				onClose={handleCloseDialogLogout}
				aria-describedby="logout-dialog"
			>
				<DialogTitle style={{ color: Theme.dark.primaryText, backgroundColor: Theme.dark.secondary, borderTop: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>Sair</DialogTitle>
				<DialogContent style={{ backgroundColor: Theme.dark.secondary, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<DialogContentText style={{ color: Theme.dark.primaryText }} id="logout-dialog-description">
						Você realmente deseja sair?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ backgroundColor: Theme.dark.secondary, borderBottom: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<Button onClick={handleCloseDialogLogout}>Cancelar</Button>
					<Button onClick={logout}>Sair</Button>
				</DialogActions>
			</Dialog>

			<Grid container direction="column" style={{ height: '100vh' }}>
				<Grid item>
					<Toolbar
						style={{
							backgroundColor: Theme.dark.tertiary,
							position: 'fixed',
							width: '100%',
							zIndex: 1000
						}}>
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<div>
								<Grid container direction="row" alignItems="center" marginLeft={'10%'}>
									<Styled.MenuDrawerSM item>
										<IconButton
											onClick={handleDrawerOpen}
											edge="start"
											sx={{ mr: 2, color: Theme.dark.primaryText }}
										>
											<MenuIcon />
										</IconButton>
									</Styled.MenuDrawerSM>
									<img src={LogoWhite} style={{ height: 20 }} alt="Logo" />
								</Grid>
							</div>

							<Button
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								style={{ marginRight: '5%', color: Theme.dark.primaryText }}
							>
								<AccountCircle style={{ color: Theme.dark.primaryText }} />
								<Text color={Theme.dark.primaryText} size={14} style={{ marginLeft: 7, marginRight: 2 }}>
									{nameFormatted}
								</Text>
								<ExpandMore style={{ color: Theme.dark.primaryText }} />
							</Button>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={!!anchorEl}
								onClose={handleClose}
							>
								<MenuItem onClick={handleLogout}>Sair</MenuItem>
							</Menu>
						</Grid>
					</Toolbar>
				</Grid>

				<Grid item style={{ flex: 1, width: '100%' }}>
					<Styled.DrawerContainer container direction="row">
						{isToShowDrawer && (
							<Styled.MenuDrawerSM>
								<Drawer
									variant="persistent"
									anchor="left"
									PaperProps={{
										backgroundColor: Theme.dark.secondary,
										style: { borderRight: 'none' }
									}}
									open={open}
									onBackdropClick={() => setOpen(false)}
									style={{ border: 'none' }}
								>
									<DrawerContent
										path={location.pathname}
										onClose={() => setOpen(false)}
										onLogout={handleOpenDialogLogout}
										userType={user?.type}
									/>
								</Drawer>
							</Styled.MenuDrawerSM>
						)}

						{isToShowDrawer && (
							<Styled.MenuDrawerMD>
								<DrawerContent
									path={location.pathname}
									onClose={() => setOpen(false)}
									onLogout={handleOpenDialogLogout}
									userType={user?.type}
									showClose={false}
								/>
							</Styled.MenuDrawerMD>
						)}

						<Grid item style={{ flex: 1, backgroundColor: Theme.background, }}>
							{children}
						</Grid>
					</Styled.DrawerContainer>
				</Grid>
			</Grid>
		</>
	);
};

export default MainDrawer;