import React from 'react';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlined from '@mui/icons-material/LocalPhoneOutlined';
import Text from 'components/Text';
import * as Styled from './styles';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { IJB_URL, IMG_PLACEHOLDER } from 'api';

const PendingYoungCard = (props) => {

  const young = props.props;
  const navigate = useNavigate();

  const sendEmail = (email) => {
    if (!email) return;
    window.open('mailto: ' + email, '_blank');
  }

  const sendMessage = (number) => {
    if (!number) return;
    navigator.clipboard.writeText(number);
  }

  const getStep = (step) => {
    if (step == 0) return 'Dados pessoais';
    if (step == 1) return 'Dados contratuais';
    if (step == 2) return 'Uploads';
  }

  const open = (id, step) => {
    if (step == 0) {
      navigate('/cadastrar-aprendiz', { state: { id: id } });
    }

    if (step == 1) {
      navigate('/cadastrar-aprendiz-dois', { state: { id: id } });
    }

    if (step == 2) {
      navigate('/cadastrar-aprendiz-tres', { state: { id: id } });
    }
  }

  return (
    <Styled.ItemContainer
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}
    >
      <Grid item container alignItems="center" xs={0.5} sm={0.5} md={0.5}>
        <img
          src={young.foto ? `${IJB_URL}${young.foto}` : IMG_PLACEHOLDER}
          width="40"
          height="40"
          alt='Foto do jovem'
          style={{ borderRadius: 100 }}
        />
      </Grid>

      <Grid item xs={2} sm={2} md={2}>
        <Text size={13} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '95%', overflow: 'hidden' }}>{young?.nome}</Text>
      </Grid>

      <Grid xs={0.5} sm={0.5} md={0.5}>
        <Text size={13}>{young?.idade}</Text>
      </Grid>

      <Grid xs={2} sm={2} md={2}>
        <Text size={13} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '95%', overflow: 'hidden', marginLeft: -10 }}>{young?.nome_fantasia}</Text>
      </Grid>
      
      <Grid xs={1} sm={1} md={1}>
        <Text size={13}>{young?.cidade}</Text>
      </Grid>

      <Grid xs={1.2} sm={1.2} md={1.2}>
        <Text size={13}>{young?.cnpj}</Text>
      </Grid>

      <Grid xs={1} sm={1} md={1} style={{ marginLeft: 8}}>
        <Text size={13}>{young?.tipo_contratacao}</Text>
      </Grid>

      <Grid xs={1.5} sm={1.5} md={1.5}>
        <Text style={{ fontSize: 14, marginLeft: 8, color: '#F2F2F3', fontWeight: 600 }}>{getStep(young.etapa_cadastro)}</Text>
      </Grid>

      <Grid container item xs={2} sm={2} md={2} justifyContent="space-between" alignItems="center" style={{ marginLeft: 24 }}>
        <div>
          <EmailOutlined fontSize='medium' style={{ color: young.email ? '#4789EB' : '#93979F' }} onClick={(e) => { e.stopPropagation(); sendEmail(young.email) }} />
          <LocalPhoneOutlined fontSize='medium' style={{ color: young.whatsapp ? '#4789EB' : '#93979F', marginLeft: 15 }} onClick={(e) => { e.stopPropagation(); sendMessage(young.whatsapp) }} />
        </div>
      </Grid>

    </Styled.ItemContainer >
  );
};

export default PendingYoungCard;
