import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import Text from 'components/Text';

import Theme from 'style/Theme';

const PageHeader = ({ icon, title }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    style={{
      padding: '10px 0',
      borderBottom: '1px solid #364563',
      color: Theme.secondary,
    }}
    key={`${title}_header`}
  >
    {!!icon && icon}
    <Text size={24} color={Theme.white.secondary} style={{ marginLeft: !!icon ? 10 : 0 }}>
      {title}
    </Text>
  </Grid>
);

PageHeader.defaultProps = {
  icon: null,
  title: '',
};

PageHeader.prototype = {
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
};

export default React.memo(PageHeader);
