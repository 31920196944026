import React, { useRef, useEffect } from 'react';
import { Checkbox, CircularProgress, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions, useStoreState } from 'easy-peasy';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router';

import { ValidateCPF } from 'services/Validation';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Form/Input';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';
import { toast } from "react-toastify";

const UpdatePasswordSchemaValidation = Yup.object().shape({
	cpf: Yup.string()
		.required('Campo obrigatório')
		.test('CPFvalidation', 'CPF inválido', (value) => ValidateCPF(value)),
	recoveryCode: Yup.string()
		.required('Campo obrigatório'),
	password: Yup.string()
		.required('Campo obrigatório'),
});

const initialValues = {
	cpf: '',
	recoveryCode: '',
	password: ''
};

const UpdatePasswordScreen = () => {
	let navigate = useNavigate();

	const loading = useStoreState(({ auth }) => auth.loading);
	const updatePassword = useStoreActions(({ auth }) => auth.updatePassword);

	const checkAlreadyLogged = async () => {
		const token = await localStorage.getItem('@AUTH_TOKEN');
		if (token) navigate('/aprendizes');
	};

	useEffect(() => {
		checkAlreadyLogged();
	}, [0]);

	const handleSumbit = async ({ cpf = '', recoveryCode = '', password = '' }) => {
		if (loading) return;

		let cleanedCPF = cpf;//cpf.replace(/\D+/g, '') || '';
		const response = await updatePassword({ cpf: cleanedCPF, recoveryCode: recoveryCode, password: password });
		if (response) {
			toast('Sua senha foi redefinida com sucesso!', { type: 'success' });
			navigate('/');
		}
	};

	return (
		<Grid container direction="row" style={{ flex: 1, backgroundColor: Theme.dark.background }}>
			<Grid item xs={12} sm={12} md={6} style={{ backgroundColor: Theme.dark.background, minHeight: '100vh' }}>
				<Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
					<Grid item style={{ width: '100%', maxWidth: 480, padding: 20 }}>
						<Grid marginTop={-10} marginBottom={2} container direction="column" alignItems="center" justifyContent="center">
							<Text color={Theme.dark.secondaryText} size={16}>Para completar a redefinição de senha, informe o seu CPF, o código de recuperação recebido no e-mail e a nova senha:</Text>
						</Grid>

						<Formik
							initialValues={initialValues}
							validationSchema={UpdatePasswordSchemaValidation}
							onSubmit={handleSumbit}
						>
							{({ errors, handleChange, handleSubmit, setFieldValue, touched, values }) => (
								<Styled.Form onSubmit={handleSumbit}>
									<Text bold color={Theme.dark.secondaryText} size={16}>CPF</Text>
									<InputMask
										mask="999.999.999-99"
										value={values.cpf}
										onChange={(value) => setFieldValue('cpf', value)}
										placeholder="Digite seu CPF"
									>
										{(inputProps) => <Input {...inputProps} type="tel" required style={{ marginTop: 5, backgroundColor: 'transparent', border: '1px solid #4789EB', borderRadius: 8, color: '#fff' }} />}
									</InputMask>
									{errors.cpf && touched.cpf ? (
										<ErrorText message={errors.cpf} />
									) : null}

									<Text bold color={Theme.dark.secondaryText} size={16} style={{ marginTop: 25 }}>Código de Recuperação</Text>
									<Input
										type="text"
										value={values.recoveryCode}
										onChange={handleChange('recoveryCode')}
										onSubmit={handleSubmit}
										placeholder="Informe o código de recuperação"
										required
										style={{ backgroundColor: 'transparent', color: '#fff' }}
									/>
									{errors.recoveryCode && touched.recoveryCode ? (
										<ErrorText message={errors.recoveryCode} />
									) : null}

									<Text bold color={Theme.dark.secondaryText} size={16} style={{ marginTop: 25 }}>Nova Senha</Text>
									<Input
										type="password"
										value={values.password}
										onChange={handleChange('password')}
										onSubmit={handleSubmit}
										isPassword
										placeholder="Digite sua senha"
										required
										style={{ backgroundColor: 'transparent', color: '#fff' }}
									/>
									{errors.password && touched.password ? (
										<ErrorText message={errors.password} />
									) : null}

									<Button
										background={Theme.dark.primary}
										style={{ marginTop: 20, padding: 15, width: '100%', height: 55 }}
										height={60}
										disabled={loading}
										onClick={handleSubmit}
										type="submit"
									>
										{loading ? <CircularProgress size={16} style={{ color: '#fff' }} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>REDEFINIR SENHA</Text>}
									</Button>

									<Button
										background={Theme.dark.primary}
										style={{ marginTop: 20, marginBottom: -20, padding: 15, width: '100%', height: 55 }}
										height={60}
										onClick={() => navigate('/recuperar-senha')}
										type="submit"
									>
										<Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>VOLTAR</Text>
									</Button>

								</Styled.Form>
							)}
						</Formik>
					</Grid>
				</Grid>
			</Grid>
			<Styled.SecondContainer item md={6}>
				<Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
					<Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
						Transformando <spam style={{ color: Theme.dark.green }}>vidas.</spam>
					</Text>
					<Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
						Transformando o <spam style={{ color: Theme.dark.green }}>Brasil.</spam>
					</Text>
				</Grid>
			</Styled.SecondContainer>
		</Grid>
	)
};

export default UpdatePasswordScreen;
