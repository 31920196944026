import { action, thunk } from 'easy-peasy';

import YoungsAPI from 'api/Youngs';

export default {
  user: {},
  young: null,
  contract: null,
  registration: null,
  youngs: [],
  pendingYoungs: [],
  journeyReport: [],
  generalReport: [],
  generalReportColumns: [],
  unities: [],
  companies: [],
  courses: [],
  classes: [],
  sectors: [],
  docs: null,
  theoreticalPlaces: [],
  practicalPlaces: [],
  loading: false,
  warnings: [],

  setYoung: action((state, value) => {
    state.young = value;
  }),

  setWarnings: action((state, value) => {
    state.warnings = value;
  }),

  setSectors: action((state, value) => {
    state.sectors = value;
  }),

  setRegistration: action((state, value) => {
    state.registration = value;
  }),

  setGeneralReportColumns: action((state, value) => {
    state.generalReportColumns = value;
  }),

  setPendingYoungs: action((state, value) => {
    state.pendingYoungs = value;
  }),

  setJourneyReport: action((state, value) => {
    state.journeyReport = value;
  }),

  setGeneralReport: action((state, value) => {
    state.generalReport = value;
  }),

  setDocs: action((state, value) => {
    state.docs = value;
  }),

  setContract: action((state, value) => {
    state.contract = value;
  }),

  setYoungs: action((state, value) => {
    state.youngs = value;
  }),

  setTheoreticalPlaces: action((state, value) => {
    state.theoreticalPlaces = value;
  }),

  setPracticalPlaces: action((state, value) => {
    state.practicalPlaces = value;
  }),

  setUnities: action((state, value) => {
    state.unities = value;
  }),

  setCompanies: action((state, value) => {
    state.companies = value;
  }),

  setCourses: action((state, value) => {
    state.courses = value;
  }),

  setClasses: action((state, value) => {
    state.classes = value;
  }),

  setUser: action((state, payload) => {
    state.user = payload;
  }),

  list: thunk(async (actions) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.list().then(response => {
        if (response.ok) {
          actions.setYoungs(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),


  listPending: thunk(async (actions) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listPending().then(response => {
        if (response.ok) {
          actions.setPendingYoungs(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listWarnings: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);
      YoungsAPI.getWarnings(payload).then(response => {
        if (response.ok) {
          actions.setWarnings(response.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e);
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),
  
  listJourneyReport: thunk(async (actions) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listJourneyReport().then(response => {
        if (response.ok) {
          actions.setJourneyReport(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listGeneralReportColumns: thunk(async (actions) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listGeneralReportColumns().then(response => {
        if (response.ok) {
          actions.setGeneralReportColumns(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),
  listGeneralReport: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listGeneralReport(payload).then(response => {
        if (response.ok) {
          actions.setGeneralReport(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listUnities: thunk(async (actions) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listUnities().then(response => {
        if (response.data || response.ok) {
          actions.setUnities(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listCompanies: thunk(async (actions) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listCompanies().then(response => {
        if (response.data || response.ok) {
          actions.setCompanies(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listCourses: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listCourses(payload).then(response => {
        if (response.data || response.ok) {
          actions.setCourses(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listDocs: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listDocs(payload).then(response => {
        if (response.data || response.ok) {
          actions.setDocs(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listClasses: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listClasses(payload.unity, payload.course).then(response => {
        if (response.data || response.ok) {
          actions.setClasses(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listSectors: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listSectors(payload).then(response => {
        if (response.data || response.ok) {
          actions.setSectors(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listTheoreticalPlaces: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listTheoreticalPlaces(payload).then(response => {
        if (response.data || response.ok) {
          actions.setTheoreticalPlaces(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listPracticalPlaces: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      YoungsAPI.listPracticalPlaces(payload).then(response => {
        if (response.data || response.ok) {
          actions.setPracticalPlaces(response.data.data);
          return true;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  listYoung: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      return await YoungsAPI.listYoung(payload).then(response => {
        if (response.data || response.ok) {
          actions.setYoung(response.data.data);
          return response.data.data;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  newYoung: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);
      return await YoungsAPI.newYoung(payload).then(response => {
        if (response.ok) {
          return response.data;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/aprendizes');
      return false;
    }
  }),

  updateYoung: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);
      return await YoungsAPI.updateYoung(payload).then(response => {
        if (response.ok) {
          return response.data;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/aprendizes');
      return false;
    }
  }),

  newContract: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);
      return await YoungsAPI.newContract(payload).then(response => {
        if (response.ok) {
          return response.data;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/aprendizes');
      return false;
    }
  }),

  getContract: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);
      return await YoungsAPI.getContract(payload).then(response => {
        if (response.data || response.ok) {
          actions.setContract({pdf: response.data.data, id: payload.young});
          return response.data.data;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),


  getRegistration: thunk(async (actions, payload) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);
      return await YoungsAPI.getRegistration(payload).then(response => {
        if (response.data || response.ok) {
          actions.setRegistration(response.data.data);
          return response.data.data;
        } else {
          return false;
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

};
