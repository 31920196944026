import API from 'api';

const login = (body) => API.post('/auth', body);
const recoverPassword = (body) => API.post('/auth/recover-password', body);
const updatePassword = (body) => API.post('/auth/update-password', body);

export default {
  login,
  recoverPassword,
  updatePassword,
};
