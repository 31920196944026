import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material'
import { Formik } from 'formik';
import Text from 'components/Text';
import Button from 'components/Button';
import Theme from 'style/Theme';
import { toast } from "react-toastify";
import * as Styled from './styles';
import UploadCard from './components/UploadCard';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'api';
import { create } from 'apisauce';

const initialValues = {};

const NewYoungStep3Screen = () => {


  const location = useLocation();
  let navigate = useNavigate();

  const getContract = useStoreActions(({ youngs }) => youngs.getContract);
  const listDocs = useStoreActions(({ youngs }) => youngs.listDocs);

  const contract = useStoreState(({ youngs }) => youngs.contract);
  const docs = useStoreState(({ youngs }) => youngs.docs);

  const [contrato, setContrato] = useState();
  const [rg, setRg] = useState();
  const [cpf, setCpf] = useState();
  const [responsavelRg, setResponsavelRg] = useState();
  const [responsavelCpf, setResponsavelCpf] = useState();
  const [escolaridade, setEscolaridade] = useState();
  const [foto, setFoto] = useState();
  const [esocial, setEsocial] = useState();
  const [loading, setLoading] = useState(false);

  const setFile = (file, type) => {

    switch (type) {
      case 'contrato':
        setContrato(file);
        break;
      case 'rg':
        setRg(file);
        break;
      case 'cpf':
        setCpf(file);
        break;
      case 'responsavel_rg':
        setResponsavelRg(file);
        break;
      case 'responsavel_cpf':
        setResponsavelCpf(file);
        break;
      case 'escolaridade':
        setEscolaridade(file);
        break;
      case 'foto':
        setFoto(file);
        break;
      case 'ctps_assinada':
        setEsocial(file);
        break;
      default:
        break;
    }

  };

  const uploadAllFiles = async () => {
    setLoading(true);
    if (contrato) await uploadFile(contrato, 'contrato', 'Contrato');
    if (rg) await uploadFile(rg, 'rg', 'RG');
    if (cpf) await uploadFile(cpf, 'cpf', 'CPF');
    if (responsavelRg) await uploadFile(responsavelRg, 'responsavel_rg', 'RG do responsável');
    if (responsavelCpf) await uploadFile(responsavelCpf, 'responsavel_cpf', 'CPF do responsável');
    if (escolaridade) await uploadFile(escolaridade, 'escolaridade', 'Comprovante de escolaridade');
    if (foto) await uploadFile(foto, 'foto', 'Foto do aprendiz');
    if (esocial) await uploadFile(esocial, 'ctps_assinada', 'Comprovante do eSocial');
    if (!contrato && !rg && !cpf && !responsavelRg && !responsavelCpf && !escolaridade && !foto && !esocial) {
      toast('Não há arquivos para carregar!', { type: 'error' });
      setLoading(false);
    }
  }


  const uploadFile = async (file, type, title) => {
    const api = create({
      baseURL: API_URL,
      headers: {
        Accept: 'application/json',
        authorization: localStorage.getItem('@AUTH_TOKEN'),
      },
    });

    let formdata = new FormData();
    formdata.append('file', file, file.path);
    formdata.append('title', file.name);
    formdata.append('type', type);
    formdata.append('young', location.state.id);
    formdata.append('responsible', (docs.responsavel == 14));

    api.post('/api/jovem/docs/upload', formdata)
      .then(response => {
        if (!response.ok) {
          toast('Erro ao carregar o arquivo ' + title + ', tente novamente!', { type: 'error' });
          setLoading(false);
          return;
        }

        listDocs(location.state.id);
        toast(title + ' carregado com sucesso!', { type: 'success' });
        clearSelectedFile(type);
        setLoading(false);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  /*const uploadFile = async (file, type, title) => {

    var myHeaders = new Headers();
    const token = localStorage.getItem('@AUTH_TOKEN');
    myHeaders.append("authorization", token);
    myHeaders.append("Accept", "application/json");

    var formdata = new FormData();
    formdata.append("file", file, file.path);
    formdata.append("title", file.name);
    formdata.append("type", type);
    formdata.append("young", location.state.id);
    formdata.append("responsible", (docs.responsavel == 14));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };


    fetch(API_URL + "/api/jovem/docs/upload", requestOptions)
      .then(response => {
        //get status code
        let status = response.status;

        if(status != 200) {
          toast('Erro ao carregar o arquivo ' + title + ', tente novamente!', { type: 'error' });
          setLoading(false);
          return;
        }

        listDocs(location.state.id);
        toast(title + ' carregado com sucesso!', { type: 'success' });
        clearSelectedFile(type);
        setLoading(false);
        
      })
      .catch(error => {
        console.log('error', error);
      });
  };*/

  const clearSelectedFile = (type) => {
    switch (type) {
      case 'contrato':
        setContrato(null);
        break;
      case 'rg':
        setRg(null);
        break;
      case 'cpf':
        setCpf(null);
        break;
      case 'responsavel_rg':
        setResponsavelRg(null);
        break;
      case 'responsavel_cpf':
        setResponsavelCpf(null);
        break;
      case 'escolaridade':
        setEscolaridade(null);
        break;
      case 'foto':
        setFoto(null);
        break;
      case 'ctps_assinada':
        setEsocial(null);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    getContract({ young: location.state.id });
    listDocs(location.state.id);
  }, [0]);

  const handleSumbit = async () => { };

  return (
    <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>

      <table style={{ width: '100%', borderBottom: '1px solid #364563', paddingBottom: 8 }}>
        <tr>
          <td style={{ width: 40, cursor: 'pointer' }}>
            <Grid onClick={() => { navigate('/aprendizes'); }} container xs={12} sm={12} md={12} lg={12} style={{ width: 40, height: 40, border: '1px solid #144672', borderRadius: 8, justifyContent: 'center', alignContent: 'center' }}>
              <ArrowBack sx={{ color: '#144672', fontSize: 24 }} />
            </Grid>
          </td>
          <td>
            <Text color={'#93979F'} bold size={14} style={{ marginLeft: 4 }}>Contratar aprendiz</Text>
            <Text color={'#F2F2F3'} bold size={24} style={{ marginTop: -2, marginLeft: 4 }}>Contrato para assinar & Envio de arquivos</Text>
          </td>
        </tr>
      </table>

      <Grid container direction="row" alignItems="flex-end" style={{ paddingTop: 20 }} sx={{ backgroundColor: Theme.dark.background }}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSumbit}
        >
          {({ errors, handleChange, handleSubmit, setFieldValue, handleBlur, touched, values }) => (
            <Styled.Form onSubmit={handleSumbit}>

              <Grid container>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  {(location.state.id == contract?.id && contract?.pdf) ? <embed src={contract.pdf} width="100%" height="375"></embed> : <div style={{ minHeight: 375, width: '100%', alignItems: 'center', alignContent: 'center', textAlign: 'center' }}><Text style={{ marginTop: 160 }}>Carregando contrato...</Text></div>}


                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: 10, marginTop: 10 }}>
                  <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px', fontWeight: 600 }}>Upload de arquivos</Text>
                  <hr style={{ border: '1px solid #364563' }} />
                </Grid>


                {docs ? <Grid container direction="row" xs={12} sm={12} md={12} lg={12}>

                  <UploadCard props={{ title: 'Contrato assinado', path: docs.contrato, setFile: setFile, type: 'contrato' }} />
                  <UploadCard props={{ title: 'Cópia do RG do aprendiz', path: docs.rg, setFile: setFile, type: 'rg' }} />

                </Grid> : null}

                {docs ? <Grid container direction="row" xs={12} sm={12} md={12} lg={12}>

                  <UploadCard props={{ title: 'Cópia do CPF do aprendiz', path: docs.cpf, setFile: setFile, type: 'cpf' }} />
                  <UploadCard props={{ title: 'Foto do aprendiz', path: docs.foto, setFile: setFile, type: 'foto' }} />


                </Grid> : null}

                {(docs && docs.responsavel !== 14) ? <Grid container direction="row" xs={12} sm={12} md={12} lg={12}>

                  <UploadCard props={{ title: 'Cópia do RG do responsável (quando menor)', path: docs.responsavel_rg, setFile: setFile, type: 'responsavel_rg' }} />
                  <UploadCard props={{ title: 'Cópia do CPF do responsável (quando menor)', path: docs.responsavel_cpf, setFile: setFile, type: 'responsavel_cpf' }} />


                </Grid> : null}

                {docs ? <Grid container direction="row" xs={12} sm={12} md={12} lg={12}>

                  <UploadCard props={{ title: 'Declaração de escolaridade', path: docs.escolaridade, setFile: setFile, type: 'escolaridade' }} />
                  <UploadCard props={{ title: 'Comprovante de registro eSocial', path: docs.ctps_assinada, setFile: setFile, type: 'ctps_assinada' }} />

                </Grid> : null}

              </Grid>

              <Grid container xs={12} sm={12} md={12} lg={12} style={{ padding: 10, marginTop: 16, paddingBottom: 200, justifyContent: 'flex-end' }}>
                <Button
                  background={Theme.dark.primary}
                  style={{ padding: 5, width: '200px', height: 40 }}
                  height={40}
                  onClick={uploadAllFiles}
                  loading={loading}
                >
                  <Text color={Theme.dark.primaryText} size={12} bold style={{ letterSpacing: '1px' }}>ENVIAR ARQUIVOS</Text>
                </Button>
              </Grid>

            </Styled.Form>
          )}
        </Formik>
      </Grid>
    </div>
  );
}

export default NewYoungStep3Screen;
