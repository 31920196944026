import styled from 'styled-components';
import { Grid } from '@mui/material';
import Input from 'components/Form/Input';
import InputMask from 'react-input-mask';
import Select from 'react-select';

import BackgroundImage from 'assets/images/background-login.jpg';

export const Container = styled.div`
  align-items: center;
  background-color: #fff;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const SecondContainer = styled(Grid)`
  background-image: url(${BackgroundImage});
  background-size: cover;
  flex: 1;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  @media (max-width: 899px) {
    display: none;
  }
`;

export const Form = styled.form`
  align-items: center;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const Input2 = styled(Input)`
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const Input3 = styled(InputMask)`
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const Select2 = styled(Select)`
  border: 1px solid #364563 !important;
  border-radius: 8px;
  font-size: 14px;
`;

export const SelectGrid = styled(Grid)`

  div {
    background-color: rgba(28,32,35,0.6);
    color: #ffffff !important;
    border-radius: 8px;
    border: none;
  }

  svg {
    color: #4789EB;
  }

  span {
    border: 1px solid rgba(28,32,35);
  }


`;