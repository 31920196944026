import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import UserAPI from 'api/User';

export default {
  user: {},
  current: {},
  registerDataAditivos: [],
  contractData: {},
  youngData: {},

  setUser: action((state, payload) => {
    state.user = payload;
  }),

  setData: action((state, { field, payload }) => {
    state[field] = payload;
  }),

  getMe: thunk(async (actions) => {
    try {
      let user = await localStorage.getItem('USER') || null;
      user = JSON.parse(user) || {};
      actions.setUser(user);

      UserAPI.me().then(response => {
        if (response.ok) {
          actions.setUser(response.data);
          localStorage.setItem('USER', JSON.stringify(response.data));
          return true;
        } else {
          throw "unathorized";
        }
      });
    } catch (e) {
      console.error('Error', e)
      localStorage.clear();
      window.location.replace('/login');
      return false;
    }
  }),

  getContractData: thunk(async (actions, payload) => {
    try {
      const response = await UserAPI.getContractData(payload);

      if (response.ok) {
        actions.setData({ field: 'contractData', payload: response.data.data });
        return true;
      }

      toast.error(response.data.message || 'Ocorre um erro inesperado');
      return false;
    } catch (e) {
      return false;
    }
  }),

  getRegisterData: thunk(async (actions, payload) => {
    try {
      const response = await UserAPI.getRegisterData(payload);

      if (response.ok) {
        actions.setData({ field: 'current', payload: response.data.atual });
        actions.setData({ field: 'registerDataAditivos', payload: response.data.aditivos });
        return true;
      }

      toast.error(response.data.message || 'Ocorre um erro inesperado');
      return false;
    } catch (e) {
      return false;
    }
  }),

  getYoungData: thunk(async (actions, payload) => {
    try {
      const response = await UserAPI.getYoungData(payload);

      if (response.ok) {
        actions.setData({ field: 'youngData', payload: response.data.data });
        return true;
      }

      toast.error(response.data.message || 'Ocorre um erro inesperado');
      return false;
    } catch (e) {
      return false;
    }
  }),
};
