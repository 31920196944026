import React from 'react';
import Text from 'components/Text';
import { Grid, Button } from '@mui/material';
import { CheckCircleOutline, FileUploadOutlined, CloseOutlined, PublishOutlined } from '@mui/icons-material'
import Theme from 'style/Theme';
import { toast } from "react-toastify";

const UploadCard = (props) => {

  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = React.useState(false);
  const IMGTYPES = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'];

  const saveFile = async (e) => {

    let aux = e.target.files[0].name.split('.');
    let ext = aux[aux.length - 1];

    if (props.props.type !== 'foto' && ext !== 'pdf') {
      toast('Formato inválido, informe um PDF!', { type: 'error' });
      return;
    }

    if (props.props.type === 'foto' && IMGTYPES.indexOf(ext) == -1) {
      toast('Erro ao carregar a foto! Fomatos aceitos: JPG, JPEG, PNG', { type: 'error' });
      return;
    }

    props.props.setFile(e.target.files[0], props.props.type);
    setSelectedFile(true);
  };

  return (
    <Grid container direction="row" xs={6} sm={6} md={6} lg={6} style={{ padding: 8 }}>
      <Grid container direction="row" xs={12} sm={12} md={12} lg={12} style={{ background: '#1E262D', padding: 5, border: '1px solid #364563', borderRadius: 8 }}>

        {(props.props.path) ?
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <div style={{ width: 72, height: 72, background: 'rgba(47, 196, 153, 0.3)', border: '1px solid #2FC499', borderRadius: 8, textAlign: 'center' }}>
              <CheckCircleOutline sx={{ color: '#2FC499', fontSize: 32, marginTop: 2.2 }} />
            </div>
          </Grid> :
          ((selectedFile) ?
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <div style={{ width: 72, height: 72, background: 'rgba(127, 140, 141, 0.3)', border: '1px solid #7f8c8d', borderRadius: 8, textAlign: 'center' }}>
                <PublishOutlined sx={{ color: '#7f8c8d', fontSize: 32, marginTop: 2.2 }} />
              </div>
            </Grid>
            :
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <div style={{ width: 72, height: 72, background: 'rgba(252, 79, 129, 0.3)', border: '1px solid #FC4F81', borderRadius: 8, textAlign: 'center' }}>
                <CloseOutlined sx={{ color: '#FC4F81', fontSize: 32, marginTop: 2.2 }} />
              </div>
            </Grid>
          )}

        <Grid item xs={12} sm={12} md={9} lg={9} style={{ paddingTop: 27 }}>
          <Text color={Theme.dark.secondaryText} size={16} bold>{props.props.title}</Text>
        </Grid>

        <Grid item xs={12} sm={12} md={1} lg={1} style={{}}>
          <Button onClick={() => { hiddenFileInput.current.click(); }} style={{ background: 'none', border: 'none', width: 35, height: 35, marginTop: 20, marginRight: 40 }}>
            <FileUploadOutlined sx={{ color: '#4789EB', fontSize: 32 }} />
          </Button>
          <input type="file" ref={hiddenFileInput} onChange={saveFile} style={{ visibility: 'hidden' }} />
        </Grid>

      </Grid>
    </Grid>
  );
};

export default UploadCard;
