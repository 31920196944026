import React, { useEffect, useState, useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material'
import { Formik } from 'formik';
import Text from 'components/Text';
import Button from 'components/Button';
import Theme from 'style/Theme';
import { toast } from "react-toastify";
import * as Styled from './styles';
import ErrorText from 'components/ErrorText';
import Moment from 'moment';
import CurrencyInput from './components/CurrencyInput';

const initialValues = {
  company: '',
  fantasy_name: '',
  course: '',
  class: '',
  start_date: null,
  end_date: null,
  start_time: null, //theoretical
  end_time: null, //theoretical
  start_time_practical: null,
  end_time_practical: null
};

const NewYoungStep2Screen = () => {

  let navigate = useNavigate();
  const location = useLocation();

  const newContract = useStoreActions(({ youngs }) => youngs.newContract);
  const listUnities = useStoreActions(({ youngs }) => youngs.listUnities);
  const listCompanies = useStoreActions(({ youngs }) => youngs.listCompanies);
  const listCourses = useStoreActions(({ youngs }) => youngs.listCourses);
  const listClasses = useStoreActions(({ youngs }) => youngs.listClasses);
  const listSectors = useStoreActions(({ youngs }) => youngs.listSectors);
  const listTheoreticalPlaces = useStoreActions(({ youngs }) => youngs.listTheoreticalPlaces);
  const listPracticalPlaces = useStoreActions(({ youngs }) => youngs.listPracticalPlaces);

  const [loading, setLoading] = useState(false);
  const companiesList = useStoreState(({ youngs }) => youngs.companies);
  const coursesList = useStoreState(({ youngs }) => youngs.courses);
  const classesList = useStoreState(({ youngs }) => youngs.classes);
  const sectorsList = useStoreState(({ youngs }) => youngs.sectors);
  const theoreticalPlaces = useStoreState(({ youngs }) => youngs.theoreticalPlaces);
  const practicalPlaces = useStoreState(({ youngs }) => youngs.practicalPlaces);

  const [unity, setUnity] = useState(null);
  const [fantasyName, setFantasyName] = useState('');
  const [course, setCourse] = useState('');
  const [classAux, setClass] = useState('');
  const [sectorAux, setSector] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [theoretical, setTheoretical] = useState('');
  const [practical, setPractical] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startTimePractical, setStartTimePractical] = useState(null);
  const [endTimePractical, setEndTimePractical] = useState(null);
  const [salMesBg, setSalMesBg] = useState('#4789EB');
  const [salHoraBg, setSalHoraBg] = useState('transparent');
  const [salMesColor, setSalMesColor] = useState('white');
  const [salHoraColor, setSalHoraColor] = useState('#4789EB');
  const [transport, setTransport] = useState(null);
  const [insalub, setInsalub] = useState({ value: 1, label: 'Não recebe' });
  const [peric, setPeric] = useState({ value: 1, label: 'Não recebe' });
  const [sal, setSal] = useState(null);
  const [startVacation, setStartVacation] = useState(null);
  const [endVacation, setEndVacation] = useState(null);

  const [semFeriasBg, setSemFeriasBg] = useState('#4789EB');
  const [comFeriasBg, setComFeriasBg] = useState('transparent');
  const [semFeriasColor, setSemFeriasColor] = useState('white');
  const [comFeriasColor, setComFeriasColor] = useState('#4789EB');

  const [comInsalubBg, setComInsalubBg] = useState('transparent');
  const [semInsalubBg, setSemInsalubBg] = useState('#4789EB');
  const [comInsalubColor, setComInsalubColor] = useState('#4789EB');
  const [semInsalubColor, setSemInsalubColor] = useState('white');

  const [comPericBg, setComPericBg] = useState('transparent');
  const [semPericBg, setSemPericBg] = useState('#4789EB');
  const [comPericColor, setComPericColor] = useState('#4789EB');
  const [semPericColor, setSemPericColor] = useState('white');

  //OLD
  const [company, setCompany] = useState('');

  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    listUnities();
    listCompanies();
  }, [0]);

  const handleSumbit = async () => {

    if (loading) return;

    if (!company.value) {
      toast('Informe o estabelecimento cumpridor da cota!', { type: 'error' });
      return;
    }

    if (!course.value) {
      toast('Informe o curso!', { type: 'error' });
      return;
    }

    if (!classAux.value) {
      toast('Informe a turma!', { type: 'error' });
      return;
    }

    if (!startDate) {
      toast('Informe a data de início do contrato!', { type: 'error' });
      return;
    }

    if (!theoretical) {
      toast('Informe o local da teórica!', { type: 'error' });
      return;
    }

    if (!startTime) {
      toast('Informe o horário de início da teórica!', { type: 'error' });
      return;
    }

    if (!practical) {
      toast('Informe o local da prática!', { type: 'error' });
      return;
    }

    if (!startTimePractical) {
      toast('Informe o horário de início da prática!', { type: 'error' });
      return;
    }

    if (salMesBg == 'transparent' && !sal) {
      toast('Informe o salário hora!', { type: 'error' });
      return;
    }

    if (salMesBg != 'transparent' && !sal) {
      toast('Informe o salário mensal!', { type: 'error' });
      return;
    }

    if (!transport || !transport.value) {
      toast('Informe se haverá vale transporte!', { type: 'error' });
      return;
    }

    if (comFeriasBg != 'transparent' && !startVacation) {
      toast('Informe a data de início das férias!', { type: 'error' });
      return;
    }

    if (comFeriasBg != 'transparent' && !endVacation) {
      toast('Informe a data fim das férias!', { type: 'error' });
      return;
    }

    if (comInsalubBg != 'transparent' && !insalub) {
      toast('Informe o grau de insalubridade!', { type: 'error' });
      return;
    }

    if (comPericBg != 'transparent' && !peric) {
      toast('Informe o grau de periculosidade!', { type: 'error' });
      return;
    }

    setLoading(true);

    let formData = {
      young: location.state.id,
      company: company.value,
      course: course.value,
      course_id: course.id,
      hours: course.qtde_horas,
      proposta: course.proposta,
      class: classAux.value,
      id_turma_meses: classAux.id_turma_meses,
      qtd_meses: course.meses,
      start_date: startDate,
      end_date: endDate,
      theoretical: theoretical.value,
      theoretical_start: startTime,
      theoretical_end: endTime,
      practical: practical.value,
      practical_start: startTimePractical,
      practical_end: endTimePractical,
      monthly_sal: (salMesBg != 'transparent'),
      salary: sal,
      transport: transport.value,
      vacation: (comFeriasBg != 'transparent'),
      vacation_start: startVacation,
      vacation_end: endVacation,
      insalub: insalub.value,
      peric: peric.value,
      sector: sectorAux?.value
    }

    newContract(formData).then(response => {
      if (response) {
        setLoading(false);
        toast('Contrato gerado com sucesso!', { type: 'success' });
        //navigate(-1);
        setTimeout(() => {
          navigate('/cadastrar-aprendiz-tres', { state: { id: location.state.id } });
        }, 2000);
      } else {
        setLoading(false);
        toast('Não foi possível realizar o cadastro, tente novamente!', { type: 'error' });
      }
    });

  };

  const resetTime = () => {
    setStartTime(null);
    setEndTime(null);
    setStartTimePractical(null);
    setEndTimePractical(null);
  }

  const updateEndDate = (dt) => {
    let aux = new Date(dt);
    aux.setMonth(aux.getMonth() + course.meses);
    const formatedDate = Moment(aux).format('YYYY-MM-DD');
    setEndDate(formatedDate);
  }

  const updateEndTime = (dt) => {
    let hours = dt.split(':')[0];
    let minutes = dt.split(':')[1];
    hours = Number(hours) + Number(course.horas_teorica);
    if (hours < 10) hours = '0' + hours;
    setEndTime(hours + ':' + minutes);
  }

  const updateEndTimePractical = (dt) => {
    let hours = dt.split(':')[0];
    let minutes = dt.split(':')[1];
    hours = Number(hours) + Number(course.horas_pratica);
    if (hours < 10) hours = '0' + hours;
    setEndTimePractical(hours + ':' + minutes);
  }

  const transportOp = [
    { value: 1, label: 'Não pegará vale transporte' },
    { value: 2, label: 'Pegará para TODOS os dias' },
    { value: 3, label: 'Será fornecido pela empresa' },
    { value: 4, label: 'Somente para curso' },
    { value: 5, label: 'Somente para empresa' },
    { value: 6, label: 'Afastamento/Licença' }
  ];

  const insalubOp = [
    { value: 1, label: 'Não recebe' },
    { value: 2, label: 'Leve 10%' },
    { value: 3, label: 'Médio 20%' },
    { value: 4, label: 'Grave 40%' }
  ];

  const pericOp = [
    { value: 1, label: 'Não recebe' },
    { value: 2, label: '30%' },
    { value: 3, label: '40%' }
  ];

  return (
    <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>

      <table style={{ width: '100%', borderBottom: '1px solid #364563', paddingBottom: 8 }}>
        <tr>
          <td style={{ width: 40, cursor: 'pointer' }}>
            <Grid onClick={() => { navigate('/aprendizes'); }} /*onClick={() => { navigate('/cadastrar-aprendiz', { state: { id: location.state.id } }); }}*/ container xs={12} sm={12} md={12} lg={12} style={{ width: 40, height: 40, border: '1px solid #144672', borderRadius: 8, justifyContent: 'center', alignContent: 'center' }}>
              <ArrowBack sx={{ color: '#144672', fontSize: 24 }} />
            </Grid>
          </td>
          <td>
            <Text color={'#93979F'} bold size={14} style={{ marginLeft: 4 }}>Contratar aprendiz</Text>
            <Text color={'#F2F2F3'} bold size={24} style={{ marginTop: -2, marginLeft: 4 }}>Dados do contrato</Text>
          </td>
        </tr>
      </table>

      <Grid container direction="row" alignItems="flex-end" style={{ paddingTop: 20 }} sx={{ backgroundColor: Theme.dark.background }}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSumbit}
        >
          {({ errors, handleChange, handleSubmit, setFieldValue, handleBlur, touched, values }) => (
            <Styled.Form onSubmit={handleSumbit}>

              <Grid container>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Styled.SelectGrid item xs={12} sm={12} md={6} lg={6} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Estabelecimento cumpridor da cota <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      styles={{
                        background: 'red',
                      }}
                      placeholder="Escolha uma opção"
                      value={company}
                      required
                      onChange={(value) => { setCompany(value); setFantasyName(value.fantasy_name); listCourses(value.value); listSectors(value.value); setUnity(value.unidade); listTheoreticalPlaces(value.unidade); listPracticalPlaces(value.value); setSector({}) }}
                      options={companiesList} />
                  </Styled.SelectGrid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Nome fantasia</Text>
                    <Styled.Input2
                      type="text"
                      value={fantasyName}
                      onChange={(value) => { setFantasyName(value); }}
                      placeholder="Vinculado ao estabelecimento"
                      required
                      disabled
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.fantasy_name && touched.fantasy_name ? (
                      <ErrorText message={errors.fantasy_name} />
                    ) : null}
                  </Grid>

                  <Styled.SelectGrid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Curso <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={course}
                      onChange={(value) => { setCourse(value); listClasses({ unity: unity, course: value.id }); setClass({}); resetTime() }}
                      options={coursesList} />
                  </Styled.SelectGrid>

                  <Styled.SelectGrid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Turma <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha"
                      value={classAux}
                      onChange={(value) => {
                        setClass(value);
                        if (startDate) updateEndDate(startDate);
                      }}
                      options={classesList} />
                  </Styled.SelectGrid>

                  <Styled.SelectGrid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Meses da turma</Text>
                    <Text color={'#93979F'} size={18} style={{ paddingTop: 10, color: '#93979F' }}>{course.meses || '-'} meses</Text>
                  </Styled.SelectGrid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Styled.SelectGrid item xs={6} sm={6} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Setor</Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={sectorAux}
                      onChange={(value) => { setSector(value); }}
                      options={sectorsList} />
                  </Styled.SelectGrid>

                  <Grid item xs={6} sm={6} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Data de início do contrato <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="date"
                      value={startDate}
                      onChange={(value) => {
                        setStartDate(value);
                        updateEndDate(value);
                      }}
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.start_date && touched.start_date ? (
                      <ErrorText message={errors.start_date} />
                    ) : null}
                  </Grid>

                  <Grid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Data fim do contrato</Text>
                    <Text color={'#93979F'} size={18} style={{ paddingTop: 10, color: '#93979F' }}>{(endDate) ? Moment(endDate).format('DD/MM/YYYY') : null}</Text>
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Styled.SelectGrid item xs={12} sm={12} md={8} lg={8} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Local da teórica <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={theoretical}
                      onChange={(value) => { setTheoretical(value); }}
                      options={theoreticalPlaces} />
                  </Styled.SelectGrid>

                  <Grid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Hora de início teórica <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="time"
                      value={startTime}
                      onChange={(value) => {
                        setStartTime(value);
                        updateEndTime(value);
                      }}
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.start_time && touched.start_time ? (
                      <ErrorText message={errors.start_time} />
                    ) : null}
                  </Grid>

                  <Grid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Hora fim teórica</Text>
                    <Text color={'#93979F'} size={18} style={{ paddingTop: 10, color: '#93979F' }}>{endTime}</Text>
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Styled.SelectGrid item xs={12} sm={12} md={8} lg={8} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Local da prática <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={practical}
                      onChange={(value) => { setPractical(value); }}
                      options={practicalPlaces} />
                  </Styled.SelectGrid>

                  <Grid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Hora de início prática <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="time"
                      value={startTimePractical}
                      onChange={(value) => {
                        setStartTimePractical(value);
                        updateEndTimePractical(value);
                      }}
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.start_time_practical && touched.start_time_practical ? (
                      <ErrorText message={errors.start_time_practical} />
                    ) : null}
                  </Grid>

                  <Grid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Hora fim prática</Text>
                    <Text color={'#93979F'} size={18} style={{ paddingTop: 10, color: '#93979F' }}>{endTimePractical}</Text>
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>


                  <Grid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Receberá salário por: <span style={{ color: '#4789EB' }}>*</span></Text>

                    <Grid container xs={12} sm={12} md={12} lg={12}>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                          background={salMesBg}
                          style={{ padding: 5, width: '100%', height: 40, borderRadius: 8, border: '1px solid #4789EB' }}
                          height={40}
                          onClick={() => { setSalMesBg('#4789EB'); setSalHoraBg('transparent'); setSalMesColor('white'); setSalHoraColor('#4789EB') }}
                        >
                          <Text color={salMesColor} size={12} bold style={{ letterSpacing: '1px' }}>MÊS</Text>
                        </Button>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: 10 }}>
                        <Button
                          background={salHoraBg}
                          style={{ padding: 5, width: '100%', height: 40, borderRadius: 8, border: '1px solid #4789EB' }}
                          height={40}
                          onClick={() => { setSalMesBg('transparent'); setSalHoraBg('#4789EB'); setSalMesColor('#4789EB'); setSalHoraColor('white') }}
                        >
                          <Text color={salHoraColor} size={12} bold style={{ letterSpacing: '1px' }}>HORA</Text>
                        </Button>
                      </Grid>

                    </Grid>

                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Salário {(salMesBg == 'transparent') ? ' hora' : ' mês'} <span style={{ color: '#4789EB' }}>*</span></Text>
                    <CurrencyInput
                      style={{ width: '100%', backgroundColor: 'transparent', color: '#fff', paddingTop: 5, paddingBottom: 5, marginTop: 15, border: '1px solid #364563', paddingLeft: 10, borderRadius: 8, fontSize: 16, height: 30, decoration: 'none' }}
                      onChange={(e) => {
                        let vl = e.target.value;
                        vl = vl.replace('R$ ', '');
                        vl = vl.replace('.', '');
                        vl = vl.replace(',', '.');
                        vl = Number(vl);
                        setSal(vl);
                      }}
                      placeholder="R$ 0,00"
                      type="text"
                      required
                    />
                  </Grid>

                  <Styled.SelectGrid item xs={6} sm={6} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Vale transporte <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={transport}
                      onChange={(value) => {
                        setTransport(value);
                      }}
                      options={transportOp} />
                  </Styled.SelectGrid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={6} sm={6} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Status de férias <span style={{ color: '#4789EB' }}>*</span></Text>

                    <Grid container xs={12} sm={12} md={12} lg={12}>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                          background={semFeriasBg}
                          style={{ padding: 5, width: '100%', height: 40, borderRadius: 8, border: '1px solid #4789EB' }}
                          height={40}
                          onClick={() => { setSemFeriasBg('#4789EB'); setComFeriasBg('transparent'); setSemFeriasColor('white'); setComFeriasColor('#4789EB') }}
                        >
                          <Text color={semFeriasColor} size={12} bold style={{ letterSpacing: '1px' }}>SEM FÉRIAS</Text>
                        </Button>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: 10 }}>
                        <Button
                          background={comFeriasBg}
                          style={{ padding: 5, width: '100%', height: 40, borderRadius: 8, border: '1px solid #4789EB' }}
                          height={40}
                          onClick={() => { setSemFeriasBg('transparent'); setComFeriasBg('#4789EB'); setSemFeriasColor('#4789EB'); setComFeriasColor('white') }}
                        >
                          <Text color={comFeriasColor} size={12} bold style={{ letterSpacing: '1px' }}>FÉRIAS DEFINIDA</Text>
                        </Button>
                      </Grid>

                    </Grid>

                  </Grid>

                  <Grid item xs={6} sm={6} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Início das férias {(comFeriasBg != 'transparent') ? <span style={{ color: '#4789EB' }}>*</span> : null}</Text>
                    <Styled.Input2
                      type="date"
                      disabled={(comFeriasBg == 'transparent')}
                      value={startVacation}
                      onChange={(value) => {
                        setStartVacation(value);
                      }}
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.start_date && touched.start_date ? (
                      <ErrorText message={errors.start_date} />
                    ) : null}
                  </Grid>

                  <Grid item xs={6} sm={6} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Fim das férias {(comFeriasBg != 'transparent') ? <span style={{ color: '#4789EB' }}>*</span> : null}</Text>
                    <Styled.Input2
                      type="date"
                      value={endVacation}
                      disabled={(comFeriasBg == 'transparent')}
                      onChange={(value) => {
                        setEndVacation(value);
                      }}
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.start_date && touched.start_date ? (
                      <ErrorText message={errors.start_date} />
                    ) : null}
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Receberá insalubridade? <span style={{ color: '#4789EB' }}>*</span></Text>

                    <Grid container xs={12} sm={12} md={12} lg={12}>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                          background={comInsalubBg}
                          style={{ padding: 5, width: '100%', height: 40, borderRadius: 8, border: '1px solid #4789EB' }}
                          height={40}
                          onClick={() => { setComInsalubBg('#4789EB'); setSemInsalubBg('transparent'); setComInsalubColor('white'); setSemInsalubColor('#4789EB'); setInsalub(null) }}
                        >
                          <Text color={comInsalubColor} size={12} bold style={{ letterSpacing: '1px' }}>SIM</Text>
                        </Button>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: 10 }}>
                        <Button
                          background={semInsalubBg}
                          style={{ padding: 5, width: '100%', height: 40, borderRadius: 8, border: '1px solid #4789EB' }}
                          height={40}
                          onClick={() => { setComInsalubBg('transparent'); setSemInsalubBg('#4789EB'); setComInsalubColor('#4789EB'); setSemInsalubColor('white'); setInsalub({ value: 1, label: 'Não recebe' }) }}
                        >
                          <Text color={semInsalubColor} size={12} bold style={{ letterSpacing: '1px' }}>NÃO</Text>
                        </Button>
                      </Grid>

                    </Grid>

                  </Grid>

                  <Styled.SelectGrid item xs={6} sm={6} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Grau <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={insalub}
                      onChange={(value) => {
                        setInsalub(value);
                      }}
                      options={insalubOp} />
                  </Styled.SelectGrid>


                  <Grid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Receberá periculosidade? <span style={{ color: '#4789EB' }}>*</span></Text>

                    <Grid container xs={12} sm={12} md={12} lg={12}>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Button
                          background={comPericBg}
                          style={{ padding: 5, width: '100%', height: 40, borderRadius: 8, border: '1px solid #4789EB' }}
                          height={40}
                          onClick={() => { setComPericBg('#4789EB'); setSemPericBg('transparent'); setComPericColor('white'); setSemPericColor('#4789EB'); setPeric(null) }}
                        >
                          <Text color={comPericColor} size={12} bold style={{ letterSpacing: '1px' }}>SIM</Text>
                        </Button>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: 10 }}>
                        <Button
                          background={semPericBg}
                          style={{ padding: 5, width: '100%', height: 40, borderRadius: 8, border: '1px solid #4789EB' }}
                          height={40}
                          onClick={() => { setComPericBg('transparent'); setSemPericBg('#4789EB'); setComPericColor('#4789EB'); setSemPericColor('white'); setPeric({ value: 1, label: 'Não recebe' }) }}
                        >
                          <Text color={semPericColor} size={12} bold style={{ letterSpacing: '1px' }}>NÃO</Text>
                        </Button>
                      </Grid>

                    </Grid>

                  </Grid>

                  <Styled.SelectGrid item xs={6} sm={6} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Grau <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={peric}
                      onChange={(value) => {
                        setPeric(value);
                      }}
                      options={pericOp} />
                  </Styled.SelectGrid>

                </Grid>

              </Grid>

              <Grid container xs={12} sm={12} md={12} lg={12} style={{ padding: 10, marginTop: 16, paddingBottom: 200, justifyContent: 'flex-end' }}>
                <Button
                  background={Theme.dark.primary}
                  style={{ padding: 5, width: '200px', height: 40 }}
                  height={40}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  <Text color={Theme.dark.primaryText} size={12} bold style={{ letterSpacing: '1px' }}>GERAR CONTRATO</Text>
                </Button>
              </Grid>

            </Styled.Form>
          )}
        </Formik>
      </Grid>



    </div>
  );
}

export default NewYoungStep2Screen;
