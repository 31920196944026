import React from 'react';
import { Grid, DialogContent, Dialog, DialogContentText, DialogActions, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import UsersIcon from 'assets/images/icons/users.png';
import AddUserIcon from 'assets/images/icons/add_user.png';
import DocumentIcon from 'assets/images/icons/document.png';
import Text from 'components/Text';
import Theme from 'style/Theme';

const MENU = [
	// {
	// 	icon: DashboardIcon,
	// 	title: 'Dashboard',
	// 	path: '/dashboard',
	// },
	{
		icon: UsersIcon,
		title: 'Lista de aprendizes',
		path: '/aprendizes',
	},
	{
		icon: AddUserIcon,
		title: 'Contratar aprendiz',
		path: '/cadastrar-aprendiz',
	},
	{
		icon: DocumentIcon,
		title: 'Relatórios',
		path: '/relatorios',
	},
	// {
	// 	icon: CalendarToday,
	// 	title: 'Controle de ponto',
	// 	path: '/registro-ponto',
	// },
	// {
	// 	icon: VerifiedUserOutlined,
	// 	title: 'Gestão de usuários',
	// 	path: '/avisos',
	// },
	// {
	// 	icon: Settings,
	// 	title: 'Configurações',
	// 	path: '/desempenho',
	// },
	// {
	// 	icon: Article,
	// 	title: 'Termos e condições',
	// 	path: '/termos',
	// },
	// {
	// 	icon: PrivacyTip,
	// 	title: 'Política de privacidade',
	// 	path: '/politica-protecao-dados',
	// },
	// {
	// 	icon: Logout,
	// 	title: 'Sair',
	// 	path: '',
	// }
]

const DrawerContent = ({ onLogout }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname;
	const [dialogLogout, setDialogLogout] = React.useState(false);

	const handleOpenDialogLogout = () => setDialogLogout(true);
	const handleCloseDialogLogout = () => setDialogLogout(false);

	const handleLogout = () => {
		handleOpenDialogLogout();
	};

	const handleClick = (item) => {
		if (item.title === 'Sair') {
			handleLogout()
		}
		navigate(item.path);
	};

	const logout = () => {
		handleCloseDialogLogout();

		localStorage.clear();
		navigate('/login');
		window.location.replace('/');
	};

	return (
		<Grid
			container
			direction="column"
			style={{ maxWidth: 350, width: '100%', height: '100%', backgroundColor: Theme.dark.secondary, padding: 24, overflowY: 'auto', display: 'flow-root' }}
		>
			<Dialog
				open={dialogLogout}
				keepMounted
				onClose={handleCloseDialogLogout}
				aria-describedby="logout-dialog"
			>
				<DialogContent style={{ backgroundColor: Theme.dark.secondary, borderTop: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<DialogContentText style={{ color: Theme.dark.primaryText }} id="logout-dialog-description">
						Você realmente deseja sair?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ backgroundColor: Theme.dark.secondary, borderBottom: `1px solid ${Theme.dark.border}`, borderLeft: `1px solid ${Theme.dark.border}`, borderRight: `1px solid ${Theme.dark.border}` }}>
					<Button onClick={handleCloseDialogLogout}>Cancelar</Button>
					<Button onClick={logout}>Sair</Button>
				</DialogActions>
			</Dialog>

			<Text color="#5e6a82" bold style={{ marginBottom: 10 }}>
				Menu
			</Text>

			{
				MENU.map((item, index) => {
					let actived = path === item.path;
					return (
						<div key={index} style={{ borderBottom: '1px solid rgba(71, 137, 235, 0.1)', padding: '20px 5px', paddingRight: 0 }}>
							<Grid
								container
								direction="row"
								alignItems="center"
								style={{ cursor: 'pointer' }}
								title={item.title}
								onClick={() => handleClick(item)}
							>
								<img alt='Menu icon' src={item.icon} style={{ width: 20, height: 20, marginLeft: 10, color: actived ? Theme.dark.primary : Theme.dark.primary }} />
								<Text
									color={actived ? Theme.dark.primary : '#F2F2F3'}
									size={15}
									bold={actived}
									style={{ marginLeft: 15, flex: 1 }}
								>
									{item.title}
								</Text>
							</Grid>
						</div>
					)
				})
			}
		</Grid>
	);
};

export default DrawerContent;
