import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { CircularProgress, Grid } from '@mui/material';

import Text from 'components/Text';
import moment from 'moment';

import Theme from 'style/Theme';

const ContractualData = ({ id }) => {
    const [loading, setLoading] = useState(true);

    const getContractData = useStoreActions(({ user }) => user.getContractData);
    const contractData = useStoreState(({ user }) => user.contractData);

    const init = async () => {
        setLoading(true);
        await getContractData(id);
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, [0]);

    if (loading) return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24}>Dados contratuais</Text>

            <Grid container alignItems="center" justifyContent="center" direction="column">
                <CircularProgress style={{ color: Theme.primary, marginTop: 25 }} />
                <Text bold size={16} color={Theme.primary} style={{ marginTop: 5 }}>Carregando...</Text>
            </Grid>
        </div>
    );

    return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24}>Dados contratuais</Text>

            <Grid container alignItems="center" style={{ marginTop: 25, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Data de nascimento</Text>
                    <Text bold>{moment(contractData?.data_nascimento).format('DD/MM/YYYY')|| '--/--/----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Gênero</Text>
                    <Text bold>{contractData?.sexo || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Estado civil</Text>
                    <Text bold>{contractData?.estado_civil || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Etnia</Text>
                    <Text bold>{contractData?.etnia || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CPF</Text>
                    <Text bold>{contractData?.cpf || '---.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Dispensado do exército?</Text>
                    <Text bold>{contractData?.reservista || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Reservista R.A</Text>
                    <Text bold>{contractData?.reservista_numero || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Data de emissão</Text>
                    <Text bold>{(contractData?.reservista_data_emissao)?moment(contractData?.reservista_data_emissao).format('DD/MM/YYYY'):'--/--/----'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>RG</Text>
                    <Text bold>{contractData?.rg_numero || '--.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>UF RG</Text>
                    <Text bold>{contractData?.uf_rg || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Órgão expedidor</Text>
                    <Text bold>{contractData?.orgao_exp_rg || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Data de emissão</Text>
                    <Text bold>{(contractData?.rg_data_emissao)?moment(contractData?.rg_data_emissao).format('DD/MM/YYYY'):'--/--/----'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nacionalidade</Text>
                    <Text bold>{contractData?.nacionalidade || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Estado naturalidade</Text>
                    <Text bold>{contractData?.estado_naturalidade || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Cidade naturalidade</Text>
                    <Text bold>{contractData?.cidade_naturalidade || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Modelo CTPS</Text>
                    <Text bold>{contractData?.modelo_ctps || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nº CTPS</Text>
                    <Text bold>{contractData?.ctps_numero || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Série CTPS</Text>
                    <Text bold>{contractData?.ctps_serie || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CTPS UF</Text>
                    <Text bold>{contractData?.uf_ctps || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Data de emissão CTPS</Text>
                    <Text bold>{(contractData?.ctps_data_emissao)?moment(contractData?.ctps_data_emissao).format('DD/MM/YYYY'):'--/--/----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>PIS/PASEP</Text>
                    <Text bold>{contractData?.pis_pasep || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Tipo de certidão</Text>
                    <Text bold>{contractData?.tipo_certidao || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Folha de registro</Text>
                    <Text bold>{contractData?.folha_de_registro || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Livro de registro</Text>
                    <Text bold>{contractData?.livro_de_registro || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Certidão de nascimento/casamento</Text>
                    <Text bold>{contractData?.certidao_nascimento_casamento || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Estado de registro</Text>
                    <Text bold>{contractData?.uf_registro || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Cidade de registro</Text>
                    <Text bold>{contractData?.cidade_registro || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Data de registro</Text>
                    <Text bold>{(contractData?.cas_res_data_registro)?moment(contractData?.cas_res_data_registro).format('DD/MM/YYYY'):'--/--/----'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Banco</Text>
                    <Text bold>{contractData?.banco || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Agência</Text>
                    <Text bold>{contractData?.agencia || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Operação</Text>
                    <Text bold>{contractData?.operacao || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Conta bancária ou termo Social Bank</Text>
                    <Text bold></Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CEP</Text>
                    <Text bold>{contractData?.cep || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Rua</Text>
                    <Text bold>{contractData?.logradouro || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Número</Text>
                    <Text bold>{contractData?.numero || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Complemento</Text>
                    <Text bold>{contractData?.complemento || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Bairro</Text>
                    <Text bold>{contractData?.bairro || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>UF</Text>
                    <Text bold>{contractData?.sigla || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Cidade</Text>
                    <Text bold>{contractData?.cidade || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Telefone 1</Text>
                    <Text bold>{contractData?.telefone_1 || '(--) -----.----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Telefone 2</Text>
                    <Text bold>{contractData?.telefone_2 || '(--) -----.----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Telefone 3</Text>
                    <Text bold>{contractData?.telefone_3 || '(--) -----.----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>E-mail</Text>
                    <Text bold>{contractData?.email || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Número da CNH</Text>
                    <Text bold>{contractData?.numero_cnh || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Categoria da CNH</Text>
                    <Text bold>{contractData?.categoria_cnh || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Validade da CNH</Text>
                    <Text bold>{(contractData?.validade_cnh)?moment(contractData?.validade_cnh).format('DD/MM/YYYY'):'--/--/----'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Estado de emissão da CNH</Text>
                    <Text bold>{contractData?.uf_cnh || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nº título de eleitor</Text>
                    <Text bold>{contractData?.titulo_eleitor || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Zona eleitoral</Text>
                    <Text bold>{contractData?.titulo_zona || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Seção eleitoral</Text>
                    <Text bold>{contractData?.titulo_secao || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Emissão do título eleitoral</Text>
                    <Text bold>{(contractData?.titulo_emissao)?moment(contractData?.titulo_emissao).format('DD/MM/YYYY'):'--/--/---'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Tamanho do uniforme</Text>
                    <Text bold>{contractData?.uniforme || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Como soube do programa?</Text>
                    <Text bold>{contractData?.como_soube || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Por que se interessou?</Text>
                    <Text bold>{contractData?.motivo_interesse || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Possui deficiência?</Text>
                    <Text bold>{contractData?.deficiencia || '-'}</Text>
                </Grid>
            </Grid>

            <Text bold size={18} color={Theme.primary} style={{ marginTop: 25 }}>Dados familiares</Text>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Renda familiar (incluindo sem registro)</Text>
                    <Text bold>{contractData?.renda_familiar || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Quantas pessoas moram na casa?</Text>
                    <Text bold>{contractData?.moram_na_casa || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Quantos filhos possui?</Text>
                    <Text bold>{contractData?.filhos || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nome do cônjuge </Text>
                    <Text bold>{contractData?.nome_conjuge || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CPF do cônjuge</Text>
                    <Text bold>{contractData?.cpf_conjuge || '---.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Gênero do cônjuge</Text>
                    <Text bold>{contractData?.sexo_conjuge || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Data de nascimento do cônjuge</Text>
                    <Text bold>{(contractData?.data_nascimento_conjuge)?moment(contractData?.data_nascimento_conjuge).format('DD/MM/YYYY'):'--/--/----'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nome do pai</Text>
                    <Text bold>{contractData?.pai_nome || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>RG do pai</Text>
                    <Text bold>{contractData?.pai_rg || '--.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CPF do pai</Text>
                    <Text bold>{contractData?.pai_cpf || '---.---.---.--'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nome da mãe</Text>
                    <Text bold>{contractData?.mae_nome || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>RG da mãe</Text>
                    <Text bold>{contractData?.mae_rg || '--.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CPF da mãe</Text>
                    <Text bold>{contractData?.mae_cpf || '---.---.---.--'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Responsável:</Text>
                    <Text bold>{contractData?.responsavel || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>Nome do responsável</Text>
                    <Text bold>{contractData?.responsavel_nome || '-'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>RG do responsável</Text>
                    <Text bold>{contractData?.responsavel_rg || '--.---.---.--'}</Text>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ marginTop: 5 }}>
                    <Text bold size={12} color='#93979F'>CPF do responsável</Text>
                    <Text bold>{contractData?.responsavel_cpf || '---.---.---.--'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 10, borderBottom: '1px solid #364563', paddingBottom: 15 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Text bold size={12} color='#93979F'>E-mail do responsável</Text>
                    <Text bold>{contractData?.responsavel_email || '-'}</Text>
                </Grid>
            </Grid>

            <Grid container alignItems="center" style={{ marginTop: 15 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Text bold size={12} color='#93979F'>Observações gerais</Text>
                    <Text bold>{contractData?.observacoes_gerais || '-'}</Text>
                </Grid>
            </Grid>

        </div>
    );
}

export default ContractualData;
