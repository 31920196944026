import React from 'react';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlined from '@mui/icons-material/LocalPhoneOutlined';
import Text from 'components/Text';
import * as Styled from './styles';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { IJB_URL, IMG_PLACEHOLDER } from 'api';

const WarningCard = (props) => {

  const warning = props.props;
  const young = props.props;
  const navigate = useNavigate();

  const sendEmail = (email) => {
    if (!email) return;
    window.open('mailto: ' + email, '_blank');
  }

  const sendMessage = (number) => {
    if (!number) return;
    navigator.clipboard.writeText(number);
  }

  const open = (id, step) => {
    if (step == 0) {
      navigate('/cadastrar-aprendiz', { state: { id: id } });
    }

    if (step == 1) {
      navigate('/cadastrar-aprendiz-dois', { state: { id: id } });
    }

    if (step == 2) {
      navigate('/cadastrar-aprendiz-tres', { state: { id: id } });
    }
  }

  return (
    <Styled.ItemContainer
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid md={1} item style={{ padding: 5, width: 95 }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: warning?.cor, borderRadius: 8, height: 48, width: 48 }} align="center">
          <Text size={18} bold style={{ width: '100%' }} align="center">
            {warning?.pontos}
          </Text>
        </div>
      </Grid>

      <Grid md={8} style={{ }} onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}>
        <Text size={14} style={{ color: '#F2F2F3' }}>{props?.props?.medida_disciplinar}</Text>
      </Grid>

      <Grid md={3} item>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: warning?.cor, borderRadius: 8, height: 48 }} align="center">
          <Text size={14} bold style={{ width: '100%' }} align="center">
            {warning?.gravidade}
          </Text>
        </div>
      </Grid>

    </Styled.ItemContainer >
  );
};

export default WarningCard;
