import React from 'react';
import Text from 'components/Text';
import * as Styled from './styles';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import { IJB_URL, IMG_PLACEHOLDER } from 'api';

const JourneyCard = (props) => {
  const young = props.props;
  const navigate = useNavigate();


  const open = (id, step) => {
    if (step == 0) {
      navigate('/cadastrar-aprendiz', { state: { id: id } });
    }

    if (step == 1) {
      navigate('/cadastrar-aprendiz-dois', { state: { id: id } });
    }

    if (step == 2) {
      navigate('/cadastrar-aprendiz-tres', { state: { id: id } });
    }
  }

  const red = { border: '1px solid #FC4F81', background: 'rgba(252, 79, 129, 0.2)' };
  const yellow = { border: '1px solid #E2B53F', background: 'rgba(226, 181, 63, 0.2)' };
  const general = {};

  const getStyle = () => {
    if ((young.status != 'presente' && young.status != 'NÃO JUSTIFICADO') || (young.status == 'presente' && !young.saida)) return yellow;
    if (young.status == 'presente') return general;
    if (young.status == 'NÃO JUSTIFICADO') return red;
  }

  const getIcon = () => {
    if ((young.status != 'presente' && young.status != 'NÃO JUSTIFICADO') || (young.status == 'presente' && !young.saida)) return <WarningAmberIcon fontSize='medium' style={{ color: '#E2B53F' }} />;
    if (young.status == 'presente') return <CheckIcon fontSize='medium' style={{ color: '#2FC499' }} />;
    if (young.status == 'NÃO JUSTIFICADO') return <CloseIcon fontSize='medium' style={{ color: '#FC4F81' }} />;
  }

  const getHour = (time) => {
    try {
      if (!time) return '--:--';
      let dt = new Date(time);
      let result = dt.getHours() + ':' + ((dt.getMinutes()<10)?'0':'') + dt.getMinutes();
      return result;
    } catch (error) {
      return '--:--';
    }
  }

  return (
    <>
      <Styled.ItemContainer
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={getStyle()}
      >
        <Grid container alignItems="center">

          <Grid
            item
            onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}
            container
            alignItems="center"
            md={5}
          >
            {getIcon()}
            <img
              src={young.foto ? IJB_URL + young.foto : IMG_PLACEHOLDER}
              width="40"
              height="40"
              style={{ borderRadius: 100, marginLeft: 5 }}
            />

            <Text style={{ fontSize: 14, marginLeft: 8, color: '#F2F2F3', fontWeight: 600, marginLeft: 5 }}>{young.nome.toUpperCase()}</Text>
          </Grid>

          <Grid item md={2} onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}>
            <Text style={{ fontSize: 14, marginLeft: 8, color: '#F2F2F3', fontWeight: 600 }}>{young.tipo_aula.toUpperCase()}</Text>
          </Grid>

          <Grid item md={1} onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}>
            <Text style={{ fontSize: 14, marginLeft: 8, color: '#F2F2F3', fontWeight: 600 }}>{moment(young.data).format('DD/MM/YYYY')}</Text>
          </Grid>

          <Grid item md={2} onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}>
            <Text style={{ fontSize: 14, marginLeft: 8, color: young.status == 'presente' ? '#CACBCE' : young.status == 'NÃO JUSTIFICADO' ? '#FC4F81' : '#E2B53F', fontWeight: 600 }}>
              {young.status.toUpperCase()}
            </Text>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            md={1}
            onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}
          >
            <LoginIcon fontSize='medium' style={{ color: '#2FC499' }} />
            <Text style={{ fontSize: 14, marginLeft: 8, color: '#F2F2F3', fontWeight: 600 }}>{getHour(young.entrada) || '--:--'}</Text>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            md={1}
            onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}
          >
            <LogoutIcon fontSize='medium' style={{ color: '#E2B53F' }} />
            <Text style={{ fontSize: 14, marginLeft: 8, color: '#F2F2F3', fontWeight: 600 }}>{getHour(young.saida) || '--:--'} </Text>
          </Grid>
        </Grid>
      </Styled.ItemContainer>
    </>
  );
};

export default JourneyCard;
