import React, { useRef, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStoreActions, useStoreState } from 'easy-peasy';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router';

import { ValidateCPF } from 'services/Validation';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Form/Input';
import Text from 'components/Text';

import LogoImage from 'assets/images/logo.png';

import Theme from 'style/Theme';
import * as Styled from './styles';

const LoginSchemaValidation = Yup.object().shape({
	cpf: Yup.string()
		.required('Campo obrigatório')
		.test('CPFvalidation', 'CPF inválido', (value) => ValidateCPF(value)),
	password: Yup.string()
		.min(6, 'Senha muito curta')
		.required('Campo obrigatório'),
});

const initialValues = {
	cpf: '',
	password: '',
};

const LoginScreen = () => {
	const passwordInputRef = useRef(null);
	let navigate = useNavigate();

	const loading = useStoreState(({ auth }) => auth.loading);
	const loginAction = useStoreActions(({ auth }) => auth.login);

	const checkAlreadyLogged = async () => {
		const token = await localStorage.getItem('@AUTH_TOKEN');
		if (token) navigate('/aprendizes');
	};

	useEffect(() => {
		checkAlreadyLogged();
	}, [0]);

	const handleSumbit = async ({ cpf = '', password = '' }) => {
		if (loading) return;

		let cleanedCPF = cpf;//cpf.replace(/\D+/g, '') || '';
		let cleanedPassword = password.replace(/\.|\-/g, '');
		const response = await loginAction({ cpf: cleanedCPF, password: cleanedPassword });
		if (response) navigate('/aprendizes');
	};

	const handleCPF = (value = '', setFieldValue) => {
		setFieldValue('cpf', value);
		let cpfCleaned = typeof (value) === 'string' ? value.replace(/\D+/g, '') : '';
		if (cpfCleaned.length === 11) passwordInputRef.current.focus();
	};

	return (
		<Grid container direction="row" style={{ flex: 1, backgroundColor: Theme.dark.background }}>
			<Grid item xs={12} sm={12} md={6} style={{ backgroundColor: Theme.dark.background, minHeight: '100vh' }}>
				<Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
					<Grid item style={{ width: '100%', maxWidth: 480, padding: 20 }}>
						<Grid marginTop={-10} container direction="column" alignItems="center" justifyContent="center">
							<img src={LogoImage} style={{ marginBottom: 25 }} />
						</Grid>

						<Formik
							initialValues={initialValues}
							validationSchema={LoginSchemaValidation}
							onSubmit={handleSumbit}
						>
							{({ errors, handleChange, handleSubmit, setFieldValue, touched, values }) => (
								<Styled.Form onSubmit={handleSumbit}>
									<Text bold color={Theme.dark.secondaryText} size={16}>CPF</Text>
									<InputMask
										mask="999.999.999-99"
										value={values.cpf}
										onChange={(value) => handleCPF(value, setFieldValue)}
										placeholder="Digite seu CPF"
										onSubmit={() => passwordInputRef.current.focus()}
									>
										{(inputProps) => <Input {...inputProps} type="tel" required style={{ marginTop: 5, backgroundColor: 'transparent', border: '1px solid #364563', borderRadius: 8, color: '#fff' }} />}
									</InputMask>
									{errors.cpf && touched.cpf ? (
										<ErrorText message={errors.cpf} />
									) : null}

									<Text bold color={Theme.dark.secondaryText} size={16} style={{ marginTop: 25 }}>Senha</Text>
									<Input
										type="password"
										forwardedRef={passwordInputRef}
										value={values.password}
										onChange={handleChange('password')}
										onSubmit={handleSubmit}
										isPassword
										placeholder="Digite sua senha"
										required
										style={{ backgroundColor: 'transparent', color: '#fff' }}
									/>
									{errors.password && touched.password ? (
										<ErrorText message={errors.password} />
									) : null}
									
									<Button
										background={Theme.dark.primary}
										style={{ marginTop: 20, padding: 15, width: '100%', height: 55 }}
										height={60}
										disabled={loading}
										onClick={handleSubmit}
										type="submit"
									>
										{loading ? <CircularProgress size={16} style={{ color: '#fff' }} /> : <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px' }}>ACESSAR</Text>}
									</Button>

									<div style={{ marginTop: 20, marginBottom: 20, flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<Button onClick={() => navigate('/recuperar-senha')} sx={{ color: Theme.dark.grey, height: '100%' }}>
											<Text style={{ color: Theme.dark.primaryText, fontSize: 15, textAlign: 'left' }}>Esqueci minha senha</Text>
										</Button>
									</div>

								</Styled.Form>
							)}
						</Formik>
					</Grid>
				</Grid>
			</Grid>
			<Styled.SecondContainer item md={6}>
				<Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
					<Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
						Transformando <spam style={{ color: Theme.dark.green }}>vidas.</spam>
					</Text>
					<Text style={{ fontSize: '36px', color: Theme.dark.primaryText }} align={'center'}>
						Transformando o <spam style={{ color: Theme.dark.green }}>Brasil.</spam>
					</Text>
				</Grid>
			</Styled.SecondContainer>
		</Grid>
	)
};

export default LoginScreen;
