import { Grid } from '@mui/material';
import Styled from 'styled-components';

export const ItemContainer = Styled(Grid)`
  background-color: #1E262D;
  border-radius: 8px;
  border: 1px solid #364563;
  padding: 10px 20px;
  margin-bottom: 8px;
  margin-top: 14px;
  border-left: 4px solid #2FC499;
`;
