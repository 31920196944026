import styled from 'styled-components';
import { Grid } from '@mui/material';

import BackgroundImage from 'assets/images/background-login.jpg';

export const Container = styled.div`
  align-items: center;
  background-color: #fff;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const SecondContainer = styled(Grid)`
  background-image: url(${BackgroundImage});
  background-size: cover;
  flex: 1;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  @media (max-width: 899px) {
    display: none;
  }
`;

export const Form = styled.form`
  align-items: center;
  flex: 1;
  justify-content: center;
  width: 100%;
`;


