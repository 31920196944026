import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { CircularProgress, Grid } from '@mui/material';
import Text from 'components/Text';
import Theme from 'style/Theme';
import { IJB_URL } from 'api';

const LearningContract = ({ id }) => {
    const [loading, setLoading] = useState(true);

    const getRegisterData = useStoreActions(({ user }) => user.getRegisterData);
    const [current, registerDataAditivos = []] = useStoreState(({ user }) => [user.current, user.registerDataAditivos]);

    const init = async () => {
        setLoading(true);
        await getRegisterData(id);
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, [0]);


    if (loading) return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24}>Contrato de aprendizagem</Text>

            <Grid container alignItems="center" justifyContent="center" direction="column">
                <CircularProgress style={{ color: Theme.primary, marginTop: 25 }} />
                <Text bold size={16} color={Theme.primary} style={{ marginTop: 5 }}>Carregando...</Text>
            </Grid>
        </div>
    );

    return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24} style={{ marginBottom: 24 }}>Contrato de aprendizagem</Text>

            {registerDataAditivos.map((aditivo, index) => {

                if (index == 0)
                    return <a download="contrato.pdf" href={IJB_URL + current} style={{ textDecoration: 'none' }}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10, cursor: 'pointer' }}
                        >
                            <Text>Contrato de aprendizagem profissional</Text>
                            <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                                ATUAL
                            </Text>
                        </Grid>
                    </a>;
                else return <Grid
                    key={index}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ borderRadius: 8, padding: 20, backgroundColor: '#1E262D', border: '1px solid #364563', marginTop: 10 }}
                >
                    <Text>
                        <b>Nº {aditivo?.id_jovem_contrato} - </b> {aditivo?.motivo}
                        <span style={{ marginLeft: 5, fontSize: 12, color: '#bfbfbf' }}>- {aditivo?.data_criacao}</span>
                    </Text>
                    <Text style={{ backgroundColor: '#144672', borderRadius: 8, padding: '8px 15px' }}>
                        {index == 0 ? 'ATUAL' : 'ADITIVO'}
                    </Text>
                </Grid>

            })}


        </div>
    );
}

export default LearningContract;
