import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material'
import Text from 'components/Text';

import DocumentIcon from 'assets/images/icons/document.png';
import Theme from 'style/Theme';

const DATA = [
  {
    title: 'Relatório de advertências',
    icon: DocumentIcon,
    route: '/relatorios/advertencia'
  },
  {
    title: 'Relatório de exportação geral',
    icon: DocumentIcon,
    route: '/relatorios/geral'
  },
  {
    title: 'Relatório de desempenho geral',
    icon: DocumentIcon,
    route: '/relatorios/desempenho'
  },
  {
    title: 'Relatório de jornada',
    icon: DocumentIcon,
    route: '/relatorios/jornada'
  }
];

const ReportsScreen = () => {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1, maxWidth: 550 }}>
      <Text color="#F2F2F3" bold size={24}>Relatórios</Text>

      <Grid style={{ marginTop: 10 }}>
        {DATA.map((item, index) => (
          <Button
            key={index}
            style={{ background: '#1E262D', padding: 24, height: 72, textAlign: 'left', width: '100%', borderRadius: 8, border: '1px solid #364563', marginTop: 15 }}
            height={40}
            onClick={() => { navigate(item.route) }}
          >
            <img alt='Ícone do relatório' src={item.icon} style={{ marginRight: 16 }} />
            <Text size={16} bold style={{ marginLeft: 24, letterSpacing: '1px', width: '100%' }}>{item.title}</Text>
            <ChevronRight fontSize='large' />
          </Button>
        ))}

      </Grid>
    </div>
  );
}

export default ReportsScreen;
