import styled from 'styled-components';
import { Grid } from '@mui/material';

export const MenuDrawerMD = styled(Grid)`
  @media (max-width: 959px) {
    display: none;
  }
`;

export const MenuDrawerSM = styled(Grid)`
  @media (max-width: 959px) {
    display: block;
  }

  @media (min-width: 960px) {
    display: none !important;
  }
`;

export const DrawerContainer = styled(Grid)`
  flex: 1;
  height: 100%;
  padding-top: 64px;
  
  @media (max-width: 959px) {
    padding-top: 40px;
  }
`;
