import React from 'react';
import PropTypes from 'prop-types';

import { Drawer, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';

import Text from 'components/Text';

const FilterAdvanced = ({ children, onClose, show = false }) => (
    <Drawer
        anchor="right"
        open={show}
        onClose={onClose}
    >
        <Grid container direction="column" style={{ backgroundColor: '#1E2C38', padding: 25, flex: 1, width: 305 }}>
            <Grid container direction="row" justifyContent="space-between">
                <Text>Filtro</Text>
                <Close style={{ color: '#fff', cursor: 'pointer' }} onClick={onClose} />
            </Grid>

            {children}

            <Grid style={{ borderTop: '2px solid rgba(255, 255, 255, 0.1)', height: 1, marginTop: 20, marginBottom: 20 }} />

            <Text size={12} color="#93979F" style={{ marginTop: 20 }}>
                *Os filtros se aplicam automaticamente conforme você selecionar as opções!
            </Text>
        </Grid>
    </Drawer>
);

FilterAdvanced.defaultProps = {
    show: false
};

FilterAdvanced.prototype = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool
};

export default FilterAdvanced;
