import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PrivacyTip } from '@mui/icons-material';
import Text from 'components/Text';
import Theme from 'style/Theme';
import * as Styled from './styles';
import PageHeader from 'components/PageHeader';
import DrawerContent from 'components/Drawer/components/DrawerContent';

const GeneralDataProtectionPolicy = () => {
  const [hasToken, setToken] = useState(false);

  const getToken = async () => {
    setToken(!!(await localStorage.getItem('@AUTH_TOKEN')));
  };

  const navigate = useNavigate();

  useEffect(() => {
    getToken();
  }, [0]);

  return (
    <div style={{ backgroundColor: Theme.dark.background, flex: 1, display: 'flex', flexDirection: 'row' }}>
      <div style={{ minHeight: '83vh' }}>
        <DrawerContent />
      </div>
      <Styled.Content
        container
        direction="column"
        justifyContent="center"
      >
        <div
          style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}
        >
          {!hasToken && <ArrowBackIcon style={{ marginRight: 10, color: Theme.dark.primaryText, cursor: 'pointer' }} onClick={() => navigate(-1)} />}
          <PageHeader title='Política de Privacidade e Proteção de Dados' icon={<PrivacyTip fontSize="large" />} />
        </div>

        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 20, width: '100%' }}>
        Nós do Instituto João Bittar levamos a privacidade e a proteção dos seus dados a sério. Nesta Política de Privacidade e Proteção de Dados, em atenção ao Marco Civil da Internet (Lei nº 12.965 de 23 de abril de 2014), Lei Geral de Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018) e ao Código de Defesa do Consumidor (Lei nº 8.078, de 11 de setembro de 1990), explicamos de forma clara e detalhada como nós utilizamos, armazenamos, compartilhamos e protegemos os dados pessoais coletados a partir do uso dos nossos serviços e soluções e/ou da visita aos nossos sites, aplicativos, plataformas e outros. Aqui também informamos como os titulares dos dados podem exercer seus direitos sobre eles, conforme previsto na legislação aplicável.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 5, width: '100%' }}>
        Nós oferecemos uma variedade de produtos e serviços. Deste Desse modo, as referências aos meios/produtos de interação incluem sites, aplicativos, softwares, pontos de atendimento   e plataformas pelas quais nos relacionamos com os nossos parceiros e usuários, podendo haver uma Política de Privacidade específica aplicável para alguns dos nossos produtos e serviços, a qual poderá ser consultada na página web deste do produto ou serviço.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 5, width: '100%' }}>
        Os dados pessoais que coletamos dependem do contexto das suas interações conosco. Assim, para facilitar a sua compreensão, explicamos neste Termo de Política de Privacidade e Proteção de Dados como tratamos os dados dos nossos alunos, parceiros e demais usuários dos nossos sites e plataformas.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ marginTop: 20, width: '100%' }}>
        1. Sobre o aceite da nossa Política de Privacidade e Proteção de Dados
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 5, width: '100%' }}>
        O aceite aos termos desta Política ocorrerá quando você acessar ou utilizar o site, aplicativo, software, plataforma ou algum dos nossos produtos ou serviços. Ao acessar ou utilizar esses serviços, você deverá declarar que está ciente e de acordo com os termos e condições previstos neste documento. Se você não concordar com algum dos termos e condições aqui previstos, você não deverá utilizar o nosso produto/serviço e poderá entrar em contato conosco pelo e-mail privacidade@institutojoaobittar.edu.br, informando o meio que coletou informações a seu respeito, a fim de que possamos proceder com a exclusão dos dados.
        </Text>
       
        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        2. Quem somos nós
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Somos uma instituição formadora de aprendizes, fundada em 1983, atuante na área da Educação e Assistência Social que oferece um portfólio completo de serviços na área da  educação e da formação profissional também pela internet, os quais incluem, hospedagem de sites, plataformas, soluções de computação em nuvem, estante virtual, ferramentas de e-mail marketing, intermediador de pagamento, entre outros.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Integram nossos projetos a Plataforma Uniessa, Uniessa Corporativa, Uniessa Enem, Plataforma Aprendiz Bittar e outros, podendo tais serviços ocorrer mediante parceiras coligadas ou controladas, sendo o Instituto João Bittar o controlador responsável pelos seus dados pessoais obtidos mediante a interação atinentes a esses projetos.
        </Text>
        
        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        3. Definições importantes
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>Lei Geral de Proteção de Dados (LGPD): </spam>   É a Lei Federal nº 13.709 publicada no dia 14 de agosto de 2018 que regula as atividades de Tratamento de Dados Pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado, com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>Agentes de tratamento: </spam>   São aqueles a quem a lei atribui a responsabilidade pelo tratamento dos dados pessoais e estão separados em duas categorias: o Controlador e o Operador. O Controlador é a pessoa ou a empresa responsável pelas decisões referentes ao tratamento de dados pessoais. O Operador, por sua vez, é a pessoa ou a empresa que realiza o tratamento de dados Pessoais em nome do Controlador, seguindo as suas instruções.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>Dados pessoais: </spam>   Informações relacionadas a pessoa natural identificada ou identificável. Também constituem dados pessoais o conjunto de informações distintas que podem levar à identificação de uma determinada pessoa.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>Dados pessoais sensíveis: </spam>   São dados pessoais sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dados referentes à saúde ou à vida sexual, dados genéticos ou biométricos, que ostentam essa condição quando vinculados a uma pessoa natural.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>Tratamento: </spam>   Qualquer operação realizada com os dados pessoais, de forma automatizada ou não. É a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, armazenamento, arquivamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>Autorização de coleta de dados e informações: </spam>   Ao interagir com nossos sites, plataformas, redes sociais, aplicativos, inscrever-se em nossos cursos, conferências e programas, você deverá aceitar e autorizar a coleta de informações clicando no botão <spam style={{ fontWeight: 'bold' }}>Li e concordo com os termos</spam>, aquiescendo assim ao tratamento, manipulação e arquivamento dos seus dados nos termos da presente política de privacidade.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>Cookies: </spam>   Um cookie é um trecho de informação armazenado localmente no seu computador ou dispositivo. Ele contém informações acerca das atividades deste na internet.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        4. Dos dados pessoais que coletamos
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        De modo geral, quando um usuário ou sua empresa contrata, utiliza ou interage com os nossos serviços, nós coletamos os seus dados pessoais necessários à consecução do objeto da contratação e/ou para o estabelecimento e manutenção do vínculo comercial, podendo tais dados serem divididos em três categorias:
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) Dados que você nos fornece: </spam>   Quando você contrata, utiliza ou interage com os produtos ou serviços ou se comunica conosco de alguma forma, pode nos fornecer alguns dados, que  incluem, mas não se limitam aos: <spam style={{ fontWeight: 'bold' }}>(i)</spam> Dados de informação, como nome, idade, etnia, filiação, estado civil, impressão digital, profissão, cargo, empresa, localização, número de telefone e endereço; preferências de marketing, credenciais de conta, como o seu endereço de e-mail, usuário e senha; informações de pagamento, como dados de cartão de crédito, dados bancários e endereço para fatura, cópia de documentos pessoais e outros; <spam style={{ fontWeight: 'bold' }}>(ii)</spam> Dados relacionados a suporte técnico e solução de problemas, como dados de abertura de chamado, tipo de produto ou serviço relacionado ao seu pedido de ajuda, dados de contato ou autenticação e o conteúdo das suas comunicações conosco, incluindo mensagens escritas, gravações telefônicas etc.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) Dados técnicos coletados:  </spam>   Quando você utiliza os nossos serviços, nós podemos coletar e armazenar dados de forma automática. Esses dados incluem, mas não se limitam aos: <spam style={{ fontWeight: 'bold' }}>(i)</spam> Dados de dispositivos que você utiliza para acessar nosso website, plataforma e serviços, como o seu endereço de IP, sistema operacional, informação de geolocalização e identificador do dispositivo; <spam style={{ fontWeight: 'bold' }}>(ii)</spam> Dados de registros, como o endereço de IP e suas atividades nos serviços (como, por exemplo, a data e a hora associadas ao seu uso, as páginas e arquivos visualizados, pesquisas e outras ações que você realiza); <spam style={{ fontWeight: 'bold' }}>(iii)</spam> Dados de navegação, ou seja, dados sobre como você utiliza e interage com os nossos produtos e serviços, o que pode incluir as datas em que você utilizou os serviços e as suas atividades de navegação. Nós também coletamos informações sobre a performance dos serviços, incluindo métricas relacionadas ao envio de e-mails e outro tipo de comunicação que você envia por meio dos nossos produtos ou serviços.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c)  Dados coletados de outras fontes: </spam>   Nós podemos coletar dados de outras fontes, como, por exemplo, bases de dados públicas, dados de perfis públicos em redes sociais e entidades de proteção ao crédito. Quaisquer informações que você compartilha publicamente em uma rede social de terceiros ou informações que fazem parte de seu perfil em uma rede social de terceiros (como o Facebook ou Google, por exemplo) poderão ser utilizados por nós a fim de garantir-lhe uma melhor experiência comercial. Exemplos incluem suas informações básicas de conta (dados cadastrais, cidade atual, foto de perfil, ID de usuário, lista de amigos etc.) e quaisquer outras informações ou atividades adicionais que você permita que a rede social de terceiros compartilhe publicamente. Nós coletamos suas informações de perfil de redes sociais de terceiros (ou partes delas) sempre que você baixa um item ou interage com um aplicativo da web de nossa propriedade em uma rede social de terceiros, sempre que você utiliza um recurso de rede social integrado a um de nossos sites ou páginas (como o Facebook Connect) ou sempre que você interage conosco por meio de uma rede social de terceiros. Para saber mais sobre como suas informações de uma rede social de terceiros são obtidas por nós ou para optar por não compartilhar tais informações, consulte a política de privacidade da rede social de terceiros específica.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        5. Da finalidade e da forma como utilizamos os seus dados pessoais
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Coletamos e recebemos dados estritamente necessários para garantir a qualidade da interação e da parceria existente ou a ser estabelecida com os seus interlocutores, visando a sempre proporcionar segurança e uma experiência agradável, além da excelência na prestação de serviços. Assim, os dados coletados/recebidos são utilizados especificamente para as finalidades abaixo relacionadas:
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) </spam>   Cumprir as nossas obrigações contratuais com você ou com a sua empresa, bem como exigir e fazer valer o cumprimento de suas obrigações pessoais conosco. Isso inclui manipular, tratar e manter em nossos arquivos os seus dados pessoais, inclusive os de natureza sensível, pelo período contratado e prescricionais das obrigações contidas no contrato, bem como notificar, criar e gerenciar as contas que você possa ter conosco, verificar sua identidade, cobrar os valores devidos por você, fornecer, personalizar e aprimorar nossos serviços, bem como oferecer-lhe outros serviços, além dos contratados;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) </spam>   Promover a publicidade e a comunicação institucional dos nossos produtos e serviços, bem como fornecer informações que entendermos adequadas ao seu perfil e/ou relação que você mantém conosco;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) </spam>   Notificá-lo sobre eventuais alterações em nossos produtos e serviços, política institucional e outras informações que entendermos relevantes para você;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>d) </spam>   Realizar operações internas, incluindo suporte, solução de problemas, análise de dados, testes, pesquisas e estatística;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>e) </spam>   Reforçar nossos procedimentos de segurança e proteção, visando à prestação de um serviço mais seguro e eficaz;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>f) </spam>   Promover pesquisas e avaliações afetas ou não à eficácia da publicidade que veiculamos, visando a fornecer publicidades relevantes para você;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>g) </spam>   Gerenciar riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades potencialmente ilegais ou proibidas, além de violações de políticas, contratos ou termos de uso aplicáveis;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>h) </spam>   Melhorar e desenvolver nossos produtos, personalizando-os para atender às suas necessidades e fazer recomendações necessárias;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>i) </spam>   Exercer, exigir e defender direitos em processos judiciais, administrativos ou arbitrais, podendo para essa finalidade compartilhar seus dados com terceiros cujo acesso seja necessário para o exercício;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>j) </spam>   Cumprir com obrigações legais ou regulatórias, ou conforme exigido em um processo judicial, por qualquer órgão de aplicação da lei ou do governo com competência, ou que alegue ter competência, sobre nossas atividades;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>k) </spam>   Compartilhar com parceiros e terceiros que possam promover a oferta de produtos, serviços e benefícios adequados ao seu perfil, relacionados aos produtos e serviços que oferecemos;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>l) </spam>   Realizar comunicações com você, por qualquer meio, sobre nossos setores de atuação, sobre assuntos relacionados à nossa prestação de serviços ou aos nossos produtos, sempre que necessário;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>m) </spam>   Tomar medidas relacionadas à saúde e segurança dos usuários de nossos serviços;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>n) </spam>   Avaliar, registrar e arquivar seu desempenho acadêmico e/ou sua performance durante a utilização de nossos produtos ou serviços, para fins legais, regulatórios e/ou para nosso controle, processamento e avaliação interna;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>o) </spam>   Desenvolver novos produtos ou serviços;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>p) </spam>   Realizar pesquisas, avaliações e projeções de seu nível de satisfação, de seu comportamento ou de comportamentos do mercado;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>q) </spam>   Produzir relatórios, científicos ou não, relacionados ao seu desempenho, ao desempenho de nossos produtos e serviços e/ou ao desempenho de determinado grupo ou perfil que você integre.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Também utilizamos os dados para melhorar a nossa gestão, incluindo a análise de nosso desempenho, o desenvolvimento de nossa força de trabalho e a realização de pesquisas e desenvolvimento de produtos e serviços, e, ainda, para o cumprimento de nossas obrigações legais.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Ao realizar essas ações, combinamos dados que coletamos de diferentes contextos (por exemplo, a partir do uso de duas bases de dados, como Plataforma Uniessa e Uniessa Corporativa), para lhe proporcionar uma experiência melhor, além de outras finalidades legítimas.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Nosso processamento de dados pessoais para essas finalidades inclui métodos automáticos e manuais (humanos) de processamento, sempre de acordo com as leis vigentes. Nossos métodos automatizados são muitas vezes relacionados à operabilidade, à publicidade e aos serviços oferecidos. Já os nossos métodos manuais estão relacionados, em sua maioria, ao atendimento e ao suporte de produtos e serviços.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Além de tratarmos os dados de acordo com as regras previstas nesta Política, podemos utilizar também seus dados anônimos, ou seja, aqueles que não o identificam pessoalmente.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Asseguramos que, caso os seus dados sejam utilizados para alguma finalidade não prevista nesta Política, tal uso não poderá desrespeitar os direitos à proteção dos dados e à privacidade, nem a legítima expectativa dos usuários.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Informamos ainda que sempre buscamos tratar o mínimo possível os seus dados pessoais sensíveis. Para algumas pesquisas de mercado, atendimento personalizado, execução de contratos e/ou em razão das exigências legais de nossa atividade, precisamos coletar alguns dados sensíveis (como etnia, dados biométricos e outros). Caso seja necessário tratar seus dados pessoais sensíveis para outros fins que não os legais, a finalidade pretendida será informada, e nos apoiaremos em seu prévio e expresso consentimento, quando isso for uma exigência legal.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Nós buscamos coletar dados de menores de idade apenas em situações de necessidade e com o consentimento expresso de um dos pais ou responsáveis. Caso você permita a coleta dos dados pessoais do seu filho ou da criança por quem é responsável, será informado de forma clara a respeito de quais dados serão coletados e qual a finalidade da coleta em cada caso específico.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        6. Como compartilhamos os seus dados pessoais
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        O aceite de nossa Política de Privacidade e Proteção de Dados inclui a autorização expressa e inequívoca do compartilhamento dos dados recebidos e/ou coletados para terceiros cujo acesso seja necessário para que as partes exerçam regularmente os direitos e possam dar cumprimento aos termos dos contratos eventualmente firmados conosco, bem como para a efetivação das finalidades autorizadas no item 5, tais como, mas não se limitando a: empresas de gestão de software, validadora digital, bancos, oficiais cartorários, órgãos governamentais e judiciários, empresas de cobrança, marketing, transporte, escritórios de advocacia e contabilidade e outras que possuam parceria conosco e possam ofertar ou viabilizar produtos, serviços e benefícios relacionados aos produtos e serviços oferecidos por nós, e ainda órgãos que eventualmente precisem das informações para o cumprimento e a efetividade das obrigações contratuais e finalidades aqui autorizadas.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Referido compartilhamento ocorre com base nos seguintes critérios e para as finalidades descritas abaixo:
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) Nossos parceiros e clientes:  </spam>   As empresas parceiras e clientes de nossa organização podem compartilhar entre si os dados pessoais coletados a partir do aceite da presente política de dados, a fim de operar, executar, aprimorar, entender, personalizar, dar suporte, anunciar produtos e serviços relacionados ou não às nossas atividades, para tomar medidas relacionadas à saúde e segurança de qualquer pessoa que se relacione conosco, bem como para prevenir fraudes;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) Prestadores de serviço terceirizados e fornecedores:  </spam>   Trabalhamos com prestadores de serviço terceirizados para nos ajudar a operar, executar, aprimorar, entender, personalizar, dar suporte e anunciar nossos produtos e serviços. Quando compartilhamos seus dados com prestadores de serviços terceirizados, exigimos que eles os utilizem de acordo com nossas instruções e termos ou mediante seu consentimento expresso, quando aplicável;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) Entidades de proteção ao crédito:  </spam>   Podemos compartilhar os seus dados cadastrais com entidades dedicadas a reduzir o risco de crédito e proteger empresas e indivíduos contra fraudes, a fim de validar as informações fornecidas por você;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>d) Órgãos reguladores, autoridades judiciais ou administrativas:  </spam>   Podemos compartilhar as suas informações pessoais para prestar às autoridades competentes todas as informações solicitadas com relação ao titular e operações por ele executadas em qualquer contato conosco. Ademais, podemos compartilhar os seus dados pessoais com autoridades públicas ou entidades privadas para combate à fraude e abuso no uso dos serviços, para investigação de suspeitas de violação da lei ou para combater qualquer outra suspeita de descumprimento das nossas políticas e contratos.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>e) Transferência de ativos/alteração de mantença:  </spam>   Havendo transferência, fusão, reorganização, cessão, venda de parte ou de toda atividade mantida e/ou operada por nós a um terceiro, a sua informação pessoal poderá ser transferida ao comprador/cessionário, partner, independentemente de nova autorização para garantir a continuidade dos serviços;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>f) Com a sua autorização:  </spam>   Em outros casos não previstos acima, havendo o objetivo de compartilhamento dos seus dados pessoais e informações, enviaremos a você uma notificação solicitando o seu consentimento para a finalidade determinada.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Comprometemo-nos a exigir dos parceiros e terceiros cujos dados em análise sejam franqueados, por força do consentimento ofertado pela adesão à presente Política de Privacidade e Proteção de Dados, a mesma garantia de privacidade, confidencialidade e segurança assegurada neste instrumento e a declaração do cumprimento das políticas de proteção de dados nos termos da lei, mas não nos responsabilizamos em caso de eventual vazamento de informações.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        7. Cookies e tecnologias de rastreamento
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Nós e nossos parceiros podemos utilizar diversas tecnologias para coletar e armazenar, automaticamente, dados sobre o uso dos nossos serviços. Essas tecnologias incluem cookies, pixels, web beacons e SDKs. As informações coletadas por meio dessas tecnologias são utilizadas para otimizar e personalizar a sua experiência de navegação, direcionar campanhas publicitárias, desenvolver e aplicar funcionalidades de segurança e procedimentos de prevenção à fraude, dentre outras possíveis utilizações.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Assim, poderemos fazer o uso de cookies da seguinte forma e para as seguintes finalidades:
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) Cookies de desempenho:  </spam>   Coletam informações anônimas sobre a maneira como você utiliza o nosso site e nossa plataforma, no intuito de otimizá-los. As informações coletadas por esses cookies nunca contêm detalhes pessoais a partir dos quais seja possível identificá-lo;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) Cookies funcionais:  </spam>   São utilizados em algumas de nossas funcionalidades, buscando oferecer uma melhor experiência durante a utilização da plataforma;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) Cookies publicitários:  </spam>   Coletam informações sobre os hábitos de navegação, buscando tornar a publicidade mais relevante para você.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        O acesso aos cookies termina assim que você fecha o navegador. É dada a você a possibilidade de aceitá-los ou recusá-los.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Para que os cookies sejam utilizados, é necessária sua aceitação antes do início da sessão. Você poderá optar por recusar o uso de cookies, o que não comprometerá a maior parte das funcionalidades do nosso site/plataforma. Contudo, alguns serviços não estarão otimizados, o que pode vir a prejudicar seu acesso a eles. 
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        8. Da possibilidade de transferências de dados pessoais para fora do Brasil
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Com a adesão à presente Política de Privacidade de Proteção de Dados, você está ciente e autoriza que possamos transferir parte ou todos os seus dados pessoais, de natureza sensível ou não, à prestadores de serviços localizados no exterior, incluindo prestadores de serviços em nuvem.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Quando seus dados pessoais forem transferidos para fora do Brasil, adotaremos medidas apropriadas para garantir a proteção adequada de seus dados pessoais em conformidade com os requisitos da legislação aplicável de proteção de dados, incluindo por meio da celebração de contratos apropriados de transferência de dados com terceiros de seus dados pessoais quando exigidos.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        9. Interações com sites de terceiros
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Podemos disponibilizar links para outros sites na Internet. NÃO NOS RESPONSABILIZAMOS POR ESTES SITES E CONTEÚDOS E, AINDA, NÃO COMPARTILHAMOS, SUBSCREVEMOS, MONITORAMOS, VALIDAMOS OU ACEITAMOS A FORMA COMO ESSES SITES OU FERRAMENTAS DE ARMAZENAMENTO DE CONTEÚDO COLETAM, PROCESSAM E TRANSFEREM SUAS INFORMAÇÕES PESSOAIS E PRIVADAS. Recomendamos que você consulte as respectivas políticas de privacidade de tais sites para se informar adequadamente a respeito do uso de suas informações pessoais por outros sites ou outras ferramentas. Podemos registrar o seu acesso aos sites de terceiros, com o intuito de medir a relevância desses sites aos nossos usuários.
        </Text>
        
        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        10. Segurança
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Os seus dados pessoais tratados por nós são protegidos por medidas físicas, técnicas e organizacionais de segurança para reduzir riscos de perda, mau uso e acesso não autorizado, divulgação e alteração, tais como firewalls e criptografia de dados, controles de acesso físico a data centers, além de controles de autorização de acesso à informação.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Informamos, entretanto, que a nossa política de proteção não se aplica às informações que você tenha escolhido compartilhar em áreas públicas, como redes sociais de terceiros.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Pessoas que podem acessar seus dados pessoais: seus dados pessoais serão processados por nossos colaboradores ou agentes autorizados, desde que estes precisem ter acesso a tais informações, dependendo dos propósitos específicos para os quais seus dados pessoais tenham sido coletados (ex.: nossos colaboradores responsáveis por assuntos relacionados ao atendimento ao consumidor terão acesso aos seus registros de consumo).
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Medidas tomadas em ambientes operacionais: armazenamos seus dados pessoais em ambientes operacionais que usam medidas de segurança razoáveis para prevenir acesso não autorizado. Seguimos protocolos razoáveis para proteção dos dados pessoais. A transmissão de informação pela internet, infelizmente, não é completamente segura e, apesar de fazermos nosso melhor para proteger seus dados, não podemos garantir a segurança dos dados durante transmissão por nossos sites/aplicativos.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Da sua colaboração para proteção dos seus dados: o seu papel é fundamental para manter seus dados pessoais seguros. Quando criar uma conta online, por favor, assegure-se de escolher uma senha que seja difícil para que outras pessoas não consigam adivinhar. Você é responsável por manter esta senha confidencial por qualquer uso de sua conta. Se você usar um computador compartilhado ou público, nunca escolha a opção de lembrar seu ID de login, endereço de e-mail ou senha e certifique-se que você saiu da sua conta (“log out”) sempre que sair do computador. Você também deve usar quaisquer configurações de privacidade ou controles que nós forneçamos em nosso site ou aplicativo.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Nós adotamos políticas e procedimentos rigorosos que determinam como os dados pessoais devem ser tratados em nossa organização. Tais normas têm como objetivo garantir o tratamento adequado e lícito de seus dados pessoais. Nossas medidas de segurança são continuamente monitoradas e revisadas de acordo com os avanços tecnológicos e recursos organizacionais mais atuais.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Na ocorrência de qualquer violação à segurança própria ou a empresas de hospedagem, incluindo invasões, vazamentos de dados ou qualquer outro incidente de segurança da informação que cheguem ao nosso conhecimento, iremos notificar as autoridades nacionais e você, se afetado, acerca dessa violação e iremos fornecer o máximo de detalhes referente à natureza, extensão da violação e os dados comprometidos, em prazo razoável, de acordo com o disposto nos artigos 48, § 1º, da LGPD.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        11. Direitos do titular dos dados pessoais
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Para o exercício dos direitos atinentes aos seus dados fornecidos ou coletados por nós você poderá entrar em contato conosco nos seguinte e-mail: privacidade@institutojoabittar.edu.br.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Por motivos de segurança, somente poderemos atender a sua solicitação se tivermos certeza da sua identidade. Sendo assim, poderemos solicitar dados ou informações adicionais para a confirmação da identidade e da autenticidade do Titular. Nas hipóteses em que atuemos como operador de dados de terceiros e parceiros, você deve contatar diretamente o terceiro controlador dos dados operados.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Constituem direitos do titular, que poderão ser exigidos a qualquer tempo:
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) </spam>   Confirmação da existência de tratamento de dados pessoais;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) </spam>   Acesso aos dados pessoais, nos termos da legislação aplicável;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) </spam>   Correção de dados incompletos, inexatos ou desatualizados;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>d) </spam>   Portabilidade dos dados;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>e) </spam>   Exclusão de dados, quando este forem tratados com base no consentimento do Titular ou quando os dados forem desnecessários, excessivos ou tratados em desconformidade com a legislação aplicável;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>f) </spam>   Solicitação de informações sobre uso compartilhado de dados;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>g) </spam>   Revogação do consentimento, quando aplicável.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Ao aquiescer à presente Política de Privacidade e Proteção de Dados você nos autoriza a enviar comunicações por e-mail, telefone, mensagens instantâneas ou por qualquer outra forma de comunicação, inclusive de caráter publicitário, em qualquer horário do dia ou da noite, constituindo um direito seu entrar em contato conosco para revogação e ou limitação dos meios e horários dos nossos contatos.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Esta Política de Privacidade e Proteção de Dados descreve os dados, a finalidade, as atividades de compartilhamento destes dados por nós. Para receber informações adicionais à presente política entre em contato conosco, no endereço eletrônico citado na presente disposição.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        12. Deveres do titular dos dados pessoais
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Enquanto titular dos dados e usuário/interlocutor dos nossos produtos e serviços, você não poderá utilizar os nossos recursos, serviços e produtos para qualquer propósito que não seja lícito ou autorizado pelos termos de uso e/ou pela lei, bem como em dissonância com a ética, probidade e boa-fé.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Constitui seu dever informar corretamente e com precisão todos os seus dados, para fins cadastrais, por questão de segurança, e comunicar imediatamente qualquer ocorrência verificada consistente em anormalidades no tratamento dos seus dados para que possamos adotar, com a devida brevidade, as providências legais necessárias para rastreamento e estancamento de possível invasão/vazamento de dados.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Você deverá ser cuidadoso com os dados de sua identificação individual sempre que acessar a internet, tomando medidas necessárias para se proteger de danos, inclusive fraudes online e off-line, devendo entrar em contato com os nossos canais quanto a qualquer movimentação suspeita relativa aos seus registros disponibilizados junto a nós, inclusive recebimento de e-mails e ligações solicitando dados e informações já disponibilizadas por você em nossos cadastros.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) </spam>   Mude suas senhas periodicamente e não repita as mesmas senhas em vários sites;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) </spam>   Evite acessar nossos recursos por redes de internet e computadores públicos;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) </spam>   Nunca deixe de deslogar suas contas ao deixar um computador;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>d) </spam>   Proteja seus dispositivos móveis com senhas e bloqueadores remotos;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>e) </spam>   Antes de instalar um aplicativo, confira quais permissões ele pede e verifique se ele é de um desenvolvedor confiável;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>f) </spam>   Atenção aos dados publicados em suas redes sociais, como por exemplo, lugares que você frequenta, situação financeira e fotos que revelem onde você mora ou mesmo seu padrão de vida;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>g) </spam>   É possível controlar o que é visto pelas outras pessoas em suas redes sociais. Invista um tempo mexendo nessas configurações e filtrando os dados que pretende expor;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>h) </spam>   Evite utilizar os perfis das redes sociais para fazer login em outros sites.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        13. Limitação de responsabilidade
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Não seremos responsáveis pelos danos sofridos pelo Titular dos dados resultantes de:
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) </spam>   qualquer informação errada ou incompleta que seja fornecida pelo Titular;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) </spam>   qualquer fraude, declaração fraudulenta ou violação de dever, ou de qualquer uma das condições constantes da presente Política de Privacidade e Proteção de Dados;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) </spam>   falhas técnicas, não causadas por funcionários, prepostos ou autorizados por nós;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>d) </spam>   problemas no processamento e ou vazamento de dados por culpa ou ação dolosa do titular ou de terceiros.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Não nos responsabilizamos, perante qualquer usuário, por violação aos dados causada por terceiros, decorrente de desídia do Titular na disponibilização dos dados em redes de computadores públicos e ou redes sociais, ou ainda em caso de perda ou furto dos dispositivos de acessos.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Em nenhuma hipótese seremos responsáveis por danos indiretos, incidentais, especiais, exemplares, punitivos ou consequentes, incluindo, mas não se limitando a, danos pessoais ou à propriedade, relacionados com, ou de outra forma resultantes, de qualquer uso indevido das nossas plataformas, aplicativos, softwares e sites, por conduta dolosa ou culposa grave do Titular ou de terceiros.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Os Titulares dos dados ficam obrigados a nos indenizar e/ou ressarcir por qualquer demanda promovida por terceiros decorrentes de suas atividades em nossas plataformas, aplicativos, softwares e sites que violem os termos da presente Política de Privacidade e Proteção de dados, bem como ofenda a licitude na utilização dos dados e a boa fé, causando danos a terceiros e a nós.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        14. Período de tratamento e armazenamento dos dados pessoais
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Esta Política de Privacidade se aplica às circunstâncias acima mencionadas durante todo o período em que detivermos os seus dados pessoais fornecidos ou coletados. Pela adesão à presente Política de Privacidade e Proteção e Dados estamos autorizados a manter e armazenar seus dados:
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) </spam>   enquanto o seu cadastro conosco estiver ativo e ou pelo tempo exigido por lei;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) </spam>   até o término do contrato ou demanda necessária ao tratamento de dados pessoais, incluindo prazos prescricionais, e/ou ;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) </spam>   pelo tempo necessário a preservar o seu e nosso legítimo interesse, ou seja, enquanto houver oferta de produtos e serviços variados por nós, independente da existência e ou da manutenção de vínculo contratual ou não.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Comprometemo-nos a manter em nossa base de dados apenas aqueles dados necessários ao atendimento legal, consecução contratual e atendimento ao legítimo interesse publicitário e comercial. Assim, trataremos a integralidade dos seus dados, por exemplo, durante os prazos contratuais e prescricionais aplicáveis ou enquanto necessário para cumprimento de obrigação legal ou regulatória, permanecendo após estes prazos em nossos cadastros os dados necessários às finalidades informativas, publicitárias e comerciais. O término do tratamento de dados pessoais ocorrerá nos seguintes casos:
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) </spam>   quando a finalidade para qual o dado pessoal foi coletado for alcançada, e/ou os dados pessoais coletados deixarem de ser necessários ou pertinentes ao alcance de tal finalidade;
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) </spam>   quando o Titular solicitar a exclusão de seus dados; e
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) </spam>   quando houver uma determinação legal neste sentido.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Nos casos de término de tratamento de dados pessoais, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pela presente Política de Privacidade e Proteção de Dados, os dados pessoais serão eliminados e esta Política não será mais aplicável ao seu relacionamento conosco.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        15. Alteração na Política de Privacidade e Proteção de Dados
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Com a constante evolução dos sistemas tecnológicos, da lei e das atividades institucionais, reservamo-nos no direito de alterar esta política de privacidade a qualquer momento, mediante publicação da versão atualizada em nosso website, indicando data e hora da atualização. Caso sobrevenha alguma alteração substancial na forma de utilização e tratamento dos seus dados pessoais, você será informado no endereço eletrônico fornecido e terá a oportunidade de analisar a política revisada antes de decidir continuar usando nossos produtos e serviços, bem como haverá anúncio em destaque em nosso site.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Pequenos ajustes nesta Política de Privacidade e Proteção de Dados podem ocorrer sem afetar significativamente as formas em que nós tratamos as informações coletadas, de forma que estes não serão informados, mediante notificação, mas com a publicação atualizada dos novos termos no website institucional.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Solicitamos que você acesse frequentemente o nosso website e verifique as atualizações eventualmente realizadas na presente Política de Privacidade e Proteção de Dados.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        16. Disposições gerais
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>a) </spam>   Responsabilidade do Usuário: a violação da presente Política de Privacidade e Proteção de dados sujeita as partes, inclusive você usuário, à responsabilização pelos danos provocados.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>b) </spam>   Cooperação com Autoridades: nós declaramos que cooperamos plenamente com quaisquer autoridades ou Tribunais que venham a solicitar a divulgação da identidade ou localização de qualquer pessoa que tenha inserido qualquer material em nossos sites, aplicativos, softwares, plataformas ou redes sociais que violem as Cláusulas dispostas nesta Política.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        <spam style={{ fontWeight: 'bold' }}>c) </spam>   Limitação de Responsabilidade: esta Política de Privacidade aborda apenas o uso e divulgação de informações por nós coletadas. Acaso você divulgue suas informações a websites de terceiros, regras diferentes podem ser aplicadas à utilização das suas informações.
        </Text>

        <Text bold color={Theme.dark.primaryText} size={18} style={{ paddingTop: 20, width: '100%' }}>
        17. Lei aplicável e Jurisdição
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        A aquiescência aos termos da presente Política de Privacidade Proteção de Dados é regida pelas leis brasileiras, ficando estabelecido o foro da Comarca de Uberlândia-MG, como competente para dirimir eventuais controvérsias oriundas destes, em prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser, devendo-se dar prioridade, sempre, às tentativas de resolução por conciliação ou mediação.
        </Text>
        <Text color={Theme.dark.primaryText} size={14} style={{ paddingTop: 10, width: '90%' }}>
        Ainda está com alguma dúvida em relação a nossa Política de Privacidade? Contate a nossa Data Protection Officer (DPO): privacidade@institutojoaobittar.edu.br.
        </Text>
        
      </Styled.Content>
    </div>
  )
}

export default GeneralDataProtectionPolicy;
