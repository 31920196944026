import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { Search } from '@mui/icons-material'
import Input from 'components/Form/Input';
import Theme from 'style/Theme';

const SearchYoung = ({ handleSearch, handleOpenFilter }) => {
    const [filterText, setFilterText] = useState(null);

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
        >
            <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems=" center"
                style={{ paddingRight: 5, borderBottom: '1px solid #93979F', flex: 1 }}
            >
                <Grid item style={{ flex: 1 }}>
                    <Input
                        type="text"
                        value={filterText}
                        onChange={(value) => setFilterText(value)}
                        onSubmit={() => handleSearch(filterText)}
                        onKeyUp={() => handleSearch(filterText)}
                        placeholder="Pesquise aprendizes por nome, CNPJ ou nome fantasia da empresa"
                        required
                        style={{
                            backgroundColor: 'transparent',
                            color: '#f3f3f2',
                            padding: '0px 5px',
                            border: 'none',
                            fontSize: 14,
                            height: 40,
                        }}
                    />
                </Grid>
                <Grid item style={{ paddingLeft: 30 }}>
                    <Search
                        style={{ color: Theme.primary, cursor: 'pointer', fontSize: 22, marginTop: 10 }}
                        onClick={() => handleSearch(filterText)}
                        onKeyUp={() => handleSearch(filterText)}
                    />
                </Grid>
            </Grid>

            {/* <Grid style={{ paddingLeft: 15 }}>
                <Button
                    style={{ border: `1px solid ${Theme.primary}`, height: 40, marginLeft: 10, padding: '0px 25px' }}
                    onClick={handleOpenFilter}
                >
                    <Text
                        color={Theme.primary}
                    >
                        FILTRO
                    </Text>
                    <img src={FilterIcon} style={{ marginLeft: 10 }} />
                </Button>
            </Grid> */}
        </Grid >
    );
}

export default SearchYoung;
