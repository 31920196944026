import Styled from 'styled-components';
import { Grid } from '@mui/material';

export const Header = Styled(Grid)`
  background-color: ${({ theme }) => theme.dark.secondary};
  padding: 25px 65px;

  @media (max-width: 599px) {
    padding: 25px;
  }
`;

export const Content = Styled(Grid)`
  flex: 1;
  padding: 40px 65px;
  width: 100%;

  @media (max-width: 599px) {
    padding: 40px 25px;
  }
`;
