import { create } from 'apisauce';
import { toast } from 'react-toastify';

export const IMG_PLACEHOLDER = window.__RUNTIME_CONFIG__.REACT_APP_IMG_PLACEHOLDER;
export const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;
export const IJB_URL = window.__RUNTIME_CONFIG__.REACT_APP_IJB_URL;

const API = create({
  baseURL: `${API_URL}/api`,
  headers: {
    'content-type': 'application/json',
    'app-version': 'WEB',
    platform: 'WEB',
    authorization: localStorage.getItem('@AUTH_TOKEN')
  },
  timeout: 30000,
});

export const setToken = async (userToken = '') => {
  let token = userToken;

  if (!userToken) {
    token = (await localStorage.getItem('@AUTH_TOKEN')) || '';
  }

  API.setHeader('authorization', token);
};

setToken(false);

API.addMonitor((response) => {
  if (response.status === 401) {
    toast('Acesso negado!', { type: 'danger' });
  }
  console.log(`monitor = ${response.status}`)
});

export default API;
