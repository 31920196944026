import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Input from 'components/Form/Input';
import { Formik } from 'formik';
import Text from 'components/Text';
import Button from 'components/Button';
import Theme from 'style/Theme';
import PageHeader from 'components/PageHeader';
import { toast } from "react-toastify";
import * as Styled from './styles';
import ErrorText from 'components/ErrorText';
import { ValidateCPF, ValidateEmail } from 'services/Validation';
import moment from 'moment';

const initialValues = {};

const NewYoungScreen = () => {

  let navigate = useNavigate();

  const [save, setSave] = useState(false);

  const newYoung = useStoreActions(({ youngs }) => youngs.newYoung);
  const updateYoung = useStoreActions(({ youngs }) => youngs.updateYoung);
  const listUnities = useStoreActions(({ youngs }) => youngs.listUnities);
  const listCompanies = useStoreActions(({ youngs }) => youngs.listCompanies);
  const listYoung = useStoreActions(({ youngs }) => youngs.listYoung);
  const unitiesList = useStoreState(({ youngs }) => youngs.unities);

  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existingYoung, setExistingYoung] = useState(false);
  const [street, setStreet] = useState(null);
  const [neighborhood, setNeighborhood] = useState(null);
  const [uf, setUf] = useState(null);
  const [city, setCity] = useState(null);
  const [unity, setUnity] = useState(null);
  const [nameAux, setName] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [genreAux, setGenreAux] = useState(null);
  const [rgAux, setRg] = useState(null);
  const [zipcodeAux, setZipcode] = useState(null);
  const [num, setNum] = useState(null);
  const [complementAux, setComplement] = useState(null);
  const [phone1Aux, setPhone1] = useState(null);
  const [phone2Aux, setPhone2] = useState(null);
  const [phone3Aux, setPhone3] = useState(null);
  const [emailAux, setEmail] = useState(null);
  const [respName, setRespName] = useState('');
  const [respCpf, setRespCpf] = useState('');
  const [respRg, setRespRg] = useState('');
  const [respEmail, setRespEmail] = useState('');
  const [relation, setRelation] = useState(null);
  const [schoolName, setSchoolName] = useState(null);
  const [schoolLevel, setSchoolLevel] = useState(null);
  const [schoolShift, setSchoolShift] = useState(null);
  const [uniformAux, setUniform] = useState(null);
  const [deficiencyAux, setDeficiency] = useState(null);
  const [cpfAux, setCpf] = useState('');
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [age, setAge] = useState(null);

  useEffect(() => {
    listUnities();
    listCompanies();
  }, []);

  const checkCpf = (val) => {
    if (!val || !val.length) return;
    let str = val.replace(/[.-]/g, '').trim();
    setCpf(str);
    if (str.length < 11) return;
    str = str.substring(0, 11);
    setInvalidCpf(false);
    if (!isNaN(str))
      if (!ValidateCPF(str)) {
        toast('O CPF informado é inválido!', { type: 'error' });
        setInvalidCpf(true);
      }
  };

  const checkCpfResp = (val) => {
    if (!val || !val.length) return;
    let str = val.replace(/[.-]/g, '').trim();
    setRespCpf(str);
    if (str.length < 11) return;
    str = str.substring(0, 11);
    if (!isNaN(str))
      if (!ValidateCPF(str)) {
        toast('O CPF informado é inválido!', { type: 'error' });
      }
  };

  const setAgeAux = (val) => {
    let aux = moment().diff(val, 'years');
    if (aux < 99) setAge(aux);
    if (aux >= 18) {
      setRespCpf('');
      setRespEmail('');
      setRespName('');
      setRespRg('');
      setRelation({ value: 14, label: 'O(A) mesmo(a)' });
    } else {
      setRelation(null);
    }
  };

  const handleSumbit = async ({ image = null }) => {

    if (loading) return;

    if (!checkRequiredField(!cpfAux && cpfAux.length < 11, 'Informe o CPF!')) return;
    if (!checkRequiredField(invalidCpf, 'Informe um CPF válido!')) return;
    if (!checkRequiredField(!nameAux, 'Informe o nome do jovem!')) return;
    if (!checkRequiredField(!genreAux?.value, 'Informe o gênero!')) return;
    if (!checkRequiredField(!birthDate, 'Informe a data de nascimento!')) return;
    if (!checkRequiredField(age < 14, 'Não é possível contratar menores de 14 anos!')) return;
    if (!checkRequiredField(!rgAux, 'Informe o RG!')) return;
    if (!checkRequiredField(!zipcodeAux, 'Informe o CEP!')) return;
    if (!checkRequiredField(!street, 'Informe a rua!')) return;
    if (!checkRequiredField(!neighborhood, 'Informe o bairro!')) return;
    if (!checkRequiredField(!city, 'Informe a cidade!')) return;
    if (!checkRequiredField(!uf, 'Informe o estado!')) return;
    if (!checkRequiredField(!num, 'Informe o número!')) return;
    if (!checkRequiredField(!deficiencyAux?.value, 'Informe se o jovem possui deficiência!')) return;
    if (!checkRequiredField(!uniformAux?.value, 'Informe o tamanho do uniforme!')) return;
    if (!checkRequiredField(!phone1Aux, 'Informe o número do celular!')) return;
    if (!checkRequiredField(!emailAux, 'Informe o email!')) return;
    if (!checkRequiredField(!ValidateEmail(emailAux), 'Informe um email válido para o jovem!')) return;
    if (!checkRequiredField(!relation?.value, 'Informe o responsável do jovem!')) return;
    if (!checkRequiredField(age < 18 && relation?.value === 14, 'Jovem menor de idade não pode ser o próprio responsável!')) return;
    if (!checkRequiredField(age < 18 && !respName, 'Informe o nome do responsável!')) return;
    if (!checkRequiredField(age < 18 && !respCpf, 'Informe o CPF do responsável!')) return;
    if (!checkRequiredField(age < 18 && !ValidateCPF(respCpf), 'Informe um CPF válido para o responsável!')) return;
    if (!checkRequiredField(age < 18 && !respRg, 'Informe o RG do responsável!')) return;
    if (!checkRequiredField(age < 18 && !respEmail, 'Informe o e-mail do responsável!')) return;
    if (!checkRequiredField(age < 18 && !ValidateEmail(respEmail), 'Informe um email válido para o responsável!')) return;
    if (!checkRequiredField(!schoolName, 'Informe o nome da escola!')) return;
    if (!checkRequiredField(!schoolLevel?.value, 'Informe o nível de escolaridade!')) return;
    if (!checkRequiredField(!schoolShift?.value, 'Informe o turno!')) return;
    
    setLoading(true);

    let formData = {
      name: nameAux,
      genre: genreAux?.value || null,
      birthdate: birthDate || null,
      cpf: cpfAux.replace('_', ''),
      rg: rgAux || null,
      zipcode: zipcodeAux || null,
      street: street || null,
      streetNumber: num || null,
      complement: complementAux || null,
      neighborhood: neighborhood || null,
      uf: uf || null,
      city: city || null,
      unity: 1 || unity?.value, //for tests only
      phone1: (phone1Aux) ? phone1Aux.replace('_', '') : '',
      phone2: (phone2Aux && typeof phone2Aux == 'string') ? phone2Aux.replace('_', '') : '',
      phone3: (phone3Aux && typeof phone3Aux == 'string') ? phone3Aux.replace('_', '') : '',
      email: emailAux || null,
      uniform: uniformAux?.value || null,
      deficiency: deficiencyAux?.value || null,
      resp_name: (relation?.value === 14) ? null : respName,
      resp_cpf: (relation?.value === 14) ? null : respCpf,
      resp_rg: (relation?.value === 14) ? null : respRg,
      resp_email: (relation?.value === 14) ? null : respEmail,
      resp_relation: relation?.value || null,
      school_name: schoolName || null,
      school_shift: schoolShift?.value || null,
      school_level: schoolLevel?.value || null,
      image: image || null
    }

    if (!existingYoung)
      newYoung(formData).then(response => {
        if (response) {
          toast(response.message, { type: 'success' });
          if (!save)
            setTimeout(() => {
              navigate('/cadastrar-aprendiz-dois', { state: { id: response.data.id } });
            }, 2000);
          else
            setTimeout(() => {
              navigate('/aprendizes');
            }, 2000);
        } else {
          setLoading(false);
          toast('Não foi possível realizar o cadastro, tente novamente!', { type: 'error' });
        }
      });
    else
      updateYoung(formData).then(response => {
        if (response) {
          toast(response.message, { type: 'success' });
          if (!save)
            setTimeout(() => {
              navigate('/cadastrar-aprendiz-dois', { state: { id: response.data.id } });
            }, 2000);
          else
            setTimeout(() => {
              navigate('/aprendizes');
            }, 2000);
        } else {
          setLoading(false);
          toast('Não foi possível efetuar a atualização, tente novamente!', { type: 'error' });
        }
      });

  };

  const checkRequiredField = (validation, message) => {
    if (validation) {
      toast(message, { type: 'error' });
      return false;
    }
    return true;
  }

  const getAddress = () => {
    let url = 'https://viacep.com.br/ws/' + zipcodeAux + '/json';
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setStreet(data.logradouro);
        setNeighborhood(data.bairro);
        setUf(data.uf);
        setCity(data.localidade);

        unitiesList.forEach((item, index) => {
          if (item.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(data.localidade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) !== -1) {
            setUnity(item);
          }
        });

      })
      .catch(error => {
        console.log(error);
      });
  }

  const getYoungData = async (cpf) => {
    setValidating(true);
    await listYoung({ cpf: cpf }).then(result => {

      if (!result) {
        if (ValidateCPF(cpf)) {
          toast('CPF válido!', { type: 'success' });
        }
        setValidating(false);
        return;
      }

      if (result.error) {
        toast(result.message, { type: 'error' });
        setInvalidCpf(true);
        setValidating(false);
        return;
      }

      setName(result.nome);
      setBirthDate(result.data_nascimento);
      setAgeAux(result.data_nascimento);
      setRg(result.rg_numero);

      setZipcode(result.cep.replace('-', ''));
      setStreet(result.logradouro);
      setNum(result.numero);
      setComplement(result.complemento);
      setNeighborhood(result.bairro);
      setUf(result.estado);
      setCity(result.cidade);

      setPhone1(result.telefone_1);
      setPhone2(result.telefone_2);
      setPhone3(result.telefone_3);
      setEmail(result.email);

      setRespName(result.responsavel_nome);
      setRespCpf(result.responsavel_cpf);
      setRespRg(result.responsavel_rg);
      setRespEmail(result.responsavel_email);

      setSchoolName(result.escola_nome);

      if (result.sexo === 'M') setGenreAux({ value: 'M', label: 'Masculino' });
      else setGenreAux({ value: 'F', label: 'Feminino' });

      unitiesList.forEach((item, index) => {
        if (item.value === result.id_unidade_societa) {
          setUnity(item);
        }
      });

      relationOp.forEach((item, index) => {
        if (item.value === result.id_enum_responsavel_jovem) {
          setRelation(item);
        }
      });

      shiftOp.forEach((item, index) => {
        if (item.value === result.id_enum_turno_jovem) {
          setSchoolShift(item);
        }
      });

      levelOp.forEach((item, index) => {
        if (item.value === result.id_enum_escolaridade_jovem) {
          setSchoolLevel(item);
        }
      });

      uniformOp.forEach((item, index) => {
        if (item.value === result.id_enum_tamanho_camisa_jovem) {
          setUniform(item);
        }
      });

      deficiencyOp.forEach((item, index) => {
        if (item.value === result.id_enum_deficiente) {
          setDeficiency(item);
        }
      });

      setExistingYoung(true);
      setValidating(false);

    });

  }

  const genreOp = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
  ]

  const uniformOp = [
    { value: 1, label: '(PP) Polo normal' },
    { value: 2, label: '(P) Polo normal' },
    { value: 9, label: '(P) Polo baby look' },
    { value: 3, label: '(M) Polo normal' },
    { value: 10, label: '(M) Polo baby look' },
    { value: 4, label: '(G) Polo normal' },
    { value: 11, label: '(G) Polo baby look' },
    { value: 5, label: '(GG) Polo normal' },
    { value: 12, label: '(GG) Polo baby look' },
    { value: 6, label: '(XG) Polo normal' },
    { value: 13, label: '(XG) Polo baby look' },
  ]

  const deficiencyOp = [
    { value: 1, label: 'Não possui deficiência' },
    { value: 2, label: 'Deficiência de fala' },
    { value: 3, label: 'Deficiência de audição' },
    { value: 4, label: 'Deficiência visual grave' },
    { value: 5, label: 'Deficiência física' },
    { value: 6, label: 'Deficiência mental' },
  ]

  const relationOp = [
    { value: 1, label: 'Pai' },
    { value: 2, label: 'Mãe' },
    { value: 3, label: 'Avô' },
    { value: 4, label: 'Avó' },
    { value: 5, label: 'Irmão' },
    { value: 6, label: 'Irmã' },
    { value: 7, label: 'Tio(a)' },
    { value: 8, label: 'Padrasto' },
    { value: 9, label: 'Madrasta' },
    { value: 10, label: 'Primo(a)' },
    { value: 11, label: 'Esposo(a)' },
    { value: 12, label: 'Pai adotivo' },
    { value: 13, label: 'Mãe adotiva' },
    { value: 14, label: 'O(A) mesmo(a)' },
    { value: 15, label: 'Sogro(a)' },
    { value: 16, label: 'Madrinha' },
    { value: 17, label: 'Padrinho' },
    { value: 18, label: 'Assistente social' },
    { value: 19, label: 'Responsável pelo abrigo' },
    { value: 20, label: 'Emancipado' },
  ]

  const shiftOp = [
    { value: 1, label: 'Matutino' },
    { value: 2, label: 'Vespertino' },
    { value: 3, label: 'Noturno' },
    { value: 4, label: 'Concluí o ensino médio' },
  ]

  const levelOp = [
    { value: 10, label: '1º ano ens. médio (cursando)' },
    { value: 11, label: '2º ano ens. médio (cursando)' },
    { value: 12, label: '3º ano ens. médio (cursando)' },
    { value: 14, label: 'Ensino médio concluido' },
    { value: 1, label: 'Alfabetização' },
    { value: 2, label: '2ºano / 1ªsérie (cursando)' },
    { value: 3, label: '3ºano / 2ªsérie (cursando)' },
    { value: 4, label: '4ºano / 3ªsérie (cursando)' },
    { value: 5, label: '5ºano / 4ªsérie (cursando)' },
    { value: 6, label: '6ºano / 5ªsérie (cursando)' },
    { value: 7, label: '7ºano / 6ªsérie (cursando)' },
    { value: 8, label: '8ºano / 7ªsérie (cursando)' },
    { value: 9, label: '9ºano / 8ªsérie (cursando)' },
    { value: 13, label: 'Eja - educação de jovens e adultos (cursando)' },
    { value: 15, label: 'Superior cursando (curso na observação)' },
    { value: 16, label: 'Superior concluido (curso no campo pretensão profissional)' },
  ];

  return (
    <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>

      <Text color={'#93979F'} bold size={14} style={{ marginBottom: -10, marginLeft: 2 }}>Contratar aprendiz</Text>
      <PageHeader title='Dados pessoais' />

      <Grid container direction="row" alignItems="flex-end" style={{ paddingTop: 20 }} sx={{ backgroundColor: Theme.dark.background }}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSumbit}
        >
          {({ errors, handleChange, handleSubmit, setFieldValue, handleBlur, touched, values }) => (
            <Styled.Form onSubmit={handleSumbit}>

              <Grid container>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={12} sm={6} md={6} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>CPF <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input3
                      mask="999.999.999-99"
                      value={cpfAux}
                      onChange={(value) => { checkCpf(value); handleChange('cpfAux') }}
                      placeholder="Ex: 000.000.000-00"
                    >
                      {(inputProps) => <Input {...inputProps} type="tel" required style={{ marginTop: 15, backgroundColor: 'transparent', border: '1px solid #364563', borderRadius: 8, color: '#fff' }} />}
                    </Styled.Input3>
                    {errors.cpf && touched.cpf ? (
                      <ErrorText message={errors.cpf} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={6} md={2} lg={2} style={{ padding: 10, paddingTop: 25 }}>
                    <Button
                      background={Theme.dark.primary}
                      style={{ marginTop: 20, padding: 5, width: '100%', height: 40 }}
                      height={40}
                      loading={validating}
                      onClick={() => {
                        if (!validating) {
                          setExistingYoung(false);
                          getYoungData(cpfAux);
                        }
                      }}
                    >
                      <Text color={Theme.dark.primaryText} size={12} bold style={{ letterSpacing: '1px' }}>VALIDAR</Text>
                    </Button>
                  </Grid>

                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: 10, marginTop: 10 }}>
                  <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px', fontWeight: 600 }}>Dados pessoais do aprendiz</Text>
                  <hr style={{ border: '1px solid #364563' }} />
                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={12} md={8} lg={8} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Nome completo <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="text"
                      value={nameAux || ''}
                      onChange={(value) => { setName(value); }}
                      onSubmit={handleSubmit}
                      placeholder="Ex.: José da Silva"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.name && touched.name ? (
                      <ErrorText message={errors.name} />
                    ) : null}
                  </Grid>

                  <Styled.SelectGrid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Gênero <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={genreAux}
                      onChange={(value) => { setGenreAux(value); }}
                      options={genreOp} />
                  </Styled.SelectGrid>
                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Data de nascimento <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="date"
                      value={birthDate || ''}
                      onChange={(value) => { setBirthDate(value); setAgeAux(value); }}
                      onSubmit={handleSubmit}
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.birthdate && touched.birthdate ? (
                      <ErrorText message={errors.birthdate} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>RG <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="text"
                      value={rgAux || ''}
                      onChange={(value) => { setRg(value); handleChange('rgAux'); }}
                      onSubmit={handleSubmit}
                      placeholder="Ex.: 00.000.000-0"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.rg && touched.rg ? (
                      <ErrorText message={errors.rg} />
                    ) : null}
                  </Grid>

                  <Styled.SelectGrid item xs={6} sm={6} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Idade</Text>
                    <Text color={'#93979F'} size={18} style={{ paddingTop: 10, color: '#93979F' }}>{age || '-'} anos</Text>
                  </Styled.SelectGrid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>CEP <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="number"
                      value={zipcodeAux || ''}
                      onChange={(value) => { setZipcode(value); handleChange('zipcodeAux') }}
                      onSubmit={handleSubmit}
                      onBlur={getAddress}
                      placeholder="Ex.: 00000-000"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.zipcode && touched.zipcode ? (
                      <ErrorText message={errors.zipcode} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={7} lg={7} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Rua <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="text"
                      value={street || ''}
                      onChange={(value) => { setStreet(value); handleChange('street') }}
                      onSubmit={handleSubmit}
                      placeholder="Ex.: Rua A"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.street && touched.street ? (
                      <ErrorText message={errors.street} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Número <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="number"
                      value={num || ''}
                      onKeyPress={(event) => {
                          const key = event.key;
                          if (key === 'e' || key === 'E') {
                              event.preventDefault();
                          }
                      }}
                      onChange={(value) => { setNum(value); handleChange('num') }}
                      onSubmit={handleSubmit}
                      placeholder="Ex.: 123"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.streetNumber && touched.streetNumber ? (
                      <ErrorText message={errors.streetNumber} />
                    ) : null}
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Bairro <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="text"
                      value={neighborhood || ''}
                      onChange={(value) => { setNeighborhood(value); handleChange('neighborhood') }}
                      onSubmit={handleSubmit}
                      placeholder="Ex.: Jardim Brasil"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.neighborhood && touched.neighborhood ? (
                      <ErrorText message={errors.neighborhood} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Cidade <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="text"
                      value={city || ''}
                      onChange={(value) => { setCity(value); handleChange('city') }}
                      onSubmit={handleSubmit}
                      placeholder="Ex.: Uberlândia"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.city && touched.city ? (
                      <ErrorText message={errors.city} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>UF <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="text"
                      value={uf || ''}
                      onChange={(value) => { setUf(value); handleChange('uf') }}
                      onSubmit={handleSubmit}
                      placeholder="Estado"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.uf && touched.uf ? (
                      <ErrorText message={errors.uf} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Complemento</Text>
                    <Styled.Input2
                      type="text"
                      value={complementAux || ''}
                      onChange={(value) => { setComplement(value); handleChange('complementAux') }}
                      onSubmit={handleSubmit}
                      placeholder="Complemento"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.complement && touched.complement ? (
                      <ErrorText message={errors.complement} />
                    ) : null}
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Styled.SelectGrid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Possui deficiência? <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={deficiencyAux}
                      onChange={(value) => { setDeficiency(value); handleChange('deficiencyAux') }}
                      options={deficiencyOp} />
                  </Styled.SelectGrid>

                  <Styled.SelectGrid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Tamanho do uniforme <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Escolha uma opção"
                      value={uniformAux}
                      onChange={(value) => { setUniform(value); handleChange('uniformAux') }}
                      options={uniformOp} />
                  </Styled.SelectGrid>

                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: 10, marginTop: 10 }}>
                  <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px', fontWeight: 600 }}>Informações para contato</Text>
                  <hr style={{ border: '1px solid #364563' }} />
                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Celular <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input3
                      mask="(99) 99999-9999"
                      value={phone1Aux || ''}
                      onChange={(value) => { setPhone1(value); handleChange('phone1Aux') }}
                      placeholder="Ex.: (00) 00000-0000"
                    >
                      {(inputProps) => <Input {...inputProps} type="tel" required style={{ marginTop: 15, backgroundColor: 'transparent', border: '1px solid #364563', borderRadius: 8, color: '#fff' }} />}
                    </Styled.Input3>
                    {errors.phone1 && touched.phone1 ? (
                      <ErrorText message={errors.phone1} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Celular 2</Text>
                    <Styled.Input3
                      mask="(99) 99999-9999"
                      value={phone2Aux || ''}
                      onChange={(value) => { setPhone2(value); handleChange('phone2Aux') }}
                      placeholder="Ex.: (00) 00000-0000"
                    >
                      {(inputProps) => <Input {...inputProps} type="tel" required style={{ marginTop: 15, backgroundColor: 'transparent', border: '1px solid #364563', borderRadius: 8, color: '#fff' }} />}
                    </Styled.Input3>
                    {errors.phone2 && touched.phone2 ? (
                      <ErrorText message={errors.phone2} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Telefone fixo</Text>
                    <Styled.Input3
                      mask="(99) 9999-9999"
                      value={phone3Aux || ''}
                      onChange={(value) => { setPhone3(value); handleChange('phone3Aux') }}
                      placeholder="Ex.: (00) 0000-0000"
                    >
                      {(inputProps) => <Input {...inputProps} type="tel" required style={{ marginTop: 15, backgroundColor: 'transparent', border: '1px solid #364563', borderRadius: 8, color: '#fff' }} />}
                    </Styled.Input3>
                    {errors.phone3 && touched.phone3 ? (
                      <ErrorText message={errors.phone3} />
                    ) : null}
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>E-mail <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="text"
                      value={emailAux || ''}
                      onChange={(value) => { setEmail(value); handleChange('emailAux') }}
                      onSubmit={handleSubmit}
                      placeholder="Informe o e-mail"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.email && touched.email ? (
                      <ErrorText message={errors.email} />
                    ) : null}
                  </Grid>

                </Grid>



                <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: 10, marginTop: 10 }}>
                  <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px', fontWeight: 600 }}>Dados do responsável</Text>
                  <hr style={{ border: '1px solid #364563' }} />
                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Nome do responsável {(age < 18) ? <span style={{ color: '#4789EB' }}>*</span> : null}</Text>
                    <Styled.Input2
                      type="text"
                      value={respName || ''}
                      onChange={(value) => { setRespName(value); handleChange('respName') }}
                      onSubmit={handleSubmit}
                      placeholder="Ex.: José da Silva"
                      disabled={age >= 18}
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.resp_name && touched.resp_name ? (
                      <ErrorText message={errors.resp_name} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>CPF do responsável {(age < 18) ? <span style={{ color: '#4789EB' }}>*</span> : null}</Text>
                    <Styled.Input3
                      mask="999.999.999-99"
                      value={respCpf || ''}
                      onChange={(value) => { if (age < 18) checkCpfResp(value); }}
                      placeholder="Ex.: 000.000.000-00"
                    >
                      {(inputProps) => <Input {...inputProps} type="tel" required style={{ marginTop: 15, backgroundColor: 'transparent', border: '1px solid #364563', borderRadius: 8, color: '#fff' }} />}
                    </Styled.Input3>
                    {errors.resp_cpf && touched.resp_cpf ? (
                      <ErrorText message={errors.resp_cpf} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>RG do responsável {(age < 18) ? <span style={{ color: '#4789EB' }}>*</span> : null}</Text>
                    <Styled.Input2
                      type="text"
                      value={respRg || ''}
                      disabled={age >= 18}
                      onChange={(value) => { setRespRg(value); handleChange('respRg') }}
                      onSubmit={handleSubmit}
                      placeholder="Ex.: 00.000.000-0"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.resp_rg && touched.resp_rg ? (
                      <ErrorText message={errors.resp_rg} />
                    ) : null}
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Styled.SelectGrid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Responsável é: <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Selecione uma opção"
                      isDisabled={age >= 18}
                      value={relation}
                      onChange={(value) => { setRelation(value); handleChange('relation') }}
                      options={relationOp} />
                  </Styled.SelectGrid>

                  <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>E-mail do responsável {(age < 18) ? <span style={{ color: '#4789EB' }}>*</span> : null}</Text>
                    <Styled.Input2
                      type="text"
                      value={respEmail || ''}
                      disabled={age >= 18}
                      onChange={(value) => { setRespEmail(value); handleChange('respEmail') }}
                      onSubmit={handleSubmit}
                      placeholder="Informe o e-mail"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.resp_email && touched.resp_email ? (
                      <ErrorText message={errors.resp_email} />
                    ) : null}
                  </Grid>

                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: 10, marginTop: 10 }}>
                  <Text color={Theme.dark.primaryText} size={16} style={{ letterSpacing: '1px', fontWeight: 600 }}>Dados escolares</Text>
                  <hr style={{ border: '1px solid #364563' }} />
                </Grid>

                <Grid container xs={12} sm={12} md={12} lg={12}>

                  <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold>Nome da escola <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Input2
                      type="text"
                      value={schoolName || ''}
                      onChange={(value) => { setSchoolName(value); handleChange('schoolName') }}
                      onSubmit={handleSubmit}
                      placeholder="Informe a escola"
                      required
                      style={{ backgroundColor: 'transparent', color: '#fff' }}
                    />
                    {errors.school_name && touched.school_name ? (
                      <ErrorText message={errors.school_name} />
                    ) : null}
                  </Grid>

                  <Styled.SelectGrid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Turno de estudo <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Selecione o turno"
                      value={schoolShift}
                      onChange={(value) => { setSchoolShift(value); handleChange('schoolShift') }}
                      options={shiftOp} />
                  </Styled.SelectGrid>

                  <Styled.SelectGrid item xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <Text color={Theme.dark.primaryText} size={12} bold style={{ marginBottom: 15 }}>Escolaridade <span style={{ color: '#4789EB' }}>*</span></Text>
                    <Styled.Select2
                      placeholder="Selecione a escolaridade"
                      value={schoolLevel}
                      onChange={(value) => { setSchoolLevel(value); handleChange('schoolLevel') }}
                      options={levelOp} />
                  </Styled.SelectGrid>

                </Grid>

              </Grid>

              <Grid container xs={12} sm={12} md={12} lg={12} style={{ padding: 10, marginTop: 16, paddingBottom: 200, justifyContent: 'flex-end' }}>
                {/* <Button
                  background={'transparent'}
                  style={{ padding: 5, width: '100px', height: 40, marginRight: 20 }}
                  height={40}
                  loading={loading}
                  onClick={() => {
                    setSave(true);
                    handleSubmit();
                  }}
                >
                  <Text color={'#93979F'} size={12} bold style={{ letterSpacing: '1px' }}>Salvar</Text>
                </Button> */}

                <Button
                  background={Theme.dark.primary}
                  style={{ padding: 5, width: '200px', height: 40 }}
                  height={40}
                  loading={loading}
                  onClick={() => {
                    setSave(false);
                    handleSubmit();
                  }}
                >
                  <Text color={Theme.dark.primaryText} size={12} bold style={{ letterSpacing: '1px' }}>CADASTRAR</Text>
                </Button>
              </Grid>

            </Styled.Form>
          )}
        </Formik>
      </Grid>

    </div>
  );
}

export default NewYoungScreen;
