import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import Text from 'components/Text';
import Theme from 'style/Theme';
import { useStoreActions, useStoreState } from 'easy-peasy';

const Declaration = ({ id }) => {
    const [loading, setLoading] = useState(true);

    const getRegistration = useStoreActions(({ youngs }) => youngs.getRegistration);
    const registration = useStoreState(({ youngs }) => youngs.registration);

    const init = async () => {
        setLoading(true);
        await getRegistration({ young: id });
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, [0]);

    if (loading) return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24}>Declaração de matrícula</Text>

            <Grid container alignItems="center" justifyContent="center" direction="column">
                <CircularProgress style={{ color: Theme.primary, marginTop: 45 }} />
                <Text bold size={16} color={Theme.primary} style={{ marginTop: 15 }}>Carregando declaração...</Text>
            </Grid>
        </div>
    );

    return (
        <div style={{ backgroundColor: Theme.dark.background, padding: '20px 0px', flex: 1 }}>
            <Text bold size={24} style={{ marginBottom: 32 }}>Declaração de matrícula</Text>

            <Grid container xs={12} sm={12} md={12} lg={12}>
                {(registration) ? <embed src={'data:application/pdf;base64,' + registration} width="100%" height="415"></embed> : <div style={{ minHeight: 375, width: '100%', alignItems: 'center', alignContent: 'center', textAlign: 'center' }}><Text style={{ marginTop: 160 }}>Carregando declaração...</Text></div>}
            </Grid>

        </div>
    );
}

export default Declaration;
