import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Grid } from '@mui/material';
import Text from 'components/Text';
import CheckboxComponent from './components/CheckboxComponent';
import Theme from 'style/Theme';
import CsvDownload from 'react-json-to-csv'
import Button from 'components/Button';

const GeneralReportScreen = () => {

  const listGeneralReport = useStoreActions(({ youngs }) => youngs.listGeneralReport);
  const listGeneralReportColumns = useStoreActions(({ youngs }) => youngs.listGeneralReportColumns);
  const generalReportColumns = useStoreState(({ youngs }) => youngs.generalReportColumns);
  const generalReport = useStoreState(({ youngs }) => youngs.generalReport);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  const handleCheck = (column) => {
    if (column[1] == 2) {
      if (columns.indexOf(column[2]) == -1) {
        setColumns([...columns, column[2]]);
      } else {
        const updatedList = columns.filter((item) => {
          return (item != column[2]);
        });
        setColumns(updatedList);
      }
    } else if (column[1] == 1 && columns.indexOf(column[2]) == -1) {
      let aux = [];
      for (let c of generalReportColumns) {
        if (c[0] == column[0] && columns.indexOf(c[2]) == -1) aux.push(c[2]);
      }
      setColumns([...columns, ...aux]);
    } else if (column[1] == 1 && columns.indexOf(column[2]) != -1) {
      let aux = [];
      for (let c of generalReportColumns) {
        if (c[0] != column[0] && columns.indexOf(c[2]) != -1) aux.push(c[2]);
      }
      setColumns(aux);
    }
  }

  const generateReport = async () => {
    setLoading(true);
    setIsLoading(true);
    let aux = [];
    for (let c of generalReportColumns) {
      if (columns.indexOf(c[2]) != -1 && c[1] == 2) aux.push(c[2]);
    }
    await listGeneralReport({ columns: aux });
    setIsLoading(false);
    setLoading(false);
  }

  useEffect(() => {
    listGeneralReportColumns();
  }, [0])

  return (
    <>

      <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>
        <Text color="#F2F2F3" bold size={24}>Relatório de exportação geral</Text>

        <div style={{ marginTop: 25, marginBottom: 20 }}>
          <Text>Os campos selecionados são os que vão aparecer quando exportar o relatório.</Text>
        </div>

        <Grid container direction="column" style={{ backgroundColor: '#1E2C38', padding: 25, flex: 1, width: '100%', border: '1px solid #364563', borderRadius: 8 }}>
          {(generalReportColumns) ? generalReportColumns.map((item) => <CheckboxComponent key={(Math.random() * 10000)} column={item} columns={columns} handleCheck={handleCheck} />) : null}
        </Grid>

        <Grid container xs={12} sm={12} md={12} lg={12}>

          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Button
              background={((columns.length > 0) ? "#4789EB" : "#cccccc")}
              style={{
                color: "#ffffff",
                width: '200px',
                height: 40,
                fontStyle: 'bold',
                borderRadius: "4px",
                display: "inline-block",
                cursor: "pointer",
                fontSize: 12,
                letterSpacing: '1px',
                padding: "5px",
                textDecoration: "none",
                right: 300, bottom: 35, position: 'fixed'
              }}
              disabled={(columns.length > 0) ? false : true}
              loading={loading}
              height={40}
              onClick={generateReport}
            >
              <Text color={Theme.dark.primaryText} size={12} bold style={{ letterSpacing: '1px' }}>Gerar relatório</Text>
            </Button>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6}>
            <CsvDownload data={generalReport}
              disabled={(generalReport && generalReport.length > 0 && !isLoading) ? false : true}
              filename="relatório-geral.csv"
              style={{
                background: (generalReport && generalReport.length > 0 && !isLoading) ? "#4789EB" : "#cccccc",
                color: "#ffffff",
                width: '200px',
                height: 40,
                fontStyle: 'bold',
                borderRadius: "4px",
                display: "inline-block",
                cursor: (generalReport && generalReport.length > 0 && !isLoading) ? "pointer" : "default",
                fontSize: 12,
                letterSpacing: '1px',
                border: 'none',
                padding: "5px",
                textDecoration: "none",
                right: 70, bottom: 35, position: 'fixed'
              }}
            >
              <Grid container direction="row" style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Text color="#ffffff" size={12}> Baixar relatório</Text>
                <FileDownloadOutlinedIcon fontSize='medium' style={{ color: '#ffffff', marginLeft: 8 }} />
              </Grid>
            </CsvDownload>

          </Grid>
        </Grid>

      </div>
    </>
  );
}

export default GeneralReportScreen;
