import Styled from 'styled-components';
import { Grid } from '@mui/material';

export const inputPassword = Styled.input`
  border: none;
  flex: 1;
  font-size: 16px;

  :focus {
    outline: none !important;
  }
`;

export const PasswordContainer = Styled(Grid)`
  background-color: transparent;
  border: 1px solid #364563;
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  height: 60px;
  margin-top: 5px;
  padding: 15px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);

  :focus {
    outline: none;
  }
`;

export const TextField = Styled.input`
  background-color: transparent;
  border: 1px solid #364563;
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  height: 30px;
  margin-top: 15px;
  padding: 15px;
  width: -webkit-fill-available;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);

  :focus {
    outline: none;
  }
`;
