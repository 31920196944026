import API from 'api';

const warningsList = () => API.get('/jovem/reports/warnings');

const performancesList = () => API.get('/jovem/reports/performance');

export default {
    performancesList,
    warningsList,
};
