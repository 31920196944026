import API from 'api';

const getRegisterData = (id) => API.get('/jovem/aditivos-data/' + id);

const getContractData = (id) => API.get('/jovem/contract-data/' + id);

const getYoungData = (id) => API.get('/jovem/young-data/' + id);

const me = () => API.get('/user');

const ping = () => API.post('/user/ping');

export default {
  getContractData,
  getRegisterData,
  getYoungData,
  me,
  ping,
};
