import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid, Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FilterAdvanced from 'components/FilterAdvanced';
import Text from 'components/Text';
import JourneyCard from './components/JourneyCard';
import Search from './components/Search';
import FilterAdvancedContent from './components/FilterAdvancedContent';

import Theme from 'style/Theme';
const JourneyReportScreen = () => {
  const navigate = useNavigate();

  const [isSearching, setIsSearching] = useState(false);
  const [filtered, setFiltered] = useState([]);

  const [showFilter, setShowFilter] = useState(false);

  const openFilter = () => setShowFilter(true);
  const closeFilter = () => setShowFilter(false);

  const listJourneyReport = useStoreActions(({ youngs }) => youngs.listJourneyReport);
  const journeyReport = useStoreState(({ youngs }) => youngs.journeyReport);

  const handleSearch = async (value) => {
    let aux = value.toLowerCase();

    const result = journeyReport.filter(item => ((item.nome.toLowerCase().indexOf(aux) != -1)
      || (item.tipo_aula.toLowerCase().indexOf(aux) != -1)
      || (item.status.toLowerCase().indexOf(aux) != -1)));

    if (value && value.trim() != '' && result.length < journeyReport.length) {
      setFiltered(result);
      setIsSearching(true);
    } else {
      setFiltered([]);
      setIsSearching(false);
    }

  }

  const newYoung = () => {
    navigate('/cadastrar-aprendiz');
  }

  useEffect(() => {
    listJourneyReport();
  }, [])

  return (
    <>
      <FilterAdvanced
        show={showFilter}
        onClose={closeFilter}
      >
        <FilterAdvancedContent />
      </FilterAdvanced>

      <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>
        <Text color="#F2F2F3" bold size={24}>Relatório de jornada</Text>

        <Search
          handleSearch={handleSearch}
          handleOpenFilter={openFilter}
        />

        <div style={{ marginTop: 25, marginBottom: 10 }}>
          <Text>Jornada dos últimos 30 dias</Text>
        </div>

        <Hidden mdDown>
          <Grid container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: '10px 20px' }}
          >
            <Grid item xs={12} sm={12} md={5}>
              <Text color="#93979F">Nome</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Text color="#93979F" style={{ paddingLeft: 5 }}>Tipo Aula</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <Text color="#93979F" style={{ paddingLeft: 5 }}>Data</Text>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Text color="#93979F">Status</Text>
            </Grid>
            <Grid item xs={6} sm={6} md={1}>
              <Text color="#93979F">Entrada</Text>
            </Grid>
            <Grid item xs={6} sm={6} md={1}>
              <Text color="#93979F">Saída</Text>
            </Grid>
          </Grid>
        </Hidden>

        <div>
          {(journeyReport && !isSearching) ? journeyReport.map((item) => <JourneyCard key={item.id_jovem} props={item} />) : null}
          {(filtered.length && isSearching) ? filtered.map((item) => <JourneyCard key={item.id_jovem} props={item} />) : null}
        </div>
      </div>
    </>
  );
}

export default JourneyReportScreen;
