import React from 'react';
import Text from 'components/Text';
import * as Styled from './styles';
import moment from 'moment';
import { Grid, Hidden } from '@mui/material';

const Card = ({
  nome,
  cnpj,
  data,
  id_jovem,
  medida_disciplinar,
  meses_contratado
}) => {
  return (
    <>
      <Styled.ItemContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item container xs={12} sm={12} md={3}>
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Nome:&nbsp;</Text>
          </Hidden>
          <Text size={13} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '95%', overflow: 'hidden' }}>{nome?.toUpperCase()}</Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={1}>
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Data:&nbsp;</Text>
          </Hidden>
          <Text size={13}>{moment(data).format('DD/MM/YYYY')}</Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={4}>
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Motivo disciplinar:&nbsp;</Text>
          </Hidden>
          <Text size={13} style={{paddingRight: 4}}>{medida_disciplinar?.toUpperCase()}</Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={2}>
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Estabelecimentos:&nbsp;</Text>
          </Hidden>
          <Text size={13}>{cnpj}</Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={2}>
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Contrato:&nbsp;</Text>
          </Hidden>
          <Text size={13} italic color="#93979F">há {meses_contratado} meses</Text>
        </Grid>
        {/* <Grid item container xs={12} sm={12} md={1} justifyContent="flex-end">
          <img src={WhatsappIcon} />
        </Grid> */}
      </Styled.ItemContainer>
    </>
  );
};

export default Card;
