import React from 'react';
import Text from 'components/Text';
import * as Styled from './styles';
import { ExpandLess, ExpandMore, Remove } from '@mui/icons-material';
import { Grid, Hidden } from '@mui/material';
import { IJB_URL, IMG_PLACEHOLDER } from 'api';

const Card = ({
  absenteismo,
  desempenho_geral,
  estabelecimento,
  foto,
  idade,
  nome,
  pontos,
}) => {
  return (
    <>
      <Styled.ItemContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item container xs={12} sm={12} md={3} alignItems="center">
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Nome:&nbsp;</Text>
          </Hidden>
          <img
            alt='Foto do aprendiz'
            src={foto ? `${IJB_URL}${foto}` : IMG_PLACEHOLDER}
            width="40"
            height="40"
            style={{ borderRadius: 100, marginRight: 10 }}
          />
          <Text size={13} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '70%', overflow: 'hidden' }}>{nome.toUpperCase()}</Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={2}>
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Idade:&nbsp;</Text>
          </Hidden>
          <Text size={13}>{Math.floor(idade)}</Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={2}>
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Estabelecimentos:&nbsp;</Text>
          </Hidden>
          <Text size={13}>{estabelecimento}</Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={2} alignItems="center">
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Desempenho:&nbsp;</Text>
          </Hidden>
          {(desempenho_geral >= 70 && desempenho_geral !== null) && (<ExpandLess style={{ color: '#2FC499' }} />)}
          {(desempenho_geral < 50 && desempenho_geral !== null) && (<ExpandMore style={{ color: '#FC4F81' }} />)}
          {((desempenho_geral < 70 && desempenho_geral >= 50) || desempenho_geral === null) && (<Remove style={{ color: '#E2B53F' }} />)}
          <Text
            size={13}
            bold
            color={desempenho_geral < 50 ? "#FC4F81" : (desempenho_geral > 70 ? "#2FC499" : '#E2B53F')}
          >
            {desempenho_geral !== null && `${desempenho_geral}%`}
          </Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={2}>
          <Hidden mdUp>
            <Text color="#93979F" size={12}>Contrato:&nbsp;</Text>
          </Hidden>
          <Text
            size={13}
            bold
            color={pontos < 5 ? "#93979F" : pontos > 7 ? "#FC4F81" : '#E2B53F'}
          >
            {pontos} pontos
          </Text>
        </Grid>
        <Grid item container xs={12} sm={12} md={1}>
          <Text color="#93979F">
            <b style={{ color: '#f3f3f2' }}>{absenteismo}</b> min
          </Text>
        </Grid>
      </Styled.ItemContainer>
    </>
  );
};

export default Card;
